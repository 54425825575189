export const formatNumber = (number: string | number | undefined, digits = 2) => {
    if (!number) {
        return "0,00";
    }
    
    if (typeof number !== "number") {
        number = parseFloat(number);
    }
    
    return number.toLocaleString('da-DK', {minimumFractionDigits: digits, maximumFractionDigits: digits}).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const isNumber = (str: string) => {
    return /^[0-9]*$/.test(str);
}
