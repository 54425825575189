import { useQuery } from "react-query";

import { request } from "Apis/BaseApi";
import { IBIDto } from "Apis/IBIDto";

export const BI_USAGE_CACHE_KEY = 'BI_USAGE_CACHE_KEY';
export const useBIUsage = () => {
    return useQuery<IBIDto[]>(BI_USAGE_CACHE_KEY, async () => {
        return await request<IBIDto[]>({
            url: `/admin/bi/usage`,
            method: 'GET',
        })
    });
}

export const BI_EXPECTED_USAGE_CACHE_KEY = 'BI_EXPECTED_USAGE_CACHE_KEY';
export const useBIExpectedUsage = () => {
    return useQuery<IBIDto[]>(BI_EXPECTED_USAGE_CACHE_KEY, async () => {
        return await request<IBIDto[]>({
            url: `/admin/bi/expectedusage`,
            method: 'GET',
        })
    });
}

export const BI_PRODUCTION_CACHE_KEY = 'BI_PRODUCTION_CACHE_KEY';
export const useBIProductionData = () => {
    return useQuery<IBIDto[]>(BI_PRODUCTION_CACHE_KEY, async () => {
        return await request<IBIDto[]>({
            url: `/admin/bi/production`,
            method: 'GET',
        })
    });
}
