import {NavLink, useLocation} from "react-router-dom";
import styles from "./NavigationItem.module.scss";

type Props = {
    text: string;
    icon: any;
    route: string;
    isExact?: boolean;
    includeSearch?: boolean;
}
const NavigationItem = (props: Props) => {

    const { text, icon, route } = props;

    const { search } = useLocation();

    return (
        <NavLink to={route + (props.includeSearch ? search : '')} end={props.isExact} className={({ isActive }) => isActive ? `${styles.item} ${styles.active}` : `${styles.item}`}>
            {icon}
            {text}
        </NavLink>
    )

}

export default NavigationItem