import * as React from "react"
import { SVGProps } from "react"

const PaperPlaneIcone = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        viewBox={"0 0 24 24"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.077 3.964a1.177 1.177 0 0 0-1.027-.587L2.958 3.39c-.504 0-.94.312-1.116.775-.125.336-.097.702.075 1.004l.003.004.002.004c.065.106.148.206.248.295l6.886 5.938 1.679 8.916c.091.495.474.863.97.941.49.079.973-.152 1.225-.579L22.07 5.15l-.203-.353.204.352c.217-.369.219-.82.006-1.186Zm-.823-.233-.204-.354.204.354ZM10.09 9.681 5.078 5.352h12.596L10.091 9.68Zm2.2 8.195-1.222-6.492 7.598-4.333-6.376 10.825Z"
            fill="#333"
        />
    </svg>
)

export default PaperPlaneIcone
