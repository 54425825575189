import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { AgreementTermsModel } from "Apis/Models/AgreementTermsModel";
import { ISolarParkDto } from "Apis/Models/ISolarPark";

export const ADMIN_SOLAR_PARK_CACHE_KEY = 'ADMIN_SOLAR_PARK_CACHE_KEY';
export const useSolarParks = () => {
    return useQuery<ISolarParkDto[]>(ADMIN_SOLAR_PARK_CACHE_KEY, async () => {
        return await request<ISolarParkDto[]>({
            url: `/admin/solarParks`,
            method: 'GET',
        })
    });
}

export const useSaveSolarPark = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (solarPark: ISolarParkDto): Promise<AgreementTermsModel> => {
        if (solarPark.id) {
            return await request<AgreementTermsModel>({
                url: `/admin/solarparks/${solarPark.id}`,
                method: 'PUT',
                data: solarPark
            })
        } else {
            return await request<AgreementTermsModel>({
                url: `/admin/solarparks`,
                method: 'POST',
                data: solarPark
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_SOLAR_PARK_CACHE_KEY);
        }
    })
}
