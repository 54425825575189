import Box from "Components/V2/Box";
import {ReactElement} from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../Components/V2/Button";
import styles from "./AgreementTypeBox.module.scss";
import {StyledText} from "../../../Components/V2/Text";
import Separator from "../../../Components/V2/Separator";
import CheckmarkCircle from "../../../Assets/Icons/CheckmarkCircle";

interface Props {
    icon: ReactElement;
    headline: string;
    subheadline: string;
    explainerHeadline: string;
    explainerItems: string[];
    showTopPick?: boolean;
    onClick: () => void;
}
const AgreementTypeBox = (props: Props) => {

    const { icon, headline, subheadline, explainerHeadline, explainerItems, showTopPick, onClick } = props;
    
    const { t } = useTranslation();
    
    return (
        <Box>
            <div className={styles.container}>
                <div className={styles.top}>
                    {icon}
                    {showTopPick && (
                        <div className={styles.toppick}>
                            <StyledText type={'Button'} color={"white"}>
                                {t('topPick')}
                            </StyledText>
                        </div>
                    )}
                </div>
                <StyledText type={'Body'} weight={'Bold'} color={"secondary"}>
                    {headline}
                </StyledText>

                <StyledText type={'Caption'}>
                    {subheadline}
                </StyledText>
                
                <Button text={t('selectPackage')} onClick={onClick} />
                
                <Separator />
                
                <div className={styles.explainer}>
                    <StyledText type={"XSmall"} weight={'Bold'} color={"secondary"} style={{textTransform: 'uppercase'}}>
                        {explainerHeadline}
                    </StyledText>
                    {explainerItems.map((item, index) => (
                        <div key={index} className={styles.explainerItem}>
                            <CheckmarkCircle />
                            <StyledText type={'Caption'} color={"secondary"}>
                                {item}
                            </StyledText>
                        </div>
                    ))}
                </div>
            </div>
        </Box>
    )
}

export default AgreementTypeBox