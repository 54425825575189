import {
    Routes,
    Route
} from "react-router-dom";
import ErrorBoundary from "ErrorBoundary";
import OldRoutes from "./OldRoutes";
import NewRoutes from "./V2/NewRoutes";

const ApplicationRoutes = () => {
    
    return (
        <ErrorBoundary>
            <Routes>
                
                <Route path={"v2/*"} element={<NewRoutes />} />
                
                <Route path={"*"} element={<OldRoutes />} />
            </Routes>
        </ErrorBoundary>
    );
}



export default ApplicationRoutes;
