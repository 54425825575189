import * as RadixCheckbox from '@radix-ui/react-checkbox';
import styles from './Checkbox.module.scss';
import FlexRow from "Components/FlexRow";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CheckmarkIcon from "Assets/Icons/CheckmarkIcon";
import {StyledText} from "./Text";

interface Props {
    checked?: boolean;
    defaultChecked?: boolean;
    name: string;
    label?: string | any;
    control: any;
    rules?: any;
    errors?: any;
}
const Checkbox = (props: Props) => {
    
    return (
        <Controller
            name={props.name}
            rules={props.rules}
            control={props.control}
            defaultValue={props.defaultChecked}
            render={(
                { field },
            ) => (
                <UncontrolledCheckbox {...props} onChange={field.onChange} value={field.value} />
            )}
        />
    )
}

interface UncontrolledProps {
    value?: boolean;
    onChange?: (value: boolean) => void;
    defaultChecked?: boolean;
    name: string;
    label?: string | any;
    rules?: any;
    errors?: any;
}

export const UncontrolledCheckbox = (props: UncontrolledProps) => {
    
    const { t } = useTranslation();
    
    return (
        <FlexRow align={"center"}>
            <RadixCheckbox.Root className={styles.checkboxRoot} checked={props.value} onCheckedChange={props.onChange} id={props.name}>
                <RadixCheckbox.Indicator className={styles.checkboxIndicator}>
                    <CheckmarkIcon fill={"#616161"}/>
                </RadixCheckbox.Indicator>
            </RadixCheckbox.Root>
            <StyledText type={"Caption"} color={"secondary"} style={{paddingLeft: 8}}>
                {props.label}
                {props.errors && (
                    props.errors.type === 'required' && (<><br /><span style={{color: 'red'}}>{t('required')}</span></>)
                )}
            </StyledText>
        </FlexRow>
    )
}

export default Checkbox
