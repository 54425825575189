import { Navigate, Route, Routes } from "react-router-dom";
import AgreementRoutes from "Pages/Admin/Agreements/AgreementRoutes";
import { useAuthProvider } from "Providers/AuthProvider";
import TermsRoute from "Pages/Admin/Terms/TermsRoute";
import TopMenuLayout, { TopMenuLink } from "Layouts/TopMenuLayout";
import { useTranslation } from "react-i18next";
import SolarParksPage from "Pages/Admin/SolarPark/SolarParksPage";
import SocialMediaPage from "Pages/Admin/SocialMedia/SocialMediaPage";
import LogosPage from "Pages/Admin/Logos/LogosPage";
import BIPage from "Pages/Admin/BI/BIPage";
import AnnualReportsPage from "./AnnualReports/AnnualReportsPage";
import InvoicesRoutes from "./Invoices/InvoicesRoutes";
import moment from "moment/moment";

const AdminRoutes = () => {
    
    const { t } = useTranslation();
    
    const { userContext } = useAuthProvider();
    
    if (!userContext) {
        return <></>
    }
    
    if (!userContext.permissions.includes("Admin")) {
        return <Navigate to={"/"} />
    }
    
    return (
        <TopMenuLayout menu={(
            <>
                <TopMenuLink url={`/admin/agreements`} text={t('agreement:title')} ></TopMenuLink>
                <TopMenuLink url={`/admin/invoices`} exact text={t('invoices')} ></TopMenuLink>
                <TopMenuLink url={`/admin/invoices/invoiceable?until=${moment().startOf("month").add(-1, 'day').format("YYYY-MM-DD")}`} text={t('invoiceable')} matchUrl={"/admin/invoices/invoiceable"}></TopMenuLink>
                <TopMenuLink url={`/admin/solarparks`} text={t('solarPark:title')} ></TopMenuLink>
                <TopMenuLink url={`/admin/socialMedia`} text={t('socialMedia:title')} ></TopMenuLink>
                <TopMenuLink url={`/admin/logo`} text={t('logo:title')} ></TopMenuLink>
                <TopMenuLink url={`/admin/terms`} text={t('terms:terms')} ></TopMenuLink>
                <TopMenuLink url={`/admin/annualReports`} text={t('annualReport.annualReports')} ></TopMenuLink>
                <TopMenuLink url={`/admin/bi`} text={t('bi.bi')} ></TopMenuLink>
            </>
        )}>
            <Routes>
                <Route path={"/agreements/*"} element={
                    <AgreementRoutes />
                }/>

                <Route path={"/invoices/*"} element={
                    <InvoicesRoutes />
                }/>
    
                <Route path={"/solarParks"} element={
                    <SolarParksPage />
                }/>
                
                <Route path={"/bi"} element={
                    <BIPage />
                }/>
                
                <Route path={"/socialMedia"} element={
                    <SocialMediaPage />
                }/>
    
                <Route path={"/logo"} element={
                    <LogosPage />
                }/>
    
                <Route path={"/terms/*"} element={
                    <TermsRoute />
                }/>

                <Route path={"/annualReports"} element={
                    <AnnualReportsPage />
                }/>
                
                <Route path={"*"} element={<Navigate to={"/admin/agreements"}/>}/>
            </Routes>
        </TopMenuLayout>
    )
}

export default AdminRoutes
