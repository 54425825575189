import {SVGProps} from "react";

const ReportIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path fillRule="evenodd" clipRule="evenodd" d="M11 2H13C14.3062 2 15.4175 2.83481 15.8293 4H17C18.5977 4 19.9037 5.24892 19.9949 6.82373L20 7V19C20 20.5977 18.7511 21.9037 17.1763 21.9949L17 22H7C5.40232 22 4.09634 20.7511 4.00509 19.1763L4 19V7C4 5.40232 5.24892 4.09634 6.82373 4.00509L7 4H8.17071C8.58254 2.83481 9.69378 2 11 2ZM17 6H15.8293C15.4175 7.16519 14.3062 8 13 8H11C9.69378 8 8.58254 7.16519 8.17071 6H7C6.48716 6 6.06449 6.38604 6.00673 6.88338L6 7V19C6 19.5128 6.38604 19.9355 6.88338 19.9933L7 20H17C17.5128 20 17.9355 19.614 17.9933 19.1166L18 19V7C18 6.48716 17.614 6.06449 17.1166 6.00673L17 6ZM11 6C10.4477 6 10 5.55228 10 5C10 4.44772 10.4477 4 11 4H13C13.5523 4 14 4.44772 14 5C14 5.55228 13.5523 6 13 6H11ZM9.99327 11.8834C9.93551 11.386 9.51284 11 9 11C8.44772 11 8 11.4477 8 12V17L8.00673 17.1166C8.06449 17.614 8.48716 18 9 18C9.55228 18 10 17.5523 10 17V12L9.99327 11.8834ZM12 15C12.5128 15 12.9355 15.386 12.9933 15.8834L13 16V17C13 17.5523 12.5523 18 12 18C11.4872 18 11.0645 17.614 11.0067 17.1166L11 17V16C11 15.4477 11.4477 15 12 15ZM15.9933 13.8834C15.9355 13.386 15.5128 13 15 13C14.4477 13 14 13.4477 14 14V17L14.0067 17.1166C14.0645 17.614 14.4872 18 15 18C15.5523 18 16 17.5523 16 17V14L15.9933 13.8834Z" fill={props.fill ?? "#616161"}/>
        </svg>
    )
}

export default ReportIcon