import * as React from "react"
import { SVGProps } from "react"

const ExternalLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="32"
        height="32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        viewBox="0 0 32 32"
    >
        <path
            d="M24 17.333v8A2.667 2.667 0 0 1 21.333 28H6.667A2.667 2.667 0 0 1 4 25.333V10.667A2.667 2.667 0 0 1 6.667 8h8M20 4h8v8M13.333 18.667 28 4"
            stroke="#153629"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default ExternalLinkIcon
