import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAgreement, useAgreementEstimate } from "Apis/AgreementApi";
import styles from './ResultPage.module.scss';
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import Step from "Components/Step";
import CenteredPageLayout from "Layouts/CenteredPageLayout";
import { formatNumber } from "Utils/NumberUtils";
import { useEffect, useState } from "react";
import The0MissionIcon from "Assets/Icons/The0MissionIcon";

const ResultPage = () => {
    
    const { t } = useTranslation();
    
    const { agreementId } = useParams();
    
    const navigate = useNavigate();
    
    const { data: agreement } = useAgreement(agreementId!);
    const { data: estimate, isLoading: isLoadingEstimate } = useAgreementEstimate(agreementId!);
    
    const [solarPanels, setSolarPanels] = useState<number>(0);
    const [co2, setCo2] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    
    const animateValue = (setFunction, start, end, duration) => {
        if (start === end) {
            return
        }
        
        const range = end - start;
        let current = start;
        const increment = range / (duration / 5)
        
        let stepTime = 6
        
        const timer = setInterval(function() {
            current += increment;
            
            setFunction(current > end ? end : current);
            
            if (current >= end) {
                clearInterval(timer);
            }
        }, stepTime);
    }
    
    useEffect(() => {
        if (estimate) {
            animateValue(setSolarPanels, 0, estimate.solarPanelMeters, 1000);
            animateValue(setCo2, 0, estimate.amountOfCO2InGrams, 1500);
            animateValue(setPrice, 0, estimate.totalPriceInCents, 2000);
        }
    }, [estimate]);
    
    return (
        <CenteredPageLayout>
            <Step currentStep={3} numberOfSteps={5} />
        
            <hr/>
            
    
            {isLoadingEstimate ? (
                <>
                    <h1 className={"text-align-center"}>{t('agreement:result.loadingh1')}</h1>
                    <FlexRow justify={"center"}>
                        <The0MissionIcon spin width={100} height={100} />
                    </FlexRow>
                </>
                ) : (
                <>
                    <h1 className={"text-align-center"}>{t('agreement:result.h1')}</h1>
                    
                    <div className={styles.grid}>
                        <div className={styles.column}>
                            <Space direction={"column"} size={"large"}>
                                <div className={`h1 ${styles.alignCenter} ${styles.noWrap}`}>
                                    {solarPanels > 1000 ? formatNumber(solarPanels / 1000) : formatNumber(solarPanels)} {solarPanels > 1000 ? 'km' : 'm'}
                                </div>
                                <div className={`${styles.alignCenter}`}>
                                    {t('agreement:result.solarPanelsText')}
                                </div>
                            </Space>
                        </div>
        
                        <div className={styles.column}>
                            <Space direction={"column"} size={"large"}>
                                <div className={`h1 ${styles.alignCenter} ${styles.noWrap}`}>
                                    {formatNumber(co2 / 1000 / 1000)} ton
                                </div>
                                <div className={`${styles.alignCenter}`}>
                                    {t('agreement:result.co2Text')}
                                </div>
                            </Space>
                        </div>
        
                        <div className={styles.column}>
                            <Space direction={"column"} size={"large"}>
                                <div className={`h1 ${styles.alignCenter} ${styles.noWrap}`}>
                                    {formatNumber(price / 100, 0)} kr.
                                </div>
                                <div className={`${styles.alignCenter}`}>
                                    {t('agreement:result.priceText')}
                                </div>
                            </Space>
                        </div>
                    </div>
    
                    <Space className={styles.padding}>
                        <FlexRow justify={"center"}>
                            <Button onClick={() => navigate(`/agreements/setup/${agreement!.id}/signup`)}>{t('agreement:result.signup')}</Button>
                        </FlexRow>
                    </Space>
                </>
            )}
        
            
        </CenteredPageLayout>
    )
}

export default ResultPage
