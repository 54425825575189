import DefaultPageLayout from "../../../Layouts/V2/DefaultPageLayout";
import Gap from "../../../Components/V2/Gap";
import {StyledText} from "../../../Components/V2/Text";
import Button from "../../../Components/V2/Button";
import styles from "../Onboarding/NewAgreementPage.module.scss";
import {useTranslation} from "react-i18next";
import Box from "../../../Components/V2/Box";
import {useNavigate, useParams} from "react-router-dom";
import {useAgreement, useAgreementEstimate} from "../../../Apis/AgreementApi";
import FlexRow from "../../../Components/FlexRow";
import Separator from "Components/V2/Separator";
import Grid from "Components/V2/Grid";
import CircledSolarPanel from "../../../Assets/Icons/V2/CircledSolarPanel";
import CircledLeaf from "../../../Assets/Icons/V2/CircledLeaf";
import CircledDollar from "../../../Assets/Icons/V2/CircledDollar";
import {formatNumber} from "../../../Utils/NumberUtils";
import moment from "moment";
import CheckmarkCircle from "../../../Assets/Icons/CheckmarkCircle";
import WarningTriangle from "../../../Assets/Icons/V2/WarningTriangle";
import LinkIcon from "../../../Assets/Icons/V2/LinkIcon";
import {useRemoveAuthorization} from "../../../Apis/EloverblikAuthorizationApi";

const AgreementOnboardingPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const { agreementId } = useParams<{ agreementId: string }>();
    const { data: agreement, refetch } = useAgreement(agreementId!);
    const { data: agreementEstimate } = useAgreementEstimate(agreementId!);
    const { mutateAsync: removeAuthorizationMutation } = useRemoveAuthorization();

    const gotoReview = () => {
        navigate(`/v2/dashboard/${agreementId}/review`);
    }

    const gotoMeterAccessPage = () => {
        navigate(`/v2/dashboard/${agreementId}/onboarding/eloverblik`);
    }

    const removeAuthorization = async (id: string) => {
        await removeAuthorizationMutation(id);

        refetch();
    }

    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.content}>
                <Box style={{maxWidth: 1440, width: '100%'}}>
                    {!!agreement && !!agreementEstimate && (
                        <Gap size={"medium"} direction={"vertical"}>
                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{agreement.companyName}</StyledText>
                                    <StyledText type={"Caption"}>CVR: {agreement.vatNumber}</StyledText>
                                    <StyledText type={"Caption"}>{agreement.name}</StyledText>
                                </FlexRow>

                            </FlexRow>
                            
                            <Separator />

                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('selectedPackage')}</StyledText>
                                    {agreement.agreementType === 'Match' && (
                                        <StyledText type={"Caption"}>{t('onboarding.packageDescription.match')}</StyledText>
                                    )}
                                    {agreement.agreementType === 'Custom' && (
                                        <StyledText type={"Caption"}>{t('onboarding.packageDescription.custom')}</StyledText>
                                    )}
                                </FlexRow>
                            </FlexRow>

                            <Separator />

                            {agreement.agreementType === 'Match' && (
                                <>
                                    <FlexRow justify={"space-between"}>
                                        <FlexRow direction={"column"} align={"start"}>
                                            <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('connectToMeter')}</StyledText>
                                            <StyledText type={"Caption"}>{t('onboarding.match.connectDescription')}</StyledText>
                                        </FlexRow>

                                        <Button text={t('connectToMeter')} onClick={gotoMeterAccessPage} />

                                    </FlexRow>

                                    <Grid style={{width: '100%'}} gap={40} gridTemplateColumns={"repeat(3, clamp(150px, 15%, 300px)) auto"}>
                                    {agreement.eloverblikAuthorizations.map(authorization => (
                                        <>
                                            <Gap size={"small"} direction={"vertical"}>
                                                <StyledText type={"Caption"} weight={"Bold"}>
                                                    {t('powerOfAttorneyFrom')}
                                                </StyledText>

                                                <StyledText type={"Caption"}>
                                                    {moment(authorization.validFrom).format('DD.MM.YYYY')}
                                                </StyledText>
                                            </Gap>

                                            <Gap size={"small"} direction={"vertical"}>
                                                <StyledText type={"Caption"} weight={"Bold"}>
                                                    {t('powerOfAttorneyTo')}
                                                </StyledText>

                                                <StyledText type={"Caption"}>
                                                    {moment(authorization.validTo).format('DD.MM.YYYY')}
                                                </StyledText>
                                            </Gap>

                                            <Gap size={"small"} direction={"vertical"}>
                                                <Gap size={"small"}>
                                                    <StyledText type={"Caption"} weight={"Bold"}>
                                                        {t('status')}
                                                    </StyledText>
                                                    {authorization.status === 'Active' && (
                                                        <CheckmarkCircle fill={"#18A957"} />
                                                    )}
                                                    {(authorization.status === 'Pending' || authorization.status === 'Expired') && (
                                                        <WarningTriangle />
                                                    )}
                                                </Gap>

                                                <StyledText type={"Caption"}>
                                                    {authorization.status === 'Active' && (
                                                        t('authorizationStatus.active')
                                                    )}
                                                    {authorization.status === 'Pending' && (
                                                        t('authorizationStatus.pending')
                                                    )}
                                                    {authorization.status === 'Expired' && (
                                                        t('authorizationStatus.expired')
                                                    )}
                                                </StyledText>
                                            </Gap>

                                            <FlexRow justify={"end"}>
                                                <div onClick={() => removeAuthorization(authorization.id)}>
                                                    <Gap size={"small"}>
                                                        <LinkIcon />
                                                        <StyledText type={'Button'} color={"primary"}>{t('onboarding.removeAccess')}</StyledText>
                                                    </Gap>
                                                </div>
                                            </FlexRow>
                                        </>
                                    ))}
                                    </Grid>

                                    <Separator />
                                </>
                            )}

                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('agreementOverview')}</StyledText>
                                    {agreement.agreementType === 'Match' && (
                                        <StyledText type={"Caption"}>{t('onboarding.packageDescription.match')}</StyledText>
                                    )}
                                    {agreement.agreementType === 'Custom' && (
                                        <StyledText type={"Caption"}>{t('basedOn')} {agreement.currentAgreementUsage.yearlyUsage} {agreement.currentAgreementUsage.usageUnit}</StyledText>
                                    )}
                                </FlexRow>

                                <Button text={t('reviewAgreement')} disabled={(agreement.agreementType === 'Match' || agreement.agreementType === 'MatchAndCustom') && agreement.eloverblikAuthorizations.filter(x => x.status === 'Active').length === 0} onClick={gotoReview} />
                            </FlexRow>
                            
                            <div />
                            
                            <Grid gridTemplateColumns={"1fr 1fr 1fr"} style={{width: '100%'}}>
                                <FlexRow justify={"center"} style={{gap: 8}}>
                                    <div>
                                        <CircledSolarPanel />
                                    </div>
                                    <div className={"flex column"}>
                                        <StyledText type={"Body"} weight={"Bold"}>{t('solarpanels')}: {agreementEstimate.solarPanelMeters > 1000 ? formatNumber(agreementEstimate.solarPanelMeters / 1000) : formatNumber(agreementEstimate.solarPanelMeters)} {agreementEstimate.solarPanelMeters > 1000 ? 'km' : 'm'}</StyledText>
                                        <StyledText type={"Caption"} style={{maxWidth: 250}}>{t('onboarding.details.solarPanelDescription')}</StyledText>
                                    </div> 
                                </FlexRow>

                                <FlexRow justify={"center"} style={{gap: 8}}>
                                    <div>
                                        <CircledLeaf />
                                    </div>
                                    <div className={"flex column"}>
                                        <StyledText type={"Body"} weight={"Bold"}>{t('yearlyReduction')}: {agreementEstimate.amountOfCO2InGrams / 1000 / 1000} TON</StyledText>
                                        <StyledText type={"Caption"} style={{maxWidth: 250}}>{t('onboarding.details.yearlyReductionDescription')}</StyledText>
                                    </div>
                                </FlexRow>

                                <FlexRow justify={"center"} style={{gap: 8}}>
                                    <div>
                                        <CircledDollar />
                                    </div>
                                    <div className={"flex column"}>
                                        <StyledText type={"Body"} weight={"Bold"}>{t('yearlyCost')}: DKK {formatNumber(agreementEstimate.totalPriceInCents / 100)}</StyledText>
                                        <StyledText type={"Caption"} style={{maxWidth: 250}}>{t('onboarding.details.yearlyCostDescription')}</StyledText>
                                    </div>
                                </FlexRow>
                            </Grid>
                        </Gap>
                    )}
                </Box>
            </div>
        </DefaultPageLayout>
    )

}

export default AgreementOnboardingPage