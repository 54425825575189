
const QuestionmarkCircleIcon = () => {

    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0229 2 22.5 6.47715 22.5 12C22.5 17.5229 18.0229 22 12.5 22C6.97715 22 2.5 17.5229 2.5 12ZM4.5 12C4.5 16.4183 8.08172 20 12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4C8.08172 4 4.5 7.58172 4.5 12ZM12.5 6C10.567 6 9 7.567 9 9.5C9 10.0523 9.44772 10.5 10 10.5C10.5523 10.5 11 10.0523 11 9.5C11 8.67157 11.6716 8 12.5 8C13.3284 8 14 8.67157 14 9.5C14 10.3284 13.3284 11 12.5 11C11.9477 11 11.5 11.4477 11.5 12V14C11.5 14.5523 11.9477 15 12.5 15C13.0523 15 13.5 14.5523 13.5 14V12.84C15.3502 12.2997 16.4121 10.3618 15.8717 8.51159C15.4348 7.01539 14.0587 5.99032 12.5 6ZM13.2071 17.7071C13.5976 17.3166 13.5976 16.6834 13.2071 16.2929C12.8166 15.9024 12.1834 15.9024 11.7929 16.2929C11.4024 16.6834 11.4024 17.3166 11.7929 17.7071C12.1834 18.0976 12.8166 18.0976 13.2071 17.7071Z" fill="#E0E0E0"/>
        </svg>
    )

}

export default QuestionmarkCircleIcon