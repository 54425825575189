import styles from "./Gap.module.scss";
import {CSSProperties} from "react";

interface Props {
    children: any;
    size: 'small' | 'medium' | 'large';
    direction?: 'horizontal' | 'vertical';
    className?: string;
    block?: boolean;
    style?: CSSProperties;
}
const Gap = (props: Props) => {

    return (
        <span className={`${styles.gap} ${styles[props.size]} ${styles[props.direction!]} ${props.className} ${props.block ? styles.block : ''}`} style={props.style}>
            {props.children}
        </span>
    )
}

Gap.defaultProps = {
    direction: 'horizontal'
}

export default Gap