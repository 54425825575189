import { useBIExpectedUsage, useBIProductionData, useBIUsage } from "Apis/Admin/BiApi";
import FlexRow from "Components/FlexRow";
import PageHeader from "Components/PageHeader";
import List from "Components/List";
import { useTranslation } from "react-i18next";
import { IBIDto, IBIDtoQuarter } from "Apis/IBIDto";
import moment from "moment";
import { formatNumber } from "Utils/NumberUtils";
import {AgreementType} from "../../../Apis/Models/AgreementModel";

const BIPage = () => {
    
    const { t } = useTranslation();
    
    const { data: usageData, isLoading: isLoadingUsage } = useBIUsage();
    
    const { data: expectedUsageData, isLoading: isLoadingExpectedUsage } = useBIExpectedUsage();
    const { data: productionData, isLoading: isLoadingProductionData } = useBIProductionData();
    
    const getQuarterHeader = (quarter: IBIDtoQuarter) => {
        return moment(quarter.quarterStartDate).format("[Q]Q YYYY")
    }
    const getQuarterPaymentHeader = (quarter: IBIDtoQuarter) => {
        return `${t('bi.toPayForQuarter')} ${moment(quarter.quarterStartDate).format("[Q]Q YYYY")}`
    }

    const getAgreementTypeText = (agreement: AgreementType) => {
        switch (agreement) {
            case "Custom":
                return t('agreement:custom');
            case "Match":
                return t('agreement:match');
            case "MatchAndCustom":
                return t('agreement:matchAndCustom');
            default:
                return ""
        }
    }


    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('bi.actualUsageTitle')} />
            </FlexRow>
    
            <List keyDataIndex={"id"} loading={isLoadingUsage} data={usageData} emptyText={t('bi.noActualUsageFound')}  columns={[
                {
                    dataIndex: "name",
                    title: t('agreement:company'),
                },
                {
                    title: t('agreement:agreementType'),
                    dataIndex: "agreementType",
                    textRender: (text) => getAgreementTypeText(text),
                },
                {
                    dataIndex: "quarters",
                    title: !!usageData && usageData.length > 0 ? getQuarterHeader(usageData[0]?.quarters[3]) : '',
                    textRender: (_, record: IBIDto) => {
                        return `${record.quarters[3].usage} MWH`
                    }
                },
                {
                    dataIndex: "quarters",
                    title: !!usageData && usageData.length > 0 ? getQuarterPaymentHeader(usageData[0]?.quarters[3]) : '',
                    textRender: (_, record: IBIDto) => {
                        return `${formatNumber(record.quarters[3].usage * 70)} kr`
                    }
                },
                {
                    dataIndex: "quarters",
                    title: !!usageData && usageData.length > 0 ? getQuarterHeader(usageData[0]?.quarters[2]) : '',
                    textRender: (_, record: IBIDto) => {
                        return `${record.quarters[2].usage} MWH`
                    }
                },
                {
                    dataIndex: "quarters",
                    title: !!usageData && usageData.length > 0 ? getQuarterPaymentHeader(usageData[0]?.quarters[2]) : '',
                    textRender: (_, record: IBIDto) => {
                        return `${formatNumber(record.quarters[2].usage * 70)} kr`
                    }
                },
                {
                    dataIndex: "quarters",
                    title: !!usageData && usageData.length > 0 ? getQuarterHeader(usageData[0]?.quarters[1]) : '',
                    textRender: (_, record: IBIDto) => {
                        return `${record.quarters[1].usage} MWH`
                    }
                },
                {
                    dataIndex: "quarters",
                    title: !!usageData && usageData.length > 0 ? getQuarterPaymentHeader(usageData[0]?.quarters[1]) : '',
                    textRender: (_, record: IBIDto) => {
                        return `${formatNumber(record.quarters[1].usage * 70)} kr`
                    }
                },
                {
                    dataIndex: "quarters",
                    title: !!usageData && usageData.length > 0 ? getQuarterHeader(usageData[0]?.quarters[0]) : '',
                    textRender: (_, record: IBIDto) => {
                        return `${record.quarters[0].usage} MWH`
                    }
                },
                {
                    dataIndex: "quarters",
                    title: !!usageData && usageData.length > 0 ? getQuarterPaymentHeader(usageData[0]?.quarters[0]) : '',
                    textRender: (_, record: IBIDto) => {
                        return `${formatNumber(record.quarters[0].usage * 70)} kr`
                    }
                },
            ]} />
    
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30, marginTop: 40}}>
                <PageHeader text={t('bi.expectedUsageTitle')} />
            </FlexRow>
    
            <List keyDataIndex={"id"} loading={isLoadingExpectedUsage} data={expectedUsageData} emptyText={t('bi.noExpectedUsageFound')}  columns={[
                {
                    dataIndex: "name",
                    title: t('agreement:company'),
                    textRender: (text) => text
                },
                {
                    title: t('agreement:agreementType'),
                    dataIndex: "agreementType",
                    textRender: (text) => getAgreementTypeText(text),
                },
                {
                    dataIndex: "lastYearUsage",
                    title: t('bi.lastYearUsage'),
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "lastYearUsageToDate",
                    title: t('bi.lastYearUsageToDate'),
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "usageToDate",
                    title: t('bi.usageToDate'),
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "expectedUsage",
                    title: t('bi.expectedUsage'),
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "customUsageToDate",
                    title: t('bi.customUsageToDate'),
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "totalExpectedUsage",
                    title: t('bi.totalExpectedUsage'),
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
            ]} />
    
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30, marginTop: 40}}>
                <PageHeader text={t('bi.solarProductionTitle')} />
            </FlexRow>
    
            <List keyDataIndex={"id"} loading={isLoadingProductionData} data={productionData} emptyText={t('bi.noSolarProductionFound')}  columns={[
                {
                    dataIndex: "name",
                    title: t('solarPark'),
                    textRender: (text) => `${text}`
                },
                {
                    dataIndex: "lastYearUsage",
                    title: `${t('bi.production')} ${moment().subtract(1, 'year').format("YYYY")}`,
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "lastYearUsageToDate",
                    title: `${t('bi.productionToDate')} ${moment().subtract(1, 'year').format("YYYY")}`,
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "usageToDate",
                    title: `${t('bi.productionToDate')} ${moment().format("YYYY")}`,
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "customPeriod",
                    title: `${t('bi.customPeriodProduction')} ${moment().subtract(1, 'year').format("YYYY")}`,
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
                {
                    dataIndex: "expectedUsage",
                    title: t('bi.expectedProduction'),
                    textRender: (text) => `${formatNumber(text)} MWH`
                },
            ]} />
        </>
    )
}

export default BIPage
