import styles from './Space.module.scss';

interface Props {
    children: any;
    size?: 'small' | 'default' | 'large',
    direction?: 'column' | 'row';
    className?: any
}
const Space = (props: Props) => {
    
    return (
        <div
            className={`${styles.space} ${props.direction === 'row' && styles.row} ${props.size && styles[props.size]} ${props.className}`}>
            {props.children}
        </div>
    )
}

export default Space
