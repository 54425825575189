import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

const useQueryParam = (name: string): [string | undefined, Function] => {

    const navigate = useNavigate();
    const location = useLocation();

    const [value, setInternalValue] = useState<string>();

    useEffect(() => {
        setInternalValue(new URLSearchParams(location.search).get(name) ?? '')
    }, [name, location.search]);

    const setValue = (newValue: string) => {
        const queryParams = new URLSearchParams(location.search)

        setInternalValue(newValue);

        if (newValue) {
            queryParams.set(name, newValue);
        } else {
            queryParams.delete(name);
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString()
        })
    }

    return [value, setValue]
}

export default useQueryParam
