import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FileDto } from "Apis/Models/FileDto";
import FlexRow from "Components/FlexRow";
import PageHeader from "Components/PageHeader";
import Button from "Components/Button";
import Modal from "Components/Modal";
import styles from './SocialMediaPage.module.scss';
import { ISocialMediaPostDto } from "Apis/Models/SocialMedia";
import { useDeleteSocialMediaPost, useSaveSocialMediaPost, useSocialMediaPosts } from "Apis/Admin/SocialMediaAdminApi";
import The0MissionIcon from "Assets/Icons/The0MissionIcon";
import FileUploadButton from "Components/FileUploadButton";
import CloseIcon from "Assets/Icons/CloseIcon";
import Input from "Components/Input";
import HeartIcon from "Assets/Icons/HeartIcon";
import CommentIcon from "Assets/Icons/CommentIcon";
import PaperPlaneIcon from "Assets/Icons/PaperPlaneIcon";
import SocialMediaPost from "Components/SocialMediaPost";
import Space from "Components/Space";
import ImageSlider from "Components/ImageSlider";

const SocialMediaPage = () => {
    
    const { t } = useTranslation();
    
    const { data } = useSocialMediaPosts();
    const { mutateAsync: savePostMutation, isLoading: isSavingPost } = useSaveSocialMediaPost();
    const { mutateAsync: deletePostMutation, isLoading: isDeletingPost } = useDeleteSocialMediaPost();
    
    const [socialMediaPostModalVisible, setSocialMediaPostModalVisible] = useState<boolean>(false);
    const [deleteLogoVisible, setDeleteLogoVisible] = useState<boolean>(false);
    
    const { handleSubmit, register, control, reset } = useForm<ISocialMediaPostDto>();
    const [postImages, setPostImages] = useState<FileDto[]>([]);
    const [postToDelete, setPostToDelete] = useState<ISocialMediaPostDto>();
    
    
    const selectPost = (post: ISocialMediaPostDto) => {
        setSocialMediaPostModalVisible(true);
    
        setPostImages(post.images || []);
        
        reset(post);
    }
    
    const savePost = async (data: ISocialMediaPostDto) => {
        await savePostMutation({ ...data, images: postImages });
        
        closeModal();
    }
    
    const closeModal = () => {
        setSocialMediaPostModalVisible(false);
        setDeleteLogoVisible(false)
        reset({
            id: '',
            name: '',
            text: ''
        });
        setPostImages([])
        setPostToDelete(undefined)
    }
    
    const removeImage = (image: FileDto) => {
        setPostImages(current => current.filter(i => i.id !== image.id));
    }
    
    const showDeleteModal = (post: ISocialMediaPostDto, e: any) => {
        e.stopPropagation();
        
        setPostToDelete(post);
        setDeleteLogoVisible(true);
    }
    
    const deleteLogo = async () => {
        await deletePostMutation(postToDelete!);
        
        closeModal();
        setPostToDelete(undefined);
    }
    
    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('socialMedia:title')} />
                
                <Button color={"link"} onClick={() => setSocialMediaPostModalVisible(true)}>+ {t('socialMedia:createPost')}</Button>
            </FlexRow>
    
            {data?.length === 0 && (<FlexRow className={"h-100"} justify={"center"}>{t('socialMedia:noPostsFound')}</FlexRow>)}
            
            <div className={styles.grid}>
                {data?.map((post) => (
                    <div className={"clickable"} style={{position: 'relative'}} key={post.id}>
                        <div onClick={e => showDeleteModal(post, e)} className={styles.remove}>
                            <CloseIcon fill={"red"} />
                        </div>
                        <SocialMediaPost onClick={() => selectPost(post)} post={post} />
                    </div>))}
            </div>
            
            <Modal closeOnOutsideClick={false} title={t('socialMedia:createNewPost')} visible={socialMediaPostModalVisible} onCancel={closeModal}>
                <form onSubmit={handleSubmit(savePost)}>
                    <input type={"hidden"} {...register('id')} />
                    
                    <Input name={"name"} control={control} placeholder={t('socialMedia:name')} label={t('socialMedia:name')} />
                    
                    <div className={styles.post}>
                        <div className={styles.header}>
                            <The0MissionIcon width={20} height={20} /> The 0-Mission
                        </div>
                        <div className={styles.img}>
                            <ImageSlider images={postImages} onRemoveClick={removeImage} showRemove />
    
                            <FlexRow justify={"center"} style={{padding: 15}}>
                                <FileUploadButton multiple callback={file => setPostImages(current => [...current, file])} />
                            </FlexRow>
                        </div>
                        <div className={styles.icons}>
                            <HeartIcon />
                            <CommentIcon />
                            <PaperPlaneIcon />
                        </div>
                        <div className={styles.content}>
                            <textarea rows={10} placeholder={t('socialMedia:writeHere')} {...register('text')} />
                        </div>
                    </div>
                    
                    <FlexRow justify={"center"}>
                        <Button loading={isSavingPost} type={"submit"}>{t('save')}</Button>
                    </FlexRow>
                </form>
            </Modal>
    
            <Modal title={t('socialMedia:deleteSocialMediaPostHeader')} visible={deleteLogoVisible} onCancel={closeModal}>
                <Space direction={"column"} size={"large"}>
                    <div className={"w-100"}>
                        {t('socialMedia:deleteSocialMediaPostText')}
                    </div>
    
                    {postToDelete?.name}
            
                    <FlexRow justify={"center"}>
                        <Button disabled={isDeletingPost} loading={isDeletingPost} onClick={deleteLogo}>{t('delete')}</Button>
                    </FlexRow>
                </Space>
            </Modal>
        </>
    )
}

export default SocialMediaPage
