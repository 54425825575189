import styles from "./AgreementTypeBox.module.scss";
import Box from "../../../Components/V2/Box";
import PieChartIconV2 from "../../../Assets/Icons/PieChartIconV2";
import { StyledText } from "Components/V2/Text";
import {useTranslation} from "react-i18next";
import {ControlledInput} from "../../../Components/V2/Input";
import Button from "Components/V2/Button";
import Gap from "../../../Components/V2/Gap";
import RadioButtons from "../../../Components/V2/RadioButtons";
import {OnboardingValues} from "../Services/OnboardingValues";
import {Controller, useForm } from "react-hook-form";
import React, {useEffect} from "react";
import Tooltip from "../../../Components/V2/Tooltip";
import LightbulbIcon from "../../../Assets/Icons/V2/LightbulbIcon";
import OnboardingBoxContent from "./OnboardingBoxContent";

interface Props {
    values: Partial<OnboardingValues>;
    onChange: (values: Partial<OnboardingValues>, addSteps: number) => void;
    goBack: () => void;
    next: () => void;
}
const CustomValueInput = (props: Props) => {

    const { t } = useTranslation();
    
    const { reset, control, handleSubmit } = useForm();
    
    useEffect(() => {
        reset(props.values)
    }, [reset,  props.values])
    
    const onSubmit = (data) => {
        props.onChange({...props.values, ...data}, 1);
        props.next();
    }

    return (
        <Box style={{width: '100%'}}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
                <OnboardingBoxContent separator content={(
                    <>
                        <PieChartIconV2 />

                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                            {t('onboarding.customValueInput.headline')}
                        </StyledText>

                        <StyledText type={"Caption"}>
                            {t('onboarding.customValueInput.subheadline')}
                        </StyledText>

                        <ControlledInput
                            control={control}
                            name={"customUsage"}
                            defaultValue={props.values.customUsage?.toString()}
                            type={"number"}
                            placeholder={t('onboarding.customValueInput.enterUsage')}
                            min={0}
                            rules={{required: true}}
                            suffix={<Tooltip text={t('onboarding.customValuesInput.tooltip')} />}
                        />

                        <Controller name={"customValueUnit"}  defaultValue={"KWH"} control={control}  render={({field}) => (
                            <RadioButtons value={field.value} onChange={(newValue) => field.onChange(newValue)} options={[
                                { value: "GWH", label: t('GWH') },
                                { value: "MWH", label: t('MWH') },
                                { value: "KWH", label: t('KWH') },
                            ]} />
                        )} />
                    </>
                )}
                buttonText={(
                    <Gap size={"small"}>
                        <LightbulbIcon />
                        <StyledText type={"XSmall"} color={"secondary"} style={{maxWidth: 300}}>{t('onboarding.customValuesInput.lightBulb')}</StyledText>
                    </Gap>
                )}
                buttons={(
                    <Gap size={"small"}>
                        <Button outline text={t('back')} onClick={props.goBack} />
                        <Button text={t('continue')} type={"submit"} />
                    </Gap>
                )}
            />
            </form>
        </Box>
    )

}

export default CustomValueInput