import DefaultPageLayout from "../../../../Layouts/V2/DefaultPageLayout";
import Gap from "../../../../Components/V2/Gap";
import Button from "../../../../Components/V2/Button";
import styles from "./InternationalContactPage.module.scss";
import {StyledText} from "../../../../Components/V2/Text";
import Box from "../../../../Components/V2/Box";
import InputLabel from "../../../../Components/V2/InputLabel";
import Input, {ControlledInput} from "../../../../Components/V2/Input";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import GlobeIcon from "../../../../Assets/Icons/V2/GlobeIcon";
import {useVatInformationSearch} from "../../../../Hooks/UseVatinformationSearch";
import Grid from "../../../../Components/V2/Grid";
import {ContactFormModel} from "../../../../Apis/Models/ContactFormModel";
import {useInternationalContact} from "../../../../Apis/ContactFormController";

const InternationalContactPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { control, handleSubmit, setValue, watch } = useForm<ContactFormModel>();
    const { mutateAsync: sendContactForm, isLoading } = useInternationalContact();
    const { search, results, query } = useVatInformationSearch();

    useEffect(() => {
        if (!!results && results.length > 0) {
            let result;

            if (results?.length === 1) {
                result = results[0];
            } else {
                result = results?.find(x => x.name.toLowerCase() === query.toLowerCase() || x.vatNumber === query);
            }

            if (!!result) {
                setValue('companyName', result.name)
            } else {
                setValue('companyName', '')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results]);

    const onSubmit = async (data: ContactFormModel) => {
        await sendContactForm(data)

        let queryParams = new URLSearchParams();

        queryParams.set('companyName', data.companyName);
        queryParams.set('firstName', data.firstName);
        queryParams.set('lastName', data.lastName);
        queryParams.set('phoneNumber', data.phoneNumber);
        queryParams.set('email', data.email);

        navigate('success?' + queryParams.toString())
    }

    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.container}>

                <div className={styles.headline}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('onboarding.international.headline')}
                    </StyledText>
                </div>

                <Box style={{maxWidth: 840, width: '100%'}}>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <Gap size={"medium"} direction={"vertical"}>
                            <GlobeIcon />

                            <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                                {t('onboarding.internationalUsage')}
                            </StyledText>

                            <StyledText type={"Caption"} style={{maxWidth: 380}}>
                                {t('onboarding.international.subheadline')}
                            </StyledText>
                        </Gap>

                        <Grid gridTemplateColumns={"300px 300px"} gap={24}>
                            <div>
                                <InputLabel required>
                                    {t('companyVatNumber')}
                                </InputLabel>
                                <ControlledInput maxLength={8} control={control} name={"vatNumber"} type={"number"} onChange={e => {
                                    search(e.currentTarget.value);
                                }} placeholder={t('companyVatNumber')} rules={{required: true}} />
                            </div>

                            <div>
                                <InputLabel required>
                                    {t('companyName')}
                                </InputLabel>
                                <Input disabled value={watch('companyName')} placeholder={t('companyName')} />
                            </div>
                        </Grid>

                        <Grid gridTemplateColumns={"300px 300px"} gap={24}>
                            <div>
                                <InputLabel required>
                                    {t('firstName')}
                                </InputLabel>
                                <ControlledInput name={'firstName'} placeholder={t('yourFirstName')} control={control} rules={{required: true}} />
                            </div>

                            <div>
                                <InputLabel required>
                                    {t('lastName')}
                                </InputLabel>
                                <ControlledInput name={'lastName'} placeholder={t('yourLastName')} control={control} rules={{required: true}} />
                            </div>

                            <div>
                                <InputLabel required>
                                    {t('email')}
                                </InputLabel>
                                <ControlledInput name={'email'} placeholder={t('yourEmail')} control={control} rules={{required: true}} />
                            </div>

                            <div>
                                <InputLabel required>
                                    {t('phone')}
                                </InputLabel>
                                <ControlledInput name={'phoneNumber'} placeholder={t('yourPhone')} control={control} rules={{required: true, minLength: 8, maxLength: 8}} />
                            </div>
                        </Grid>

                        <Gap size={"small"} className={"flex justify-end"}>
                            <Button outline text={t('back')} onClick={() => navigate(-1)} />
                            <Button text={t('contactMe')} type={"submit"} state={isLoading ? 'Loading' : 'None'} />
                        </Gap>
                    </form>
                </Box>
            </div>
        </DefaultPageLayout>
    )

}

export default InternationalContactPage