
const CircledSolarPanelLargeIcon = () => {

    return (
        <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="60" height="60" rx="30" fill="#5DC389"/>
            <path d="M39.3031 20.5265C39.0604 20.1786 38.6586 19.9805 38.2374 20.0015H27.832C27.3619 20.0321 26.909 20.1922 26.5228 20.4643C26.1367 20.7367 25.8323 21.1104 25.6421 21.5456L24.4878 24.3371L24.4856 24.3419L22.7973 28.424L22.7962 28.4266L21.64 31.2225L21.6404 31.2221C21.4349 31.6488 21.4561 32.151 21.6969 32.5582C21.9393 32.9061 22.3418 33.1046 22.7626 33.0832H28.6126V39.1749L27.5284 39.1753C27.3024 39.1753 27.1196 39.3598 27.1196 39.5877C27.1196 39.8152 27.3024 40 27.5284 40H35.9416C36.1675 40 36.3503 39.8152 36.3503 39.5877C36.3503 39.3598 36.1675 39.1753 35.9416 39.1753H32.3868V33.084H33.1678C33.6379 33.0534 34.0908 32.8936 34.477 32.6212C34.8631 32.3491 35.1678 31.975 35.3576 31.5398L36.5127 28.7469L36.5134 28.7454L39.3598 21.8626C39.5653 21.4362 39.5436 20.9341 39.3031 20.5265ZM34.5819 20.8262L33.233 24.0871H29.7979L31.1464 20.8262H34.5819ZM32.8925 24.9118L31.5435 28.1733L28.1081 28.1737L29.457 24.9122L32.8925 24.9118ZM26.3972 21.8626C26.5242 21.5773 26.7238 21.331 26.976 21.1487C27.2278 20.9668 27.5234 20.8553 27.8322 20.8262H30.261L28.9125 24.0871H25.477L26.3972 21.8626ZM25.1362 24.9118H28.5713L27.2223 28.1733H23.7872L25.1362 24.9118ZM22.7629 32.2593C22.6165 32.2733 22.4727 32.2144 22.3775 32.1014C22.2935 31.9224 22.3001 31.7133 22.395 31.5399L23.4461 28.9981H26.8812L25.5326 32.259L22.7629 32.2593ZM26.4184 32.2593L27.7669 28.9984H31.2024L29.8535 32.2593H26.4184ZM31.5695 39.1757H29.4304V33.084H31.5695V39.1757ZM34.6035 31.2229C34.4765 31.5082 34.2769 31.7546 34.0247 31.9368C33.7725 32.1187 33.4768 32.2302 33.1681 32.2593H30.7396L32.0886 28.9984H35.524L34.6035 31.2229ZM35.8644 28.1737H32.4294L33.7783 24.9122H37.2138L35.8644 28.1737ZM38.6057 21.5456L37.5546 24.0875L34.1191 24.0871L35.4681 20.8262H38.2375C38.3842 20.8122 38.528 20.8711 38.6236 20.9842C38.7076 21.1631 38.701 21.3722 38.6057 21.5456Z" fill="#ECEEFE" stroke="#ECEEFE" strokeWidth="0.7"/>
        </svg>

    )

}

export default CircledSolarPanelLargeIcon