import * as React from "react"
import { SVGProps } from "react"
const ResetPasswordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 24}
    height={props.height ?? 24}
    fill="none"
    viewBox={"0 0 24 24"}
    {...props}
  >
    <path
      fill="#B386CF"
      fillRule="evenodd"
      d="M16 8.5h1a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3h1V6.61a4 4 0 1 1 8 0V8.5Zm-4-4a2.06 2.06 0 0 0-2 2.11V8.5h4V6.61a2.06 2.06 0 0 0-2-2.11Zm6 15a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v8Zm-6-7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ResetPasswordIcon
