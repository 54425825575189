import { ISocialMediaPostDto } from "Apis/Models/SocialMedia";
import styles from './SocialMediaPost.module.scss';
import The0MissionIcon from "Assets/Icons/The0MissionIcon";
import HeartIcon from "Assets/Icons/HeartIcon";
import CommentIcon from "Assets/Icons/CommentIcon";
import PaperPlaneIcon from "Assets/Icons/PaperPlaneIcon";
import { useTranslation } from "react-i18next";
import DownloadIcon from "Assets/Icons/DownloadIcon";
import { useState } from "react";
import { saveAs } from 'file-saver';
import Button from "Components/Button";
import { AgreementModel } from "Apis/Models/AgreementModel";
import ImageSlider from "Components/ImageSlider";

interface IProps {
    post: ISocialMediaPostDto;
    name?: string;
    onClick?: () => void;
    agreement?: AgreementModel
}
const SocialMediaPost = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { post, name, onClick, agreement } = props;
    
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    
    const downloadImages = async (e) => {
        
        setIsDownloading(true);
    
        for (let i = 0; i < post.images.length; i++) {
            try {
                const image = post.images[i];
        
                const response = await fetch(image!.url);
        
                const blob = await response.blob();
        
                saveAs(blob, image!.fileName);
            } catch (e) {
                console.error(e);
            }
        }
        
        setIsDownloading(false);
    }
    
    return (
        <div className={"flex column space-between h-100"}>
            <div className={styles.post} onClick={onClick}>
                <div className={styles.header}>
                    <The0MissionIcon width={20} height={20} /> {name ?? 'The 0-Mission'}
                </div>
                <div className={styles.img}>
                    <ImageSlider images={post.images} />
                </div>
                <div className={styles.icons}>
                    <HeartIcon />
                    <CommentIcon />
                    <PaperPlaneIcon />
                </div>
                <div className={styles.content}>
                    <b>{`${agreement?.nickname ?? 'The0Mission'} `}</b>{post.text}
                </div>
            </div>
            <div className={styles.downloadButton}>
                <Button onClick={downloadImages} loading={isDownloading} color={"link"}>{t('socialMedia:downloadImagesAndText')} <DownloadIcon height={18} /></Button>
            </div>
        </div>
    )
}

export default SocialMediaPost
