import styles from './ResetPasswordPage.module.scss';
import Space from "Components/Space";
import Input from "Components/Input";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { resetPassword } from "FirebaseSetup/auth";

const ResetPasswordPage = () => {
    
    const { t } = useTranslation();
    
    const [loading, setLoading] = useState<boolean>(false);
    
    const [error, setError] = useState<string>('');
    
    const { control, handleSubmit, formState: { errors } } = useForm<{email: string, password: string, passwordCheck: string, allowNewsletter, acceptTerms}>();
    
    const onSubmit = (data) => {
        setLoading(true);
    
        resetPassword(data.email).finally(() => {
            setError(t('auth:resetPassword.emailSent'));
            setLoading(false)
        });
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
            
                <h1>{t('auth:resetPassword.h1')}</h1>
                <h2 style={error ? {color: 'red'} : {}}>{error ? error : t('auth:resetPassword.h2')}</h2>
                
                <div className={"flex column w-100"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Space>
                            <div className={"w-100"}>
                                <Input label={t('email')} placeholder={t('auth:emailPlaceholder')} control={control} name={"email"} errors={errors.email} rules={{required: true}} />
                            </div>
                            
                            <FlexRow justify={"center"} style={{paddingTop: 20}}>
                                <Button type={"submit"} loading={loading}>{t('auth:resetPassword.resetPassword')}</Button>
                            </FlexRow>
                        
                            <FlexRow direction={"column"} justify={"center"} style={{paddingTop: 40}}>
                                <Link to={"/auth/login"}>{t('auth:resetPassword.gotoLogin')}</Link>
                            </FlexRow>
                        </Space>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage
