import Button from "Components/Button";
import { useState } from "react";
import Input from "Components/Input";
import Space from "Components/Space";
import { useTranslation } from "react-i18next";
import FlexRow from "Components/FlexRow";
import { useForm } from "react-hook-form";
import styles from './LoginPage.module.scss';
import { signIn } from "FirebaseSetup/auth";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState<boolean>(false);
    
    const [error, setError] = useState<string>('');
    
    const { control, handleSubmit, formState: { errors } } = useForm<{email: string, password: string}>();
    
    const onSubmit = (data) => {
        setLoading(true);
    
        signIn(data.email, data.password)
        .then((user) => {
            navigate("/");
        })
        .catch(e => {
            if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
                setError(t('auth:invalidCredentials'));
            }
            if (e.code === 'auth/invalid-email') {
                setError(t('auth:invalidEmail'));
            }
            if (e.code === 'auth/network-request-failed') {
                setError(t('somethingWentWrong'));
            }
        }).finally(() => setLoading(false));
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
            
                <h1>{t('auth:login.h1')}</h1>
                <h2 style={error ? {color: 'red'} : {}}>{error ? error : t('auth:login.h2')}</h2>
                
                <div className={"flex column w-100"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Space>
                            <div className={"w-100"}>
                                <Input label={t('email')} placeholder={t('auth:emailPlaceholder')} control={control} name={"email"} errors={errors.email} rules={{required: true}} />
                            </div>
                        
                            <div className={"w-100"}>
                                <Input label={t('password')} type={"password"} placeholder={t('auth:passwordPlaceholder')} control={control} errors={errors.password} name={"password"} rules={{required: true}} />
                            </div>
    
                            <FlexRow direction={"column"} justify={"center"} style={{paddingTop: 20}}>
                                <Link className={styles.link} to={"/auth/resetPassword"}>{t('auth:login.forgotPassword')}</Link>
                            </FlexRow>
                            
                            <FlexRow justify={"center"} style={{paddingTop: 20}}>
                                <Button disabled={loading} type={"submit"} loading={loading}>{t('auth:login.login')}</Button>
                            </FlexRow>
                            
                            <FlexRow direction={"column"} justify={"center"} style={{paddingTop: 20}}>
                                <div className={"linkExplain"}>
                                    {t('auth:login.noAccount')}
                                </div>
                                <Link to={"/auth/signup"}>{t('auth:login.gotoSignup')}</Link>
                            </FlexRow>
                        </Space>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoginPage
