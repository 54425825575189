import { FileDto } from "Apis/Models/FileDto";
import styles from './ImageSlider.module.scss';
import { useEffect, useState } from "react";
import CloseIcon from "Assets/Icons/CloseIcon";


interface IProps {
    images: FileDto[];
    autoSlide?: boolean;
    onRemoveClick?: (image: FileDto) => void;
    showRemove?: boolean;
}
const ImageSlider = (props: IProps) => {
    
    const { images, autoSlide, onRemoveClick, showRemove } = props;
    
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const goto = (index: number, e) => {
        e.stopPropagation();
        
        setCurrentSlide(index);
    }
    
    useEffect(() => {
        if (autoSlide) {
            const interval = setInterval(() => {
                setCurrentSlide(current => current === images.length - 1 ? 0 : current + 1);
            }, 8000);
            
            return () => clearInterval(interval);
        }
    }, [setCurrentSlide, autoSlide, images])
    
    return (
        <div className={styles.container}>
            {images.map((image, index) => (
                <div key={image.id} className={`${styles.slide} ${styles.fade} ${index === currentSlide && styles.active}`}>
                    <img alt={""} src={image.url} />
                    {showRemove && (
                        <div className={styles.remove} onClick={() => onRemoveClick && onRemoveClick(image)}>
                            <CloseIcon fill={"red"} />
                        </div>
                    )}
                </div>
            ))}
            <div className={styles.dotContainer}>
                {images.map((image, index) => (<div onClick={e => goto(index, e)} className={`${styles.dot} ${index === currentSlide && styles.active}`}></div>))}
            </div>
        </div>
    )
}

export default ImageSlider
