import Box from "../../../Components/V2/Box";
import { StyledText } from "Components/V2/Text";
import {useTranslation} from "react-i18next";
import { ControlledInput } from "../../../Components/V2/Input";
import Button from "Components/V2/Button";
import Gap from "../../../Components/V2/Gap";
import {OnboardingValues} from "../Services/OnboardingValues";
import {useForm} from "react-hook-form";
import InputLabel from "../../../Components/V2/InputLabel";
import Checkbox from "../../../Components/V2/Checkbox";
import {usePasswordChecks} from "../../../Hooks/UsePasswordChecks";
import React from "react";
import CircledCheckmark from "../../../Assets/Icons/CircledCheckmark";
import CircledX from "Assets/Icons/CircledX";
import OnboardingBoxContent from "./OnboardingBoxContent";

interface Props {
    values: Partial<OnboardingValues>;
    onChange: (values: OnboardingValues) => void;
    goBack: () => void;
    next: () => void;
    isLoading: boolean;
}
const UserDetails = (props: Props) => {

    const { t } = useTranslation();
    
    const { control, handleSubmit } = useForm();

    const [passwordChecks, , setFirstPassword, setSecondPassword] = usePasswordChecks(); // empty comma is needed here to make sure the correct methods are used

    const onSubmit = (data) => {
        if (passwordChecks.filter(x => !x.fulfilled).length > 0) {
            return;
        }

        props.onChange({...props.values, ...data})
    }
    
    return (
        <Box style={{width: '100%'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <OnboardingBoxContent
                    content={(
                        <>
                            <div>
                                <InputLabel required>
                                    {t('email')}
                                </InputLabel>
                                <ControlledInput name={'email'} placeholder={t('yourEmail')} control={control} rules={{required: true}} />
                            </div>

                            <div>
                                <InputLabel required>
                                    {t('password')}
                                </InputLabel>
                                <ControlledInput name={'password'} placeholder={t('yourPassword')} type={"password"} control={control} onChange={newValue => setFirstPassword(newValue.target.value)} rules={{required: true}} />
                            </div>

                            <Gap size={"small"} direction={"vertical"}>
                                {passwordChecks.map((x, index) => (
                                    <Gap size={"small"} key={index}>
                                        {x.fulfilled ? <CircledCheckmark /> : <CircledX />}
                                        <StyledText type={"Caption"} color={"secondary"}>
                                            {x.message}
                                        </StyledText>
                                    </Gap>
                                ))}
                            </Gap>

                            <div>
                                <InputLabel required>
                                    {t('repeatPassword')}
                                </InputLabel>
                                <ControlledInput name={'repeatPassword'} placeholder={t('repeatYourPassword')} type={"password"} control={control} onChange={newValue => setSecondPassword(newValue.target.value)} rules={{required: true}} />
                            </div>

                            <Checkbox name={"acceptTerms"} control={control} label={<a style={{textDecoration: 'none'}} target={"_blank"} rel={"noreferrer"} href={"https://www.the0mission.dk/privatlivspolitik/"}>{t('iAccept')}<span style={{textDecoration: 'underline'}}>{t('privacyPolicy')}</span></a>} rules={{required: true}}/>
                        </>
                    )}
                    buttons={(
                        <Gap size={"small"} className={"flex justify-end"}>
                            <Button outline text={t('back')} onClick={props.goBack} />
                            <Button text={t('continue')} type={"submit"} state={props.isLoading ? "Loading" : "None"} />
                        </Gap>
                    )}
                />

            </form>
        </Box>
    )

}

export default UserDetails