import FlexRow from "Components/FlexRow";
import PageHeader from "Components/PageHeader";
import Button from "Components/Button";
import Modal from "Components/Modal";
import Input from "Components/Input";
import styles from './LogosPage.module.scss';
import FileUploadButton from "Components/FileUploadButton";
import CloseIcon from "Assets/Icons/CloseIcon";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FileDto } from "Apis/Models/FileDto";
import { useDeleteLogo, useLogos, useSaveLogo } from "Apis/Admin/LogoAdminApi";
import { ILogoDto } from "Apis/Models/LogoDto";
import Space from "Components/Space";
import { saveAs } from 'file-saver';

const LogosPage = () => {
    
    const { t } = useTranslation();
    
    const { data } = useLogos();
    const { mutateAsync: saveLogoMutation, isLoading: isSavingLogo } = useSaveLogo();
    const { mutateAsync: deleteLogoMutation, isLoading: isDeletingLogo } = useDeleteLogo();
    
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [deleteLogoVisible, setDeleteLogoVisible] = useState<boolean>(false);
    
    const { handleSubmit, register, control, reset } = useForm<ILogoDto>();
    const [image, setImage] = useState<FileDto | null>(null);
    const [logoToDelete, setLogoToDelete] = useState<ILogoDto>();
    
    const selectLogo = (logo: ILogoDto) => {
        setModalVisible(true);
        
        setImage(logo.file);
        
        reset(logo);
    }
    
    const saveLogo = async (data: ILogoDto) => {
        if (!image) {
            return;
        }
        
        await saveLogoMutation({ ...data, file: image });
        
        closeModal();
    }
    
    const closeModal = () => {
        setModalVisible(false);
        setDeleteLogoVisible(false);
        reset({
            id: '',
            name: '',
        });
        setImage(null)
        setLogoToDelete(undefined);
    }
    
    const removeImage = () => {
        setImage(null);
    }
    
    const showDeleteLogoModal = (logo: ILogoDto, e: any) => {
        e.stopPropagation();
        
        setLogoToDelete(logo);
        setDeleteLogoVisible(true);
    }
    
    const deleteLogo = async () => {
        await deleteLogoMutation(logoToDelete!);
        
        closeModal();
    }

    const downloadLogo = async () => {
        const response = await fetch(image!.url);

        const blob = await response.blob();

        saveAs(blob, image!.fileName);
    }
    
    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('logo:title')} />
            
                <Button color={"link"} onClick={() => setModalVisible(true)}>+ {t('logo:createLogo')}</Button>
            </FlexRow>
    
            {data?.length === 0 && (<FlexRow className={"h-100"} justify={"center"}>{t('logo:noLogosFound')}</FlexRow>)}
    
            <div className={styles.imageGrid}>
                {data?.map(logo => (
                    <div key={logo.file.id} className={`${styles.imageContainer}`} onClick={_ => selectLogo(logo)}>
    
                        <div onClick={e => showDeleteLogoModal(logo, e)} className={styles.remove}>
                            <CloseIcon fill={"red"} />
                        </div>
                        
                        <div className={styles.image}>
                            <img src={logo.file.url} alt={""} />
                        </div>
            
                        <div className={styles.imageName}>
                            {logo.name}
                        </div>
                    </div>
                ))}
            </div>
            
            <Modal closeOnOutsideClick title={t('logo:createLogo')} visible={modalVisible} onCancel={closeModal}>
                <form onSubmit={handleSubmit(saveLogo)}>
                    <input type={"hidden"} {...register('id')} />
                    
                    <Space direction={"column"} size={"large"} >
                        <div className={"w-100"}>
                            <Input name={"name"} control={control} placeholder={t('logo:name')} label={t('logo:name')} />
                        </div>
                        
                        <div className={styles.img}>
                            {!image && (<FlexRow style={{padding: 40}} justify={"center"}><FileUploadButton callback={setImage} /></FlexRow>)}
                            {!!image && (
                                <>
                                    <div onClick={removeImage} className={styles.remove}>
                                        <CloseIcon fill={"red"} />
                                    </div>
                                    <img src={image?.url} alt={""} />
                                </>
                            )}
                        </div>
                        
                        <FlexRow justify={"center"}>
                            <Button disabled={!image} loading={isSavingLogo} type={"submit"}>{t('save')}</Button>
                        </FlexRow>

                        {!!image && (
                            <FlexRow justify={"center"} style={{paddingTop: 40}}>
                                <Button onClick={downloadLogo} disabled={!image}>{t('download')}</Button>
                            </FlexRow>
                        )}
                    </Space>
                </form>
            </Modal>
    
            <Modal closeOnOutsideClick title={t('logo:deleteLogoHeader')} visible={deleteLogoVisible} onCancel={closeModal}>
                <Space direction={"column"} size={"large"}>
                    <div className={"w-100"}>
                        {t('logo:deleteLogoText')}
                    </div>
    
                    <FlexRow justify={"center"}>
                        <img src={logoToDelete?.file.url} alt={""} />
                    </FlexRow>
        
                    <FlexRow justify={"center"}>
                        <Button disabled={isDeletingLogo} loading={isDeletingLogo} onClick={deleteLogo}>{t('delete')}</Button>
                    </FlexRow>
                </Space>
            </Modal>
        </>
    )
}

export default LogosPage
