import React, {useMemo, useState} from "react";
import styles from './List.module.scss';
import FlexRow from "Components/FlexRow";
import The0MissionIcon from "../../Assets/Icons/The0MissionIcon";
import CaretDownIcon from "../../Assets/Icons/CaretDownIcon";

export interface Column {
    dataIndex?: string,
    customRender?: (text?, record?) => React.ReactElement,
    textRender?: (text?, record?) => string,
    title?: string;
    allowOrderBy?: boolean;
    orderByValueFn?: (element: any) => string | number | Date;
    size?: 'auto' | 'max-content' | 'min-content' | string,
}

interface Props {
    loading?: boolean;
    data?: any[];
    columns: Column[];
    emptyContent?: React.ReactElement;
    emptyText: string;
    keyDataIndex: string;
    defaultOrderBy?: string;
}
const List = ({loading, data, columns, emptyContent, emptyText, keyDataIndex, defaultOrderBy}: Props) => {

    const [orderByField, setOrderByField] = useState<string>(defaultOrderBy ?? '')
    const [orderByDirection, setOrderByDirection] = useState<'ASCENDING' | 'DESCENDING'>('ASCENDING');

    const getGridCss = useMemo(() => {
        return columns.map(x => x.size ?? '1fr').join(' ')
    }, [columns]);
    
    const renderColumn = (column: Column, record: any, index) => {
        
        const element = column.customRender!(column.dataIndex ? record[column.dataIndex] : '', record)
        
        return React.cloneElement(
            element,
            {
                className: `${element.props.className} ${index !== 0 ? styles.separator : ''}`
            }
        )
    }

    const setSort = (column: Column) => {
        if (!column.allowOrderBy || !column.dataIndex) {
            return;
        }

        let newDirection = orderByDirection
        let newField = column.dataIndex;

        if (orderByField === column.dataIndex) {
            newDirection = orderByDirection === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING'
        } else {
            newDirection = 'ASCENDING'
        }

        setOrderByField(newField)
        setOrderByDirection(newDirection)
    }
    
    const rows = useMemo(() => {
        let rowData = data;
        let column = columns.find(x => x.dataIndex === orderByField)

        if (orderByField && orderByDirection && !!column && !!column.orderByValueFn) {
            rowData?.sort((a, b) => {
                let aValue = column!.orderByValueFn!(a).toString().toLowerCase();
                let bValue = column!.orderByValueFn!(b).toString().toLowerCase();

                if (orderByDirection === 'ASCENDING') {
                    return aValue > bValue ? 1 : -1
                } else {
                    return aValue > bValue ? -1 : 1
                }
            })
        }

        return rowData?.map(record => (
            <div className={styles.row} key={record[keyDataIndex]} style={{gridTemplateColumns: getGridCss}}>
                {columns.map((column, index) => (
                    <div className={`flex ${styles.column}`} key={index}>
                        {column.customRender ?
                            <>
                                {renderColumn(column, record, index)}
                            </>
                            : (
                                <div className={`flex column justify-center w-100 h-100`}>
                                    {column.textRender ? column.textRender(record[column.dataIndex!], record) : record[column.dataIndex!]}
                                </div>
                            )}
                    </div>
                ))}
            </div>
        ))
    }, [getGridCss, columns, data, keyDataIndex, orderByField, orderByDirection])
    
    if (loading) {
        return (
            <>
                <div className={`${styles.loadingContainer}`}>
                    <FlexRow justify={"center"} direction={"column"} className={"h-100"}>
                        <The0MissionIcon spin width={40} height={40} />
                    </FlexRow>
                </div>
            </>
        )
    }
    
    if (data?.length === 0) {
        return (
            <FlexRow justify={"center"} align={"center"} className={"h-100"}>
                {emptyContent ? emptyContent : emptyText}
            </FlexRow>
        )
    }
    
    return (
        <div className={styles.container} style={{gridTemplateColumns: getGridCss}}>
            <div className={styles.row} >
                {columns.map((column, index) => (
                    <div key={index} className={`${styles.title} label ${column.allowOrderBy && 'clickable'}`} onClick={() => setSort(column)}>
                        {column.title} {orderByField === column.dataIndex && (<CaretDownIcon direction={orderByDirection} />)}
                    </div>
                ))}
            </div>
            {rows}
        </div>
    )
}

export default List
