import DefaultPageLayout from "../../../Layouts/V2/DefaultPageLayout";
import { useTranslation } from "react-i18next";
import styles from "./NewAgreementPage.module.scss";
import {StyledText} from "../../../Components/V2/Text";

import Steps from "Features/Onboarding/Components/Steps";
import Button from "Components/V2/Button";
import Gap from "../../../Components/V2/Gap";
import AgreementTypeSelector from "../../../Features/Onboarding/Components/AgreementTypeSelector";
import CustomValueInput from "../../../Features/Onboarding/Components/CustomValueInput";
import CompanyDetails from "../../../Features/Onboarding/Components/CompanyDetails";
import {OnboardingValues} from "../../../Features/Onboarding/Services/OnboardingValues";
import ContactPersonDetails from "Features/Onboarding/Components/ContactPersonDetails";
import UserDetails from "../../../Features/Onboarding/Components/UserDetails";
import {useCreateAgreement} from "../../../Features/Onboarding/Services/OnboardingApi";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuthProvider} from "../../../Providers/AuthProvider";
import {useEffect, useState} from "react";
import {loginWithCustomToken, sendEmailVerification} from "../../../FirebaseSetup/auth";

const PERSISTED_STATE_KEY = 'NEW_AGREEMENT_PERSISTENCE';

const NewAgreementPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userContext } = useAuthProvider();

    const { mutateAsync:  createAgreementMutation } = useCreateAgreement();

    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentStep = parseInt(searchParams.get("step") ?? "1");

    const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
    const [ formData, setFormData ] = useState<Partial<OnboardingValues>>({});

    const totalSteps = formData.agreementType === 'Match' ? 4 : 5;

    useEffect(() => {
        let persistedState = localStorage.getItem(PERSISTED_STATE_KEY);

        if (!!persistedState) {
            let parsedState: OnboardingValues = JSON.parse(persistedState);
            setFormData(parsedState);

            if (parsedState.agreementType === 'Match') {
                switch (true) {
                    case !!parsedState.title:
                        setSearchParams("step=4")
                        break;
                    case !!parsedState.companyVatNumber:
                        setSearchParams("step=3")
                        break;
                    default:
                        setSearchParams("step=2")
                        break;
                }
            }

            if (parsedState.agreementType === 'Custom') {
                switch (true) {
                    case !!parsedState.title:
                        setSearchParams("step=5")
                        break;
                    case !!parsedState.companyVatNumber:
                        setSearchParams("step=4")
                        break;
                    case !!parsedState.customUsage:
                        setSearchParams("step=3")
                        break;
                    default:
                        setSearchParams("step=2")
                        break;
                }
            }
        }
    }, [setSearchParams]);

    const saveFormData = (newFormData: Partial<OnboardingValues> | null) => {
        if (newFormData === null) {
            setFormData({});
            localStorage.removeItem(PERSISTED_STATE_KEY);
        } else {
             setFormData(newFormData);
            localStorage.setItem(PERSISTED_STATE_KEY, JSON.stringify(newFormData));
        }
    }
    
    const createAgreement = async (newFormData: OnboardingValues) => {
        setIsCreatingAccount(true);

        const response = await createAgreementMutation(newFormData);
        
        if (!!response.token) {
            const firebaseUser = await loginWithCustomToken(response.token);

            await sendEmailVerification(firebaseUser.user);

            saveFormData(null);
            setIsCreatingAccount(false);
            navigate("/v2/verifyEmail");
        } else {
            navigate(`/v2/dashboard/${response.agreementId}/onboarding`);
        }
    }
    
    const getTitle = () => {
        if (!formData.agreementType || currentStep === 1) {
            return t('onboarding.headline');
        }
        
        if (formData.agreementType === 'Match') {
            switch (currentStep) {
                case 2:
                    return t('onboarding.match.step2.headline');
                case 3:
                    return t('onboarding.match.step3.headline');
                case 4:
                    return t('onboarding.match.step4.headline');
            }
        }
        
        if (formData.agreementType === 'Custom') {
            switch (currentStep) {
                case 2:
                    return t('onboarding.custom.step2.headline');
                case 3:
                    return t('onboarding.custom.step3.headline');
                case 4:
                    return t('onboarding.custom.step4.headline');
                case 5:
                    return t('onboarding.custom.step5.headline');
            }
        }
    }

    const goBack = () => {
        setSearchParams(`step=${currentStep - 1}`)
    }

    const next = () => {
        setSearchParams(`step=${currentStep + 1}`)
    }
    
    const getContent = () => {
        
        if (currentStep === 1) {
            return (
                <AgreementTypeSelector onSelect={type => saveFormData({...formData, agreementType: type})} next={next} />
            )
        }
        
        if (formData.agreementType === 'Match') {
            switch (currentStep) {
                case 2:
                    return <CompanyDetails values={formData} onChange={values => saveFormData({...formData, ...values})} goBack={goBack} next={next} />
                case 3:
                    return <ContactPersonDetails values={formData} onChange={values => saveFormData({...formData, ...values})} goBack={goBack} next={next} />
                case 4:
                    return <UserDetails values={formData} onChange={createAgreement} goBack={goBack} next={next} isLoading={isCreatingAccount} />
                default:
                    return <AgreementTypeSelector onSelect={values => saveFormData({...formData, agreementType: values})} next={next} />
            }
        }

        if (formData.agreementType === 'Custom') {
            switch (currentStep) {
                case 2:
                    return <CustomValueInput values={formData} onChange={values => saveFormData({...formData, ...values})} goBack={goBack} next={next} />
                case 3:
                    return <CompanyDetails values={formData} onChange={values => saveFormData({...formData, ...values})} goBack={goBack} next={next} />
                case 4:
                    return <ContactPersonDetails values={formData} onChange={values => saveFormData({...formData, ...values})} goBack={goBack} next={next} />
                case 5:
                    return <UserDetails values={formData} onChange={createAgreement} goBack={goBack} next={next} isLoading={isCreatingAccount} />
                default:
                    return <AgreementTypeSelector onSelect={values => saveFormData({...formData, agreementType: values})} next={next} />
            }
        }
    }

    return (
        <DefaultPageLayout contentMaxWidth={1440} menuContent={(
            <Gap size={"small"}>
                {!userContext && (
                    <>
                        <StyledText type={'ButtonLarge'}>{t('onboarding.existingUser')}</StyledText>
                        <Button outline text={t('onboarding.signInHere')} onClick={() => navigate("/v2/login")} />
                    </>
                )}
            </Gap>
        )}>
            <div className={styles.content}>

                <div className={styles.headline}>
                    <Steps currentStep={currentStep > 4 ? 4 : currentStep} totalSteps={totalSteps} />
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {getTitle()}
                    </StyledText>
                </div>

                {getContent()}
            </div>
        </DefaultPageLayout>
    )

}

export default NewAgreementPage