import { FileDto } from "Apis/Models/FileDto";
import { useBase64File, useUploadFile } from "Apis/FileApi";
import styles from './FileUploadButton.module.scss';
import The0MissionIcon from "Assets/Icons/The0MissionIcon";
import React from "react";

type Accept = '.doc' | '.pdf' | '.png' | '.jpg' | '.jpeg' | '.svg';

interface IProps {
    callback: (file: FileDto) => void;
    multiple?: boolean;
    accept?: Accept[];
    text?: string;
}
const FileUploadButton = (props: IProps) => {
    
    const { callback, multiple, accept, text } = props;
    
    const convert = useBase64File();
    
    const { mutateAsync: uploadFileMutation, isLoading: isUploadingFile } = useUploadFile();
    
    const uploadFile = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            const base64 = await convert(e.target.files[i]);
            
            let file = await uploadFileMutation({
                fileContent: base64,
                contentType: e.target.files[i].type,
                fileName: e.target.files[i].name
            });
            
            callback(file);
        }
    }
    
    const getAccept = (): string => {
        if (accept) {
            return accept.join(',');
        } else {
            return ['.png', '.jpg', '.jpeg', '.svg', ".pdf", ".doc"].join(',');
        }
    }
    
    return (
        <label className={styles.label}>
            <input accept={getAccept()} multiple={multiple} type={"file"} onChange={uploadFile} />
            <div className={`${styles.loader} ${isUploadingFile && styles.visible}`}>
                <The0MissionIcon width={14} height={14} spin />
            </div>
            {text ?? "Upload"}
        </label>
    )
}

export default FileUploadButton
