import * as React from "react"
import { SVGProps } from "react"
const CustomUsageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={40} height={40} viewBox={"0 0 40 40"} {...props}>
        <path
            fill="#B386CF"
            fillRule="evenodd"
            d="M16.472 3.678a3.167 3.167 0 0 1 3.477 2.537l.042.278.009.174v11.666c0 .855.643 1.56 1.472 1.656l.194.011H33a3.333 3.333 0 0 1 3.333 3.333c0 .127-.014.253-.043.377A16.667 16.667 0 1 1 15.839 3.82l.451-.11.182-.032Zm.194 14.655L16.665 7.05l-.038.01a13.334 13.334 0 1 0 16.197 16.722l.124-.45-11.282.001a5 5 0 0 1-4.991-4.706l-.009-.294ZM24.624 4.21a1.66 1.66 0 0 1 .367-.043l.203.012.188.033.171.05a16.667 16.667 0 0 1 10.005 9.696l.18.49a1.667 1.667 0 0 1-1.373 2.208l-.199.012h-7.5a3.334 3.334 0 0 1-3.324-3.085l-.009-.249v-7.49l.01-.198a1.8 1.8 0 0 1 .034-.194l.05-.171c.066-.186.161-.353.279-.498l.119-.134c.05-.046.096-.087.144-.125l.15-.105.148-.082.17-.072.187-.055Zm2.413 4.437-.37-.22v4.907h4.906l-.219-.37a13.34 13.34 0 0 0-3.846-4.012l-.47-.305Z"
            clipRule="evenodd"
        />
    </svg>
)
export default CustomUsageIcon
