import Footer from "./Footer";
import TopMenu from "./TopMenu";
import styles from "./DefaultPageLayout.module.scss";

interface Props {
    menuContent?: any;
    children: any;
    contentMaxWidth?: number
}
const DefaultPageLayout = (props: Props) => {

    return (
        <div className={styles.container}>
            <TopMenu contentMaxWidth={props.contentMaxWidth}>
                {props.menuContent}
            </TopMenu>
            <main className={styles.content}>
                <div style={{maxWidth: props.contentMaxWidth ?? '100%', width: '100%'}}>
                    {props.children}
                </div>
            </main>
            <Footer contentMaxWidth={props.contentMaxWidth} />
        </div>
    )

}

export default DefaultPageLayout