import * as RadioGroup from '@radix-ui/react-radio-group';
import styles from "./RadioButtons.module.scss";
import { StyledText } from './Text';

interface Props {
    value: string;
    onChange: (value: string) => void;
    options: { label: string, value: string }[];
}
const RadioButtons = (props: Props) => {

    return (
        <RadioGroup.Root className={styles.container} onValueChange={props.onChange} value={props.value} defaultValue={props.value} aria-label="View density">
            {props.options.map((option, index) => (
                <div className={styles.itemContainer} key={option.value}>
                    <RadioGroup.Item className={styles.item} value={option.value}>
                        <RadioGroup.Indicator className={styles.indicator} />
                    </RadioGroup.Item>
                    <StyledText type={"Caption"} color={"secondary"}>
                        {option.label}
                    </StyledText>
                </div>
            ))}
        </RadioGroup.Root>
    )

}

export default RadioButtons