import * as React from "react"
import { SVGProps } from "react"
const BurgerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 18}
    height={props.height ?? 18}
    fill="none"
    viewBox={"0 0 18 18"}
    {...props}
  >
    <path
      fill="#616161"
      fillRule="evenodd"
      d="M15 4.25a.75.75 0 0 1 .088 1.495L15 5.75H3a.75.75 0 0 1-.087-1.495L3 4.25h12Zm0 4.5a.75.75 0 0 1 .088 1.495L15 10.25H3a.75.75 0 0 1-.087-1.495L3 8.75h12Zm.75 5.25a.75.75 0 0 0-.75-.75H3l-.087.005A.75.75 0 0 0 3 14.75h12l.088-.005A.75.75 0 0 0 15.75 14Z"
      clipRule="evenodd"
    />
  </svg>
)
export default BurgerIcon
