import { Navigate } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";

const RedirectIfLoggedInV2 = ({ children }) => {

    const user = useAuthProvider();
    
    return user.firebaseUser === null ? children : <Navigate to={"/v2/dashboard"} />;
}

export default RedirectIfLoggedInV2;
