import { useQuery } from "react-query";
import { ISolarParkDto } from "Apis/Models/ISolarPark";
import { request } from "Apis/BaseApi";

export const SOLAR_PARK_CACHE_KEY = 'SOLAR_PARK_CACHE_KEY';
export const useSolarParks = () => {
    return useQuery<ISolarParkDto[]>(SOLAR_PARK_CACHE_KEY, async () => {
        return await request<ISolarParkDto[]>({
            url: `/solarParks`,
            method: 'GET',
        })
    });
}
