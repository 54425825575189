import {toast} from "react-toastify";
import ThumbsUp from "../Assets/Icons/V2/ThumbsUp";
import WarningTriangle from "../Assets/Icons/V2/WarningTriangle";

const useToast = () => {

    const showSuccessToast = (message: string, icon: any = null) =>  toast.success(message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        icon: icon ?? <ThumbsUp />
    });

    const showErrorToast = (message: string, icon: any = null) => toast.error(message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        icon: icon ?? <WarningTriangle fill={"white"} />,
    });

    return { showSuccessToast, showErrorToast }

}

export default useToast