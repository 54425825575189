import CenteredPageLayout from "Layouts/CenteredPageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {useAgreement, useCurrentAgreementTerms, useSendContract, useSignContract} from "Apis/AgreementApi";
import { useForm } from "react-hook-form";
import FlexRow from "Components/FlexRow";
import Step from "Components/Step";
import styles from './SignContractPage.module.scss';
import Checkbox from "Components/Checkbox";
import Button from "Components/Button";
import Space from "../../../Components/Space";
import Input from "../../../Components/Input";
import Modal from "../../../Components/Modal";
import {useEffect, useState} from "react";
import useToast from "../../../Hooks/useToast";

interface FormFields {
    iUnderstand: boolean;
}
const SignContactPage = () => {
    
    const { t } = useTranslation();
    const { showSuccessToast } = useToast();


    const { agreementId } = useParams();
    const { data: agreement } = useAgreement(agreementId!);
    const { data: agreementTerms } = useCurrentAgreementTerms(agreementId);
    const { mutateAsync: signContractMutation, isLoading } = useSignContract();
    const { mutateAsync: sendContractMutation, isLoading: isSendingContract } = useSendContract();

    const { control, formState: { errors }, handleSubmit } = useForm<FormFields>()
    const { control: emailControl, handleSubmit: handleEmailSubmit, reset: resetSendEmailForm } = useForm<{receiverEmail: string}>()

    const [sendEmailModalShown, setSendEmailModalShown] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!!agreement && agreement.agreementStatus !== 'Pending') {
            navigate(`/agreements/${agreement.id}/dashboard`);
            return;

        }
    }, [agreement, navigate])

    const sendEmail = async (data) => {
        await sendContractMutation({
            receiverEmail: data.receiverEmail,
            agreementId: agreementId!
        })

        setSendEmailModalShown(false);

        showSuccessToast(t('agreement:agreementSent'));

        resetSendEmailForm()
    }

    const closeModal = () => {
        setSendEmailModalShown(false);
    }

    const signContract = async (data: FormFields) => {
        await signContractMutation({agreementId: agreement!.id, agreementTermsId: agreementTerms!.id});
        
        navigate(`/agreements/${agreementId}/dashboard`);
    }
    
    return (
        <CenteredPageLayout maxWidth={600}>
            
            <Step currentStep={5} numberOfSteps={5} />
            
            <hr/>
            
            <FlexRow justify={"center"} direction={"column"}>
                <h1 className={"text-align-center"}>
                    {t('agreement:signContract.h1')}
                </h1>
                
                <p className={"text-align-center"}>
                    {t('agreement:signContract.explainer')}
                </p>
            </FlexRow>
            
            <div className={styles.contactArea}>
                {agreementTerms?.text}
            </div>
            
            <form onSubmit={handleSubmit(signContract)} style={{paddingTop: 40}}>
                <FlexRow justify={"space-between"}>
                    <Checkbox name={"iUnderstand"} control={control} label={t('agreement:signContact.iUnderstand')} rules={{required: true}} errors={errors.iUnderstand} />
                    
                    <Button loading={isLoading} disabled={isLoading} type={"submit"}>{t('agreement:signContact.sign')}</Button>
                </FlexRow>
            </form>

            <FlexRow justify={"space-between"} style={{paddingTop: 100, paddingBottom: 100}}>
                <p>
                    {t('agreement:sendContractExplainerText')}
                </p>
                <Button onClick={() => setSendEmailModalShown(true)}>{t('agreement:sendAgreement')}</Button>
            </FlexRow>

            <Modal title={t('agreement:sendAgreement')} visible={sendEmailModalShown} onCancel={closeModal}>
                <form onSubmit={handleEmailSubmit(sendEmail)}>
                    <Space size={"large"} direction={"column"}>
                        <div className={"w-100"}>
                            <Input name={"receiverEmail"} control={emailControl} label={t('email')} placeholder={t('email')} />
                        </div>

                        <FlexRow justify={"center"}>
                            <Button loading={isSendingContract} disabled={isSendingContract} type={"submit"}>{t('agreement:sendAgreement')}</Button>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </CenteredPageLayout>
    )
}

export default SignContactPage
