import { useMutation } from "react-query";
import { request } from "Apis/BaseApi";

export const useSetCustomUsage = (agreementId: string) => {
    return useMutation(async (data: {yearlyUsage: number}) => {
        return await request({
            url: `/CustomAgreementUsage/${agreementId}`,
            method: 'POST',
            data
        })
    });
}
