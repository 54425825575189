import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import {ISolarParkAnnualReportDto} from "./Models/ISolarParkAnnualReportDto";

export const SOLAR_PARK_ANNUAL_REPORTS_CACHE_KEY = 'SOLAR_PARK_ANNUAL_REPORTS_CACHE_KEY';
export const useAnnualReports = () => {
    return useQuery<ISolarParkAnnualReportDto[]>(SOLAR_PARK_ANNUAL_REPORTS_CACHE_KEY, async () => {
        return await request<ISolarParkAnnualReportDto[]>({
            url: `/solarparks/annualreports`,
            method: 'GET',
        })
    });
}
