import { createContext, ReactElement, ReactNode, useContext, useState } from "react";
import styles from './TopMenuLayout.module.scss';
import The0MissionIcon from "Assets/Icons/The0MissionIcon";
import {Link, useLocation, useMatch, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { signOut } from "FirebaseSetup/auth";
import { useQueryClient } from "react-query";
import { useAuthProvider } from "Providers/AuthProvider";

interface IContextProps {
    title: string | null;
    setTitle: Function;
}
export const TopMenuLayoutContextProvider = createContext<IContextProps>({
    title: null,
    setTitle: () => {
        console.error("not implemented")
    }
});

interface Props {
    menu: ReactElement;
    children: ReactNode;
}

const TopMenuLayout = (props: Props) => {
    
    const { t } = useTranslation();
    
    const { userContext } = useAuthProvider();
    
    const path = useLocation().pathname;
    
    const queryClient = useQueryClient();
    
    const navigate = useNavigate();
    
    const [title, setTitle] = useState<string | null>(null);
    
    const signOutOnClick = () => {
        signOut();
        queryClient.clear();
    }
    
    return (
        <TopMenuLayoutContextProvider.Provider value={{title, setTitle}}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div>
                    
                    </div>
                    <div className={styles.center}>
                        <div className={styles.preHeader}>
                            The 0-Mission
                        </div>
                        <div className={styles.heroHeader}>
                            {title ? title : path.startsWith('/admin') ? t('adminDashboard') : 'The 0-Mission'}
                        </div>
                    </div>
                    <div className={styles.end}>
                    
                    </div>
                </div>
                
                <div className={styles.menu}>
                    <div>
                        {props.menu}
                    </div>
                    
                    <div>
                        {userContext!.permissions.includes('Admin') && path.startsWith('/admin') && (
                            <div onClick={_ => navigate('/agreements')} className={"clickable"}>
                                Kundeside
                            </div>
                        )}
                        {userContext!.permissions.includes('Admin') && !path.startsWith('/admin') && (
                            <div onClick={_ => navigate('/admin')} className={"clickable"}>
                                Admin
                            </div>
                        )}
                        <div onClick={signOutOnClick} className={"clickable"}>
                            {t('signOut')}
                        </div>
                    </div>
                    
                </div>
                
                <div className={styles.content}>
                    {props.children}
                </div>
                
                <div className={styles.footer}>
                    <div>
                        <The0MissionIcon height={125} width={125} />
                    </div>
                    <div>
                        <div>
                            The 0-Mission ApS
                        </div>
                        <div>
                            Fæstningens Materialgård 30
                        </div>
                        <div>
                            1220 København K
                        </div>
                        <div>
                            hello@the0mission.dk
                        </div>
                        <div>
                            Tlf: 26 23 60 60
                        </div>
                    </div>
                </div>
            </div>
        </TopMenuLayoutContextProvider.Provider>
    )
}

export const TopMenuLink = ({ url, matchUrl,  text, exact }: { url: string, matchUrl?: string, text: string, exact?: boolean}) => {

    const match = useMatch({ path: matchUrl ?? url, end: exact })

    return (
        <Link to={url} style={{textDecoration: 'none', fontWeight: !!match ? 700 : 400}}>{text}</Link>
    )
}

export default TopMenuLayout

export const useTopMenuLayoutProvider = () => {
    return useContext(TopMenuLayoutContextProvider);
}
