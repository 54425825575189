import {useQuery} from "react-query";
import {InvoiceModel} from "./Models/InvoiceModel";
import {request} from "./BaseApi";
import { saveAs } from 'file-saver';

const AGREEMENT_INVOICES_CACHE_KEY = 'AGREEMENT_INVOICES_CACHE_KEY';
export const useInvoices = (agreementId: string | undefined) => {
    return useQuery<InvoiceModel[]>([AGREEMENT_INVOICES_CACHE_KEY, agreementId], async () => {
        return await request<InvoiceModel[]>({
            url: `/agreements/${agreementId}/invoices`,
            method: 'GET'
        })
    }, {
        enabled: !!agreementId
    });
}

export const getInvoicePdf = async (invoice: InvoiceModel): Promise<any> => {

    const responseBlob = await request<Blob>({
        responseType: 'blob',
            url: `/agreements/${invoice.agreementId}/invoices/${invoice.id}/download`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, `The 0-Mission faktura ${invoice.invoiceNumber}.pdf`);
}