import Gap from "../../Components/V2/Gap";
import {StyledText} from "../../Components/V2/Text";
import styles from "./Onboarding/NewAgreementPage.module.scss";
import DefaultPageLayout from "../../Layouts/V2/DefaultPageLayout";
import {useTranslation} from "react-i18next";
import Box from "Components/V2/Box";
import EmailIcon from "Assets/Icons/V2/EmailIcon";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useAuthProvider} from "../../Providers/AuthProvider";
import OnboardingBoxContent from "../../Features/Onboarding/Components/OnboardingBoxContent";

const VerifyEmailPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const user = useAuthProvider();

    useEffect(() => {
        if (user.firebaseUser?.emailVerified) {
            navigate('/v2/dashboard');
        }
    }, [user, navigate])
    
    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.content}>

                <div className={styles.headline}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('onboarding.verifyEmail.headline')}
                    </StyledText>
                </div>

                <Box style={{width: 840}}>
                    <OnboardingBoxContent content={(
                        <Gap size={"medium"} direction={"vertical"}>
                            <EmailIcon />

                            <StyledText type={"Caption"}>
                                {t('onboarding.verifyEmail.text1')}
                            </StyledText>

                            <StyledText type={"Caption"}>
                                {t('onboarding.verifyEmail.text2')}
                            </StyledText>
                        </Gap>
                    )} />
                </Box>

            </div>

        </DefaultPageLayout>
    )

}

export default VerifyEmailPage