import * as React from "react"
import { SVGProps } from "react"

const CheckmarkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={13}
        height={11}
        viewBox="0 0 13 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.99 2.351 11.58.931l-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99 8-8Z"
            fill={props.fill ?? '#153629'}
        />
    </svg>
)

export default CheckmarkIcon
