import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import {AcceptedTermsModel, AgreementModel} from "Apis/Models/AgreementModel";
import { UserModel } from "Apis/Models/UserModel";
import { CurrentAgreementUsageModel } from "Apis/Models/CurrentAgreementUsageModel";
import { AGREEMENT_USAGE_DATA_CACHE_KEY } from "Apis/UsageApi";
import { InvoiceModel } from "Apis/Models/InvoiceModel";
import { EloverblikAuthorizationUrl } from "Apis/Models/EloverblikAuthorizationUrl";
import { useTranslation } from "react-i18next";
import useToast from "Hooks/useToast";
import {EloverblikAuthorizationModel} from "../Models/EloverblikAuthorizationModel";

export const ADMIN_AGREEMENTS_CACHE_KEY = 'ADMIN_AGREEMENTS_CACHE_KEY';
export const useAgreements = () => {
    return useQuery<AgreementModel[]>(ADMIN_AGREEMENTS_CACHE_KEY, async () => {
        return await request<AgreementModel[]>({
            url: `/admin/agreements`,
            method: 'GET',
        })
    });
}

export const ADMIN_AGREEMENT_CACHE_KEY = 'ADMIN_AGREEMENT_CACHE_KEY';
export const useAgreement = (id: string) => {
    return useQuery<AgreementModel>([ADMIN_AGREEMENT_CACHE_KEY, id], async () => {
        return await request<AgreementModel>({
            url: `/admin/agreements/${id}`,
            method: 'GET',
        })
    });
}

export const ADMIN_AGREEMENT_INVOICES_CACHE_KEY = 'ADMIN_AGREEMENT_INVOICES_CACHE_KEY';
export const useInvoices = (id: string) => {
    return useQuery<InvoiceModel[]>([ADMIN_AGREEMENT_INVOICES_CACHE_KEY, id], async () => {
        return await request<InvoiceModel[]>({
            url: `/admin/agreements/${id}/invoice`,
            method: 'GET',
        })
    });
}

export const useSaveAgreement = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (agreement: AgreementModel): Promise<AgreementModel> => {
        if (agreement.id) {
            return await request<AgreementModel>({
                url: `/admin/agreements/${agreement.id}`,
                method: 'PUT',
                data: agreement
            })
        } else {
            return await request<AgreementModel>({
                url: `/admin/agreements`,
                method: 'POST',
                data: agreement
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
        }
    })
}

export const useAddUserToAgreement = (agreementId: string) => {
    const queryClient = useQueryClient();
    
    return useMutation(async (user: UserModel) => {
        return await request<UserModel>({
            url: `/admin/agreements/${agreementId}/user`,
            method: 'PUT',
            data: user
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
        }
    })
}

export const useFindAuthorizations = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (data: {externalId: string}) => {
        return await request({
            url: `/admin/agreements/authorization/find?externalId=${data.externalId}`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
        }
    })
}

export const useAddAuthorizationToAgreement = (agreementId: string) => {
    const queryClient = useQueryClient();
    
    return useMutation(async (id: string) => {
        return await request({
            url: `/admin/agreements/${agreementId}/authorization?externalId=${id}`,
            method: 'PUT',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
        }
    })
}

export const useDeleteAuthorization = (agreementId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async (authorization: EloverblikAuthorizationModel) => {
        return await request({
            url: `/admin/agreements/${agreementId}/authorization/${authorization.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
            queryClient.invalidateQueries(AGREEMENT_USAGE_DATA_CACHE_KEY);
        }
    })
}

export const useSaveCustomUsageToAgreement = (agreementId: string) => {
    const queryClient = useQueryClient();
    
    return useMutation(async (data: CurrentAgreementUsageModel) => {
        if (data.id) {
            return await request<AgreementModel>({
                url: `/admin/agreements/${agreementId}/customUsage/${data.id}`,
                method: 'PUT',
                data: data
            })
        } else {
            return await request<AgreementModel>({
                url: `/admin/agreements/${agreementId}/customUsage`,
                method: 'POST',
                data: data
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
        }
    })
}

export const useDeleteCustomUsage = (agreementId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async (customUsage: CurrentAgreementUsageModel) => {
        return await request({
            url: `/admin/agreements/${agreementId}/customUsage/${customUsage.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
        }
    })
}


export const useLoadUsageFromEloverblik = (agreementId: string) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { showSuccessToast } = useToast();

    return useMutation(async () => {
        return await request({
            url: `/admin/agreements/${agreementId}/load`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
            queryClient.invalidateQueries(AGREEMENT_USAGE_DATA_CACHE_KEY);

            showSuccessToast(t('agreement:eloverblikDataLoaded'));
        },

    })
}

export const useSaveAcceptedTerms = (agreementId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async (data: Partial<AcceptedTermsModel>) => {
        if (data.id) {
            return await request<AgreementModel>({
                url: `/admin/agreements/${agreementId}/acceptedTerms/${data.id}`,
                method: 'PUT',
                data: data
            })
        } else {
            return await request<AgreementModel>({
                url: `/admin/agreements/${agreementId}/acceptedTerms`,
                method: 'POST',
                data: data
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
            queryClient.invalidateQueries(AGREEMENT_USAGE_DATA_CACHE_KEY);
        }
    })
}

export const useDeleteAcceptedTerms = (agreementId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async (data: AcceptedTermsModel) => {
        return await request({
            url: `/admin/agreements/${agreementId}/acceptedTerms/${data.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
        }
    })
}


export const useAuthorizationUrl = (agreementId: string) => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async () => {
        return await request<EloverblikAuthorizationUrl>({
            url: `/admin/agreements/${agreementId}/authorizationUrl`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
        }
    });
}

export const useDeleteAgreement = () => {
    const queryClient = useQueryClient();

    return useMutation(async (agreement: AgreementModel) => {
        return await request({
            url: `/admin/agreements/${agreement.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_AGREEMENTS_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_AGREEMENT_CACHE_KEY);
        }
    })
}
