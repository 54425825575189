import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";

import { ISocialMediaPostDto } from "Apis/Models/SocialMedia";

export const ADMIN_SOCIAL_MEDIA_POSTS_CACHE_KEY = 'ADMIN_SOCIAL_MEDIA_POSTS_CACHE_KEY';
export const useSocialMediaPosts = () => {
    return useQuery<ISocialMediaPostDto[]>(ADMIN_SOCIAL_MEDIA_POSTS_CACHE_KEY, async () => {
        return await request<ISocialMediaPostDto[]>({
            url: `/admin/socialmedia`,
            method: 'GET',
        })
    });
}

export const useSaveSocialMediaPost = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (post: ISocialMediaPostDto): Promise<ISocialMediaPostDto> => {
        if (post.id) {
            return await request<ISocialMediaPostDto>({
                url: `/admin/socialmedia/${post.id}`,
                method: 'PUT',
                data: post
            })
        } else {
            return await request<ISocialMediaPostDto>({
                url: `/admin/socialmedia`,
                method: 'POST',
                data: post
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_SOCIAL_MEDIA_POSTS_CACHE_KEY);
        }
    })
}

export const useDeleteSocialMediaPost = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (post: ISocialMediaPostDto)=> {
        return await request({
            url: `/admin/socialmedia/${post.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_SOCIAL_MEDIA_POSTS_CACHE_KEY);
        }
    })
}
