
import Gap from "../../../Components/V2/Gap";
import {StyledText} from "../../../Components/V2/Text";
import Separator from "../../../Components/V2/Separator";
import React, {useState} from "react";
import Box from "Components/V2/Box";
import { useTranslation } from "react-i18next";
import {useAuthProvider} from "../../../Providers/AuthProvider";
import InputLabel from "../../../Components/V2/InputLabel";
import {ControlledInput} from "../../../Components/V2/Input";
import {useForm} from "react-hook-form";
import styles from "./ChangePasswordPage.module.scss";
import FlexRow from "Components/FlexRow";
import Button from "../../../Components/V2/Button";
import {usePasswordChecks} from "../../../Hooks/UsePasswordChecks";
import CircledCheckmark from "../../../Assets/Icons/CircledCheckmark";
import CircledX from "../../../Assets/Icons/CircledX";
import {EmailCredentialsProvider, reauthenticate, updatePassword} from "../../../FirebaseSetup/auth";
import useToast from "../../../Hooks/useToast";

type FormData = {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
}
const ChangePasswordPage = () => {

    const { t } = useTranslation();
    const { userContext, firebaseUser } = useAuthProvider();
    const { showSuccessToast, showErrorToast } = useToast();

    const [buttonState, setButtonState] = useState<"None" | "Loading" | "Success">("None")

    const [passwordChecks, , setFirstPassword, setSecondPassword] = usePasswordChecks(); // empty comma is needed here to make sure the correct methods are used

    const { control, handleSubmit, reset } = useForm<FormData>();


    const changePassword = async (data: FormData) => {

        if (passwordChecks.filter(x => !x.fulfilled).length > 0) {
            showErrorToast(t('auth:passwordChecksFailed'));
            return;
        }

        setButtonState("Loading")

        try {
            const credentials = EmailCredentialsProvider.credential(
                userContext!.email,
                data.oldPassword
            );
            await reauthenticate(firebaseUser!, credentials);

            await updatePassword(data.newPassword);

            showSuccessToast(`${t('success')} ${t('passwordUpdated')}` );

            reset({});

            setButtonState("Success")
            setFirstPassword("")
            setSecondPassword("")
        }
        catch (e: any) {
            let message = t('somethingWentWrong');

            if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
                message = t('auth:wrongPassword');
            }

            if (e.code === 'auth/weak-password') {
                message = t('auth:weakPassword');
            }

            showErrorToast(message);

            setButtonState("None")
        }
    }

    return (
        <Box>
            <Gap direction={"vertical"} size={"medium"}>
                <div>
                    <StyledText tag={"div"} type={"Lead"} weight={"Bold"} color={"secondary"}>{t('changePassword')}</StyledText>
                    <StyledText type={"Body"} weight={"Regular"} color={"secondary"}>{userContext?.firstname} {userContext?.lastname}</StyledText>
                </div>

                <form onSubmit={handleSubmit(changePassword)} style={{width: '100%'}}>
                    <Gap size={"large"} direction={"vertical"}>
                        <Gap size={"small"} direction={"vertical"} className={"w-100"}>
                            <div className={"w-100"}>
                                <InputLabel required>
                                    {t('currentPassword')}
                                </InputLabel>
                                <ControlledInput name={'oldPassword'} placeholder={t('enterYourPassword')} type={"password"} control={control} rules={{required: true}} className={styles.inputFieldLength} />
                            </div>

                            {/**
                                <StyledText type={"Caption"}>{t('forgotYourPAssword?')}<StyledText type={"Caption"} color={"primary"}> {t("resetItHere")}</StyledText></StyledText>
                             */}
                        </Gap>

                        <Separator/>

                        <Gap size={"medium"} className={"w-100"}>
                            <div className={styles.inputFieldLength}>
                                <InputLabel required>
                                    {t('newPassword')}
                                </InputLabel>
                                <ControlledInput name={'newPassword'} placeholder={t('enterYourNewPassowrd')} type={"password"} control={control} rules={{required: true}} className={styles.inputFieldLength} onChange={e => setFirstPassword(e.currentTarget.value)} />
                            </div>

                            <div className={styles.inputFieldLength} >
                                <InputLabel required>
                                    {t('repeatNewPassword')}
                                </InputLabel>
                                <ControlledInput name={'repeatNewPassword'} placeholder={t('enterYourNewPassowrd')} type={"password"} control={control} rules={{required: true}} className={styles.inputFieldLength} onChange={e => setSecondPassword(e.currentTarget.value)} />
                            </div>
                        </Gap>

                        <Gap size={"small"} direction={"vertical"}>
                            {passwordChecks.map((x, index) => (
                                <Gap size={"small"} key={index}>
                                    {x.fulfilled ? <CircledCheckmark /> : <CircledX />}
                                    <StyledText type={"Caption"} color={"secondary"}>
                                        {x.message}
                                    </StyledText>
                                </Gap>
                            ))}
                        </Gap>

                        <Gap className={"w-100"} direction={"vertical"} size={"small"}>
                            <Separator/>

                            <FlexRow justify={"end"}>
                                <Button text={t('saveChanges')} state={buttonState} type={"submit"}  />
                            </FlexRow>
                        </Gap>
                    </Gap>
                </form>
            </Gap>
        </Box>
    )

}

export default ChangePasswordPage