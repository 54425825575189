import {useMutation, useQuery, useQueryClient} from "react-query";
import { request } from "Apis/BaseApi";
import {ISolarParkAnnualReportDto} from "../Models/ISolarParkAnnualReportDto";

export const ADMIN_SOLAR_PARK_ANNUAL_REPORTS_CACHE_KEY = 'ADMIN_SOLAR_PARK_ANNUAL_REPORTS_CACHE_KEY';
export const useAnnualReports = () => {
    return useQuery<ISolarParkAnnualReportDto[]>(ADMIN_SOLAR_PARK_ANNUAL_REPORTS_CACHE_KEY, async () => {
        return await request<ISolarParkAnnualReportDto[]>({
            url: `/solarparks/annualreports`,
            method: 'GET',
        })
    });
}

export const useSaveAnnualReport = () => {
    const queryClient = useQueryClient();

    return useMutation(async (annualReport: ISolarParkAnnualReportDto): Promise<ISolarParkAnnualReportDto> => {
        if (annualReport.id) {
            return await request<ISolarParkAnnualReportDto>({
                url: `/admin/solarparks/annualReports/${annualReport.id}`,
                method: 'PUT',
                data: annualReport
            })
        } else {
            return await request<ISolarParkAnnualReportDto>({
                url: `/admin/solarparks/annualReports`,
                method: 'POST',
                data: annualReport
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_SOLAR_PARK_ANNUAL_REPORTS_CACHE_KEY);
        }
    })
}

export const useDeleteAnnualReport = () => {
    const queryClient = useQueryClient();

    return useMutation(async (annualReport: ISolarParkAnnualReportDto) => {
        return await request({
            url: `/admin/solarParks/annualReports/${annualReport.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_SOLAR_PARK_ANNUAL_REPORTS_CACHE_KEY);
        }
    })
}

