import {SVGProps} from "react";

const InvoiceIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M17 21.9992V22.0002H7C5.34 22.0002 4 20.6502 4 19.0002V5.00016C4 3.34016 5.34 2.00016 7 2.00016H13.9813L14 2V1.99916C14.26 1.98916 14.52 2.09916 14.71 2.28916L19.71 7.28916C19.807 7.38621 19.8837 7.50652 19.9338 7.63911C19.9726 7.74018 19.9954 7.84953 19.9994 7.9643C19.9998 7.9759 20 7.98752 20 7.99916V8V18.9992C20 20.6492 18.65 21.9992 17 21.9992ZM7 4.00016H13V7C13 8.1 13.89 9 15 9H18V19.0002C18 19.5502 17.55 20.0002 17 20.0002H7C6.44 20.0002 6 19.5502 6 19.0002V5.00016C6 4.44016 6.44 4.00016 7 4.00016ZM15 7V5.41016L16.5898 7H15ZM9 8H10C10.55 8 11 7.55 11 7C11 6.44 10.55 6 10 6H9C8.44 6 8 6.44 8 7C8 7.55 8.44 8 9 8ZM15 14H9C8.44 14 8 13.55 8 13C8 12.44 8.44 12 9 12H15C15.55 12 16 12.44 16 13C16 13.55 15.55 14 15 14ZM12 17C12 17.55 12.44 18 13 18H15C15.55 18 16 17.55 16 17C16 16.44 15.55 16 15 16H13C12.44 16 12 16.44 12 17Z" fill="#616161"/>
        </svg>
    )
}

export default InvoiceIcon