import {CSSProperties} from "react";

type Props = {
    gridTemplateRows?: string;
    gridTemplateColumns?: string;
    gap?: number;
    columnGap?: number;
    rowGap?: number;
    children: any;
    style?: CSSProperties;
    className?: string;
}
const Grid = (props: Props) => {

    return (
        <div style={{display: 'grid', gridTemplateRows: props.gridTemplateRows, gridTemplateColumns: props.gridTemplateColumns, gap: props.gap, columnGap: props.columnGap, rowGap: props.rowGap, ...props.style}} className={props.className}>
            {props.children}
        </div>
    )

}

export default Grid