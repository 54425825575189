import {SVGProps} from "react";


const DashboardIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.6129 5.20962C16.1882 4.87913 15.565 4.94315 15.2192 5.37532L11.7241 9.74401L8.44732 8.10559L8.33273 8.05688C7.9067 7.90622 7.42449 8.06066 7.16806 8.44531L3.16806 14.4453L3.10896 14.5461C2.88115 14.9919 3.0187 15.5476 3.44541 15.8321L3.54617 15.8912C3.99203 16.119 4.54769 15.9814 4.83216 15.5547L8.34211 10.289L11.5529 11.8944L11.6699 11.944C12.0659 12.0828 12.5132 11.9594 12.781 11.6247L16.0821 7.49701L19.293 10.7071L19.3872 10.7903C19.7795 11.0953 20.3467 11.0676 20.7072 10.7071C21.0977 10.3166 21.0977 9.68343 20.7072 9.29291L16.7072 5.29291L16.6129 5.20962ZM21.0001 19C21.0001 18.4477 20.5524 18 20.0001 18H4.00011L3.88349 18.0067C3.38615 18.0645 3.00011 18.4872 3.00011 19C3.00011 19.5523 3.44782 20 4.00011 20H20.0001L20.1167 19.9933C20.6141 19.9355 21.0001 19.5128 21.0001 19Z" fill="#616161"/>
        </svg>
    )
}

export default DashboardIcon