import { useTranslation } from "react-i18next";
import styles from './LogoPage.module.scss';
import Button from "Components/Button";
import { saveAs } from 'file-saver';
import { useState } from "react";
import Back from "Components/Back";
import { AgreementModel } from "Apis/Models/AgreementModel";
import { useSelectEntries } from "Hooks/UseSelectEntries";
import { UncontrolledCheckbox } from "Components/Checkbox/Checkbox";
import { useLogos } from "Apis/LogoApi";

interface IProps {
    agreement: AgreementModel;
}
const LogoPage = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { data: logos } = useLogos();
    
    const [isAllImagesSelected, selectedImages, isImageSelected, toggleImageSelected, toggleAllImagesSelected] = useSelectEntries(logos?.map(x => x.file.id) ?? [])
    
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    
    const downloadImages = async () => {
        setIsDownloading(true);
        
        for (let i = 0; i < selectedImages.length; i++) {
            
            try {
                const image = logos?.find(x => x.file.id === selectedImages[i])!.file;
                
                const response = await fetch(image!.url);
                
                const blob = await response.blob();
                
                saveAs(blob, image!.fileName);
            }
            catch (e) {
                console.error(e);
            }
        }
        
        setIsDownloading(false);
    }
    
    return (
        <>
            <Back text={t('agreement:logoPage.back')} />
            
            <div className={styles.contentGrid}>
                <div className={styles.imageGrid}>
                    {logos?.map(logo => (
                        <div key={logo.file.id} className={`${styles.imageContainer} ${isImageSelected(logo.file.id) && styles.selected}`} onClick={_ => toggleImageSelected(logo.file.id)}>
                            <div className={styles.image}>
                                <img src={logo.file.url} alt={""} />
                            </div>
                            
                            <div className={styles.imageName}>
                                {logo.name}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.downloadContainer}>
                    <UncontrolledCheckbox name={"allSelected"} value={isAllImagesSelected} onChange={() => toggleAllImagesSelected()} label={t('selectAll')} />
                    
                    <Button loading={isDownloading} onClick={downloadImages} color={"link"}>{t('downloadSelected')}</Button>
                </div>
            </div>
        </>
    )
}

export default LogoPage
