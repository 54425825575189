const LinkedInIcon = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M22 22H18V15.001C18 13.081 17.153 12.01 15.634 12.01C13.981 12.01 13 13.126 13 15.001V22H9V9H13V10.462C13 10.462 14.255 8.26 17.083 8.26C19.912 8.26 22 9.986 22 13.558V22ZM4.442 6.921C3.093 6.921 2 5.819 2 4.46C2 3.102 3.093 2 4.442 2C5.79 2 6.883 3.102 6.883 4.46C6.884 5.819 5.79 6.921 4.442 6.921ZM2 22H7V9H2V22Z" fill="#153629"/>
        </svg>
    )

}

export default LinkedInIcon