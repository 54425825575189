import styles from './ResourceLink.module.scss';
import { useNavigate } from "react-router-dom";
import {ReactNode} from "react";


export interface ResourceLinkProps {
    url?: string | undefined;
    icon: ReactNode;
    title: string;
    text: string;
    content: ReactNode;
    openInNewTab?: boolean;
    onClick?: Function | undefined;
}
const ResourceLink = (props: ResourceLinkProps) => {
    
    const navigate = useNavigate();

    const onClick = async () => {
        if (props.onClick) {
            await props.onClick();
        }

        if (props.url) {
            props.openInNewTab ? window.open(props.url, '_blank') : navigate(props.url)
        }
    }
    
    return (
        <div className={styles.container} onClick={onClick}>
            <div>
                {props.icon}
            </div>
            <div className={styles.text}>
                <div>
                    {props.title}
                </div>
                <div>
                    {props.text}
                </div>
            </div>
            <div className={styles.content}>
                {props.content}
            </div>
        </div>
        
    )
}

export default ResourceLink
