import styles from './Button.module.scss';
import The0MissionIcon from "Assets/Icons/The0MissionIcon";
import React from "react";

interface Props {
    children?: React.ReactNode;
    type?: 'button' | 'submit';
    loading?: boolean;
    onClick?: Function;
    disabled?: boolean;
    color: 'default' | 'danger' | 'link'
}
const Button = (props: Props) => {
    
    const onClick = () => {
        if (props.disabled) {
            return;
        }
        
        if (props.onClick) {
            props.onClick();
        }
    }
    
    return (
        <button disabled={props.disabled} type={props.type ?? "button"} className={`${styles.button} ${styles[props.color]}  ${props.disabled && styles.disabled}`} onClick={onClick}>
            <div className={`${styles.loader} ${props.loading && styles.visible}`}>
                <The0MissionIcon width={14} height={14} spin />
            </div>
            {props.children}
        </button>
    )
}

Button.defaultProps = {
    color: 'default'
}

export default Button
