import { Navigate, Route, Routes } from "react-router-dom";
import NewAgreementPage from "Pages/Agreements/Setup/NewAgreementPage";
import CustomUsagePage from "Pages/Agreements/Setup/CustomUsagePage";
import ResultPage from "Pages/Agreements/Setup/ResultPage";
import SignupPage from "Pages/Agreements/Setup/SignupPage";
import SignContractPage from "Pages/Agreements/Setup/SignContractPage";
import EloverBlikPage from "Pages/Agreements/Setup/EloverBlikPage";

const SetupAgreementRoutes = () => {
    
    return (
        <Routes>
            <Route path={`:agreementId/eloverblik`} element={<EloverBlikPage />}/>
    
            <Route path={`:agreementId/custom`} element={<CustomUsagePage />}/>
    
            <Route path={`:agreementId/result`} element={<ResultPage />}/>
    
            <Route path={`:agreementId/signup`} element={<SignupPage />}/>
    
            <Route path={`:agreementId/signContact`} element={<SignContractPage />}/>
            
            <Route path={`new`} element={<NewAgreementPage />}/>
            
            <Route path={"*"} element={<Navigate to={"new"}/>}/>
        </Routes>
    )
}

export default SetupAgreementRoutes
