import * as React from "react"
import { SVGProps } from "react"
const CircledPlane = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 41 41"} width={props.width ?? 48} height={props.height ?? 48} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#FDF9B4"
        d="M0 20.5C0 9.178 9.178 0 20.5 0S41 9.178 41 20.5 31.822 41 20.5 41 0 31.822 0 20.5Z"
      />
      <path
        fill="#000"
        d="M31.426 14.63a3.22 3.22 0 0 0-2.52-.187l-2.454.828.948-2.933a.693.693 0 0 0-.166-.713.693.693 0 0 0-.704-.182l-2.479.77a.699.699 0 0 0-.364.271l-3.339 4.844-7.86 2.651-2.114-2.094a.705.705 0 0 0-.698-.172l-2.541.792A.697.697 0 0 0 6.79 19.6l2.364 3.135 2.391 3.01a.702.702 0 0 0 .73.24l4.416-1.24-1.792 5.537a.698.698 0 0 0 .167.709c.187.192.453.26.703.182l2.484-.771a.668.668 0 0 0 .36-.27l5.088-7.381 7.151-2.037a3.217 3.217 0 0 0 1.974-1.599c.417-.78.5-1.682.24-2.53a3.268 3.268 0 0 0-1.64-1.954Zm-6.734-1.14.963-.303-.854 2.636-1.088.37-1.151.385 2.13-3.088Zm6.9 4.953c-.239.447-.64.77-1.124.911l-5.516 1.573.76-1.099a.708.708 0 0 0-.176-.98.7.7 0 0 0-.974.173l-1.87 2.713-5.083 7.39-.964.298 1.781-5.495.917-2.833a.714.714 0 0 0-.443-.891.7.7 0 0 0-.88.448l-.807 2.484L13.4 24.22l-1.052.291-2.099-2.635-1.75-2.328 1.198-.37 2.125 2.104a.693.693 0 0 0 .714.167l8.453-2.854c.005 0 .005 0 .01-.006.005 0 .01 0 .021-.005l3.078-1.041 5.26-1.771c.48-.162.99-.125 1.443.104.453.234.787.63.938 1.12.146.484.099 1-.146 1.448Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h41v41H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default CircledPlane
