import styles from "./DropdownItem.module.scss";

type Props = {
    children: any;
    onClick: () => void;
}
const DropdownItem = (props: Props) => {

    return (
        <div className={styles.item} onClick={props.onClick}>
            {props.children}
        </div>
    )

}

export default DropdownItem