import Box from "../../../Components/V2/Box";
import {useTranslation} from "react-i18next";
import { ControlledInput } from "../../../Components/V2/Input";
import Button from "Components/V2/Button";
import Gap from "../../../Components/V2/Gap";
import {OnboardingValues} from "../Services/OnboardingValues";
import {useForm} from "react-hook-form";
import InputLabel from "../../../Components/V2/InputLabel";
import {useEffect} from "react";
import OnboardingBoxContent from "./OnboardingBoxContent";
import Grid from "../../../Components/V2/Grid";

interface Props {
    values: Partial<OnboardingValues>;
    onChange: (values: Partial<OnboardingValues>, addSteps: number) => void;
    goBack: () => void;
    next: () => void;
}
const ContactPersonDetails = (props: Props) => {

    const { t } = useTranslation();
    
    const { reset, control, handleSubmit } = useForm();

    useEffect(() => {
        reset(props.values)
    }, [reset,  props.values])
    
    const onSubmit = (data) => {
        props.onChange({...props.values, ...data}, 1)
        props.next();
    }
    
    return (
        <Box style={{width: '100%'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <OnboardingBoxContent
                    content={(
                        <>
                        <Grid gridTemplateColumns={"1fr 1fr"} gap={8}>
                            <div>
                                <InputLabel>
                                    {t('firstName')} *
                                </InputLabel>
                                <ControlledInput name={'firstName'} placeholder={t('yourFirstName')} control={control} rules={{required: true}} />
                            </div>

                            <div>
                                <InputLabel>
                                    {t('lastName')} *
                                </InputLabel>
                                <ControlledInput name={'lastName'} placeholder={t('yourLastName')} control={control} rules={{required: true}} />
                            </div>
                        </Grid>

                        <div>
                            <InputLabel>
                                {t('title')} *
                            </InputLabel>
                            <ControlledInput name={'title'} placeholder={t('yourTitle')} control={control} rules={{required: true}} />
                        </div>

                        <div>
                            <InputLabel>
                                {t('phone')} *
                            </InputLabel>
                            <ControlledInput name={'phone'} placeholder={t('yourPhone')} control={control} rules={{required: true}} />
                        </div>
                        </>
                    )}
                    buttons={(
                        <Gap size={"small"} className={"flex justify-end"}>
                            <Button outline text={t('back')} onClick={props.goBack} />
                            <Button text={t('continue')} type={"submit"} />
                        </Gap>
                    )}
                />
            </form>
        </Box>
    )

}

export default ContactPersonDetails