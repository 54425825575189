import { useTranslation } from "react-i18next";
import { useAuthProvider } from "Providers/AuthProvider";
import { useEffect, useState } from "react";
import { sendEmailVerification } from "FirebaseSetup/auth";
import styles from './VerifyEmailPage.module.scss';
import Button from "Components/Button";
import { useNavigate } from "react-router-dom";

const VerifyEmailPage = () => {
    
    const { t } = useTranslation();
    const user = useAuthProvider();
    
    const [loading, setLoading] = useState<boolean>(false);
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if (user.firebaseUser?.emailVerified) {
            navigate('/');
        }
    }, [user, navigate])
    
    const sendVerificationEmail = async () => {
        setLoading(true);
        
        await sendEmailVerification(user.firebaseUser);
        
        setLoading(false);
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
            
                <div className={styles.steps}>
                    {t('step')} 2 {t('stepof')} 5
                </div>
            
                <hr/>
            
                <h1>{t('auth:verifyEmail.h1')}</h1>
                <h2>{t('auth:verifyEmail.h2')}</h2>
                
                <div className={styles.space}>
                    <Button onClick={sendVerificationEmail} loading={loading}>{t('auth:verifyEmail.resendVerification')}</Button>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailPage
