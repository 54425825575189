import styles from './Input.module.scss';
import React from "react";
import { Controller } from 'react-hook-form';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string;
    name: string;
    type?: 'text' | 'password' | 'number' | 'date' | 'textarea';
    errors?: any;
    rules?: any,
    control: any,
}
const Input = (props: Props) => {

    return (
        <Controller
            name={props.name}
            rules={props.rules}
            control={props.control}
            defaultValue={props.defaultValue ?? ''}
            render={(
                { field, fieldState },
            ) => (
                <>
                    {props.label && (
                        <label className={`label ${props.rules?.required && 'required'}`}>{props.label}</label>
                    )}
                    <input
                        {...field}
                        onChange={e => {
                            field.onChange(e);
                            if (props.onChange) {
                                props.onChange(e)
                            }
                        }}
                        className={`${styles.input} ${fieldState.error && styles.errors}`}
                        type={props.type ?? 'text'}
                        placeholder={props.placeholder}
                    />
                </>
            )}
        />
    )
}

export default Input
