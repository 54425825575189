import FlexRow from "Components/FlexRow";
import PageHeader from "Components/PageHeader";
import Button from "Components/Button";
import List from "Components/List";
import Modal from "Components/Modal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSaveSolarPark, useSolarParks } from "Apis/Admin/SolarParkAdminApi";
import { useForm } from "react-hook-form";
import { ISolarParkDto } from "Apis/Models/ISolarPark";
import Input from "Components/Input";
import Space from "Components/Space";
import { FileDto } from "Apis/Models/FileDto";
import styles from './SolarParksPage.module.scss';
import FileUploadButton from "Components/FileUploadButton";
import CloseIcon from "Assets/Icons/CloseIcon";

const SolarParksPage = () => {
    
    const { t } = useTranslation();
    
    const { data, isLoading } = useSolarParks();
    const { mutateAsync: saveSolarParkMutation, isLoading: isSavingPark } = useSaveSolarPark();
    
    const [solarParkModalVisible, setSolarParkModalVisible] = useState<boolean>(false);
    
    const { handleSubmit, register, control, reset } = useForm<ISolarParkDto>();
    const [parkImages, setParkImages] = useState<FileDto[]>([]);
    
    const selectPark = (park: ISolarParkDto) => {
        setSolarParkModalVisible(true);

        setParkImages(park.images || []);
        
        reset(park);
    }
    
    const savePark = async (data: ISolarParkDto) => {
        await saveSolarParkMutation({ ...data, images: parkImages });
        
        closeModal();
    }
    
    const closeModal = () => {
        setSolarParkModalVisible(false);
        reset({
            id: '',
            name: '',
        });
        setParkImages([])
    }
    
    const removeImage = (image: FileDto) => {
        setParkImages(current => current.filter(i => i.id !== image.id));
    }
    
    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('solarPark:title')} />
        
                <Button color={"link"} onClick={() => setSolarParkModalVisible(true)}>+ {t('solarPark:createSolarPark')}</Button>
            </FlexRow>
    
            <List keyDataIndex={"id"} loading={isLoading} data={data} emptyText={t('solarPark:noParksFound')}  columns={[
                {
                    dataIndex: "name",
                    title: t('solarPark:name'),
                },
                {
                    dataIndex: "images",
                    title: t('solarPark:imageCount'),
                    textRender: (_, park: ISolarParkDto) => park.images?.length.toString()
                },
                {
                    title: t('solarPark:functions'),
                    customRender: (_, park: ISolarParkDto) => (
                        <Button color={"link"} onClick={() => selectPark(park)}>{t('solarPark:select')}</Button>
                    )
                },
            ]} />
    
            <Modal closeOnOutsideClick={false} title={t('solarPark:createNewSolarPark')} visible={solarParkModalVisible} onCancel={closeModal}>
                <form onSubmit={handleSubmit(savePark)}>
                    <input type={"hidden"} {...register('id')} />
                    <Space direction={"column"} size={"large"}>
                        <div className={"w-100"}>
                            <Input placeholder={t('solarPark:name')} label={t('solarPark:name')} name={"name"} control={control} />
                        </div>
                        {parkImages.map((image, index) => (
                            <div className={styles.image}>
                                <div onClick={() => removeImage(image)} className={styles.remove}>
                                    <CloseIcon fill={"red"} />
                                </div>
                                <img alt={image.fileName} width={170} height={"auto"} src={image.url} />
                            </div>
                        ))}
                        
                        <FlexRow>
                            <FileUploadButton multiple callback={file => setParkImages(current => [...current, file])} />
                        </FlexRow>
                        
                        <FlexRow justify={"center"}>
                            <Button loading={isSavingPark} type={"submit"}>{t('save')}</Button>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>
        </>
    )
}

export default SolarParksPage
