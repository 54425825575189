import {SVGProps} from "react";

const CircledGraphIcon = (props: SVGProps<any>) => {

    return (
        <svg width={props.width ?? "60"} height={props.height ?? "60"} viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="60" height="60" rx="30" fill="#171717"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36.2662 21.512C35.7353 21.0989 34.9562 21.1789 34.524 21.7191L30.1551 27.18L26.0591 25.132L25.9159 25.0711C25.3834 24.8828 24.7806 25.0758 24.4601 25.5566L19.4601 33.0566L19.3862 33.1826C19.1014 33.7399 19.2734 34.4345 19.8068 34.7901L19.9327 34.8639C20.49 35.1487 21.1846 34.9768 21.5402 34.4434L25.9276 27.8613L29.9411 29.868L30.0874 29.93C30.5824 30.1035 31.1415 29.9493 31.4762 29.5309L35.6026 24.3713L39.6162 28.3839L39.734 28.4879C40.2244 28.8692 40.9334 28.8345 41.384 28.3839C41.8722 27.8957 41.8722 27.1043 41.384 26.6161L36.384 21.6161L36.2662 21.512ZM40.6459 39.9916C41.2676 39.9194 41.7501 39.3911 41.7501 38.75C41.7501 38.0597 41.1905 37.5 40.5001 37.5H20.5001L20.3544 37.5084C19.7327 37.5806 19.2501 38.109 19.2501 38.75C19.2501 39.4404 19.8098 40 20.5001 40H40.5001L40.6459 39.9916Z" fill="#ECEEFE"/>
        </svg>

    )

}

export default CircledGraphIcon