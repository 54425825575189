import styles from './Table.module.scss'

type Props = {
    children: any;
}
const Table = (props: Props) => {

    return (
        <table className={styles.table}>
            {props.children}
        </table>
    )

}

export default Table