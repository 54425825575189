import {SVGProps} from "react";

const LightningIcon = (props: SVGProps<SVGSVGElement>) => {

    return (
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.5" width="48" height="48" rx="24" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.6115 34.9999C23.4956 34.9998 23.3805 34.9795 23.2715 34.9399C22.8388 34.7815 22.5704 34.3479 22.6215 33.8899L23.3915 26.7999H17.5015C16.9492 26.8008 16.5008 26.3537 16.5 25.8015C16.4997 25.6013 16.5594 25.4057 16.6715 25.2399L24.5615 13.4399V13.4399C24.8183 13.06 25.3005 12.9034 25.7315 13.0599V13.0599C26.145 13.2135 26.4091 13.6197 26.3815 14.0599L25.6115 21.1999H31.5015C32.0538 21.1991 32.5022 21.6462 32.503 22.1984C32.5033 22.3985 32.4436 22.5941 32.3315 22.7599L24.4415 34.5599C24.2552 34.8355 23.9441 35.0004 23.6115 34.9999ZM19.3715 24.7999H24.5015C24.7837 24.8006 25.0525 24.9205 25.2415 25.1299C25.4324 25.3423 25.5234 25.6262 25.4915 25.9099L25.0415 30.0599L29.6315 23.1999H24.5015C23.9492 23.2033 23.4988 22.7583 23.4955 22.206C23.4952 22.1673 23.4972 22.1285 23.5015 22.0899L23.9515 17.9399L19.3715 24.7999Z" fill="#B386CF"/>
        </svg>
    )

}

export default LightningIcon