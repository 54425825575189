import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import { IEnergyDataModel } from "Apis/Models/IEnergyDataModel";

export const ENERGI_DATA_CACHE_KEY = 'ENERGI_DATA_CACHE_KEY';
export const useEnergiData = () => {
    return useQuery<IEnergyDataModel>(ENERGI_DATA_CACHE_KEY, async () => {
        return await request<IEnergyDataModel>({
            url: `/energiData`,
            method: 'GET',
        })
    }, {
        onError: (error) => {
            console.error('Error fetching energi data', error);
        }
    });
}
