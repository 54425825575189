const EmailIcon = () => {

    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.3335 32.1667H20.0002C20.9168 32.1667 21.6668 31.4167 21.6668 30.5C21.6668 29.5667 20.9168 28.8333 20.0002 28.8333H8.3335C7.40016 28.8333 6.66683 28.0833 6.66683 27.1667V13.6057L19.0828 21.883C19.3495 22.0497 19.6662 22.1497 19.9995 22.1664L19.9495 22.133C20.2662 22.1164 20.5828 22.0164 20.8662 21.8497L33.3335 13.5381V23C33.3335 23.9167 34.0668 24.6667 35.0002 24.6667C35.9168 24.6667 36.6668 23.9167 36.6668 23V10.9221C36.8068 10.5161 36.7873 10.0654 36.5978 9.66768C36.2002 7.29886 34.1349 5.5 31.6668 5.5H8.3335C5.56683 5.5 3.3335 7.73333 3.3335 10.5V27.1667C3.3335 29.9167 5.56683 32.1667 8.3335 32.1667ZM33.1405 9.71646C32.8601 9.18733 32.3037 8.83333 31.6668 8.83333H8.3335C7.67868 8.83333 7.12232 9.1943 6.84857 9.73204L19.9828 18.483L33.1405 9.71646ZM30.9436 32.1667H25.0002C24.0668 32.1667 23.3335 31.4167 23.3335 30.5C23.3335 29.5667 24.0668 28.8333 25.0002 28.8333H30.9771L28.8009 26.6667V26.665C28.1343 25.9983 28.1343 24.9483 28.8009 24.2983C29.4509 23.6317 30.5009 23.6317 31.1676 24.2983L36.1676 29.2983C36.3278 29.4544 36.4485 29.6368 36.5297 29.8328C36.6179 30.0362 36.6668 30.2616 36.6668 30.5C36.6668 31.088 36.3582 31.6075 35.8949 31.9043L31.1509 36.6483C30.8343 36.9483 30.4009 37.1317 29.9676 37.1317L30.0009 37.1667C29.5509 37.1667 29.1176 36.9833 28.8176 36.6833C28.1509 36.0333 28.1509 34.9667 28.8009 34.3167V34.3L30.9436 32.1667Z" fill="#B386CF"/>
        </svg>

    )

}

export default EmailIcon