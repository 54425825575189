import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TopMenuLink } from "Layouts/TopMenuLayout";

const AgreementMenu = () => {
    
    const { t } = useTranslation();
    
    const { agreementId } = useParams();
    
    return (
        <>
            <TopMenuLink url={`/agreements/${agreementId}/dashboard`} text={"Dashboard"} ></TopMenuLink>
            <TopMenuLink url={`/agreements/${agreementId}/communication`} text={t('communication')} ></TopMenuLink>
            <TopMenuLink url={`/agreements/${agreementId}/settings`} text={t('settings')} ></TopMenuLink>
        </>
    )
}

export default AgreementMenu
