import {useSearchParams} from "react-router-dom";
import {useAgreements} from "../../../Apis/AgreementApi";
import {AgreementModel} from "../../../Apis/Models/AgreementModel";


export const AGREEMENT_QUERY_PARAM = "agreementId";

const useSelectedAgreement = (): AgreementModel | null | undefined => {

    const [ searchParams ] = useSearchParams();

    const { data: agreements } = useAgreements();

    return searchParams.get(AGREEMENT_QUERY_PARAM) ? agreements?.find(x => x.id === searchParams.get(AGREEMENT_QUERY_PARAM)) : null;
}

export default useSelectedAgreement