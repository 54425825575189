import * as React from "react"
import { SVGProps } from "react"

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.375 18.036a1 1 0 0 1 1 1v6.005c0 .54.209 1.053.572 1.426.361.373.846.576 1.345.576h20.416c.5 0 .984-.203 1.346-.576.362-.373.571-.886.571-1.426v-6.005a1 1 0 0 1 2 0v6.005a4.048 4.048 0 0 1-1.137 2.82 3.875 3.875 0 0 1-2.78 1.182H4.292a3.875 3.875 0 0 1-2.78-1.182 4.048 4.048 0 0 1-1.137-2.82v-6.005a1 1 0 0 1 1-1Z"
            fill="#153629"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.512 10.813a1 1 0 0 1 1.414.02l6.574 6.768 6.574-6.767a1 1 0 1 1 1.435 1.393l-7.292 7.506a1 1 0 0 1-1.434 0L6.49 12.227a1 1 0 0 1 .02-1.414Z"
            fill="#153629"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5.022a1 1 0 0 1 1 1v18.014a1 1 0 1 1-2 0V1.022a1 1 0 0 1 1-1Z"
            fill="#153629"
        />
    </svg>
)

export default DownloadIcon
