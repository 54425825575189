import styles from './Back.module.scss';
import ArrowIcon from "Assets/Icons/ArrowIcon";
import { useNavigate } from "react-router-dom";

interface IProps {
    text: string;
}
const Back = (props: IProps) => {
    
    const { text } = props;
    
    const navigate = useNavigate();
    
    return (
        <div className={styles.container} onClick={_ => navigate(-1)}>
            <ArrowIcon />
            <span>
                {text}
            </span>
        </div>
    )
}

export default Back
