import * as React from "react"
import { SVGProps } from "react"

const CommentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="24" height="24" viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m21.295 21.16-1.079-4.022a.96.96 0 0 1 .076-.68c.94-1.888 1.255-4.142.676-6.493-.787-3.181-3.27-5.736-6.444-6.58a10.105 10.105 0 0 0-2.545-.336c-5.93 0-10.562 5.564-8.962 11.756.742 2.863 3.8 5.828 6.677 6.527a9.61 9.61 0 0 0 2.285.278 9.245 9.245 0 0 0 4.402-1.118.99.99 0 0 1 .468-.124c.08 0 .16.01.239.031l3.915 1.048a.237.237 0 0 0 .292-.287Zm-2.316-3.69.61 2.275-2.17-.579a2.243 2.243 0 0 0-.57-.075c-.371 0-.747.097-1.083.278a7.891 7.891 0 0 1-3.787.96 8.465 8.465 0 0 1-1.984-.244c-2.435-.592-5.118-3.208-5.736-5.603-.646-2.501-.137-5.069 1.392-7.044a7.95 7.95 0 0 1 6.328-3.107c.734 0 1.48.097 2.219.296a7.767 7.767 0 0 1 5.528 5.648c.482 1.944.283 3.884-.579 5.612a2.237 2.237 0 0 0-.168 1.582Z"
            fill="#333"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.361 14.715c-1.54-5.96 2.915-11.31 8.618-11.31a9.75 9.75 0 0 1 2.455.324c3.046.81 5.432 3.265 6.188 6.322.559 2.268.255 4.435-.649 6.247a1.316 1.316 0 0 0-.1.934l1.024 3.818-3.714-.994h-.002a1.24 1.24 0 0 0-.332-.043c-.223 0-.445.06-.638.166a8.89 8.89 0 0 1-4.232 1.075c-.714 0-1.45-.085-2.2-.268-1.362-.33-2.792-1.206-3.976-2.36-1.184-1.155-2.091-2.558-2.442-3.91ZM11.98 4.687a7.594 7.594 0 0 0-6.047 2.968c-1.46 1.886-1.948 4.34-1.329 6.738.288 1.116 1.07 2.315 2.091 3.312 1.021.998 2.246 1.758 3.385 2.035a8.11 8.11 0 0 0 1.9.233c1.275 0 2.49-.308 3.618-.917.386-.208.82-.32 1.252-.32a2.6 2.6 0 0 1 .66.086h.002l1.575.42-.45-1.68a2.593 2.593 0 0 1 .193-1.834c.822-1.649 1.014-3.502.552-5.367a7.411 7.411 0 0 0-5.275-5.39h-.001a8.176 8.176 0 0 0-2.126-.284Z"
            fill="#333"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.979 2.694c-6.158 0-10.966 5.778-9.307 12.2.392 1.51 1.385 3.022 2.635 4.24 1.25 1.22 2.787 2.175 4.303 2.543a9.965 9.965 0 0 0 2.369.289 9.601 9.601 0 0 0 4.57-1.16l.002-.002a.633.633 0 0 1 .298-.08c.053 0 .1.006.143.018l3.93 1.051.011.003h.003a.593.593 0 0 0 .703-.725l-1.079-4.025v-.002a.605.605 0 0 1 .05-.428c.978-1.961 1.302-4.302.703-6.736-.817-3.306-3.397-5.96-6.697-6.839h-.002a10.461 10.461 0 0 0-2.635-.347Zm0 .711c-5.703 0-10.158 5.35-8.618 11.31.35 1.353 1.258 2.756 2.442 3.91 1.184 1.155 2.614 2.03 3.975 2.361.75.183 1.487.268 2.2.268a8.89 8.89 0 0 0 4.233-1.075c.193-.105.415-.166.638-.166.106 0 .217.011.332.043h.002l3.714.994-1.024-3.818v-.001a1.316 1.316 0 0 1 .1-.933c.904-1.812 1.208-3.98.65-6.247-.757-3.057-3.143-5.511-6.19-6.322a9.75 9.75 0 0 0-2.454-.324Z"
            fill="#333"
        />
    </svg>
)

export default CommentIcon
