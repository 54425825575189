import {Route, Routes} from "react-router-dom";
import AgreementRedirectPage from "./AgreementRedirectPage";
import AgreementDashboardPage from "./Dashboard/AgreementDashboardPage";
import AgreementOnboardingPage from "./Dashboard/AgreementOnboardingPage";
import AgreementReviewPage from "./Dashboard/AgreementReviewPage";
import InvoicesPage from "./Dashboard/InvoicesPage";
import NavigationItem from "../../Layouts/V2/NavigationItem";
import DashboardIcon from "../../Assets/Icons/V2/DashboardIcon";
import AgreementIcon from "../../Assets/Icons/V2/AgreementIcon";
import DefaultPageLayout from "Layouts/V2/DefaultPageLayout";
import AnnualReportsPage from "./Dashboard/AnnualReportsPage";
import {useTranslation} from "react-i18next";
import AgreementOnboardingEloverblikPage from "./Dashboard/AgreementOnboardingEloverblikPage";
import AgreementDetailsPage from "./Dashboard/AgreementDetailsPage";
import MenuMoreDropdown from "../../Layouts/V2/MenuMoreDropdown";
import ContactInfoPage from "./Dashboard/ContactInfoPage";
import ChangePasswordPage from "./Dashboard/ChangePasswordPage";
import AcceptedTermsPage from "./Dashboard/AcceptedTermsPage";
import InvoiceIcon from "../../Assets/Icons/V2/InvoiceIcon";
import ReportIcon from "../../Assets/Icons/V2/ReportIcon";

const DashboardRoutes = () => {

    const { t } = useTranslation();

    const dashboardPage = (content: any) => (
        <DefaultPageLayout contentMaxWidth={1440} menuContent={(
            <>
                <NavigationItem text={t('dashboard')}  icon={<DashboardIcon />} isExact includeSearch route={`/v2/dashboard`} />
                <NavigationItem text={t('acceptedTerms')} icon={<ReportIcon />} includeSearch route={`/v2/dashboard/acceptedTerms`} />
                <NavigationItem text={t('invoices')} icon={<InvoiceIcon />} includeSearch route={`/v2/dashboard/invoices`} />
                <NavigationItem text={t('agreement')} icon={<AgreementIcon />} includeSearch route={`/v2/dashboard/details`} />
                {/**
                    <NavigationItem text={t('annualReports')} icon={<ReportIcon />} route={`/v2/dashboard/annualReports`} />
                 */}
                <MenuMoreDropdown />
            </>
        )}>
            {content}
        </DefaultPageLayout>
    )

    return (
        <Routes>
            <Route path=":agreementId/onboarding/eloverblik" element={<AgreementOnboardingEloverblikPage />} />
            <Route path=":agreementId/onboarding" element={<AgreementOnboardingPage />} />

            <Route path=":agreementId/review" element={<AgreementReviewPage />} />

            <Route path="invoices" element={dashboardPage(<InvoicesPage />)} />
            <Route path="acceptedTerms" element={dashboardPage(<AcceptedTermsPage />)} />
            <Route path="annualReports" element={dashboardPage(<AnnualReportsPage />)} />

            <Route path="details" element={dashboardPage(<AgreementDetailsPage />)} />

            <Route path="changePassword" element={dashboardPage(<ChangePasswordPage />)} />
            <Route path="contactInfo" element={dashboardPage(<ContactInfoPage />)} />

            <Route path="/" element={dashboardPage(<AgreementDashboardPage />)} />
            
            <Route path="*" element={<AgreementRedirectPage />} />
        </Routes>
    )
}

export default DashboardRoutes