import Gap from "../../Components/V2/Gap";
import styles from "./MenuMoreDropdown.module.scss";
import BurgerIcon from "../../Assets/Icons/V2/BurgerIcon";
import UserPlaceholderIcon from "../../Assets/Icons/V2/UserPlaceholderIcon";
import DropdownItem from "../../Components/V2/DropdownItem";
import SignOutIcon from "../../Assets/Icons/V2/SignOutIcon";
import {StyledText} from "../../Components/V2/Text";
import Dropdown from "../../Components/V2/Dropdown";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import {signOut} from "../../FirebaseSetup/auth";
import {useNavigate} from "react-router-dom";
import ContactInfoIcon from "../../Assets/Icons/V2/ContactInfoIcon";
import ResetPasswordIcon from "../../Assets/Icons/V2/ResetPasswordIcon";
import {useLocation} from "react-router";

const MenuMoreDropdown = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { search } = useLocation();

    const queryClient = useQueryClient();

    const signOutOnClick = () => {
        signOut();
        queryClient.clear();
    }

    const contactInfoPage = () => {
        navigate('/v2/dashboard/contactInfo' + search);
    }

    const changePassword = () => {
        navigate('/v2/dashboard/changePassword');
    }

    return (
        <Dropdown toggle={(
            <div className={"clickable"}>
                <Gap size={"small"} className={styles.moreToggle}>
                    <BurgerIcon/>
                    <UserPlaceholderIcon/>
                </Gap>
            </div>
        )}>
            <DropdownItem onClick={contactInfoPage}>
                <Gap size={"small"}>
                    <ContactInfoIcon />
                    <StyledText color={"secondary"} type={"Caption"}>{t('editContactInfo')}</StyledText>
                </Gap>
            </DropdownItem>
            <DropdownItem onClick={changePassword}>
                <Gap size={"small"}>
                    <ResetPasswordIcon />
                    <StyledText color={"secondary"} type={"Caption"}>{t('changePassword')}</StyledText>
                </Gap>
            </DropdownItem>
            <DropdownItem onClick={signOutOnClick}>
                <Gap size={"small"}>
                    <SignOutIcon />
                    <StyledText color={"secondary"} type={"Caption"}>{t('signOut')}</StyledText>
                </Gap>
            </DropdownItem>
        </Dropdown>
    )

}

export default MenuMoreDropdown