import FlexRow from "../../../Components/FlexRow";
import PageHeader from "../../../Components/PageHeader";
import {useTranslation} from "react-i18next";
import {
    useInvoices, useLinkInvoiceToEconomic, useRemoveLinkInvoiceToEconomic,
} from "../../../Apis/Admin/InvoicesApi";
import List from "Components/List";
import {InvoiceModel} from "../../../Apis/Models/InvoiceModel";
import Button from "../../../Components/Button";
import {formatNumber} from "../../../Utils/NumberUtils";
import moment from "moment";
import Config from "Config";
import React, {useState} from "react";
import Modal from "../../../Components/Modal";
import Space from "../../../Components/Space";
import Input from "../../../Components/Input";
import {useForm} from "react-hook-form";
import useToast from "../../../Hooks/useToast";


const InvoicesPage = () => {

    const { t } = useTranslation();

    const { data: invoices, isLoading: isLoadingInvoices } = useInvoices();

    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('invoices')} />
            </FlexRow>

            <List loading={isLoadingInvoices} data={invoices} keyDataIndex={"id"} emptyText={t('noInvoicesFound')} columns={[
                {
                    dataIndex: "economicInfo",
                    title: t('invoiceStatus'),
                    customRender: (_, record: InvoiceModel) => (
                        <FlexRow direction={"column"} align={'center'} justify={"center"}>
                            {!!record.economicInfo.bookedAt && (
                                <>
                                    {t('booked')} - {record.economicInfo.bookedInvoiceNumber}
                                </>
                            )}
                            {!record.economicInfo.bookedAt && !!record.economicInfo.createdAt && (
                                <>
                                    {t('draft')}
                                </>
                            )}
                        </FlexRow>
                    )
                },
                {
                    dataIndex: "customer",
                    title: t('customer'),
                    textRender: (_, record: InvoiceModel) => record.agreement.nickname,
                    allowOrderBy: true,
                    orderByValueFn: (record: InvoiceModel) => record.agreement.nickname
                },
                {
                    dataIndex: "vatNumber",
                    title: t('vatNumber'),
                    textRender: (_, record: InvoiceModel) => record.agreement.vatNumber,
                    allowOrderBy: true,
                    orderByValueFn: (record: InvoiceModel) => record.agreement.vatNumber
                },
                {
                    dataIndex: "periodCoveredFrom",
                    title: t('period'),
                    customRender: (_, record: InvoiceModel) => (
                        <FlexRow>
                            {moment(record.periodCoveredFrom).format('DD-MM-YYYY') + ' - ' + moment(record.periodCoveredTo).format('DD-MM-YYYY')}
                        </FlexRow>
                    ),
                    allowOrderBy: true,
                    orderByValueFn: (record: InvoiceModel) => moment(record.periodCoveredFrom).toDate(),
                },
                {
                    dataIndex: "agreementType",
                    title: t('agreementType'),
                    textRender: (_, record: InvoiceModel) => t('agreement:' + record.agreement.agreementType)
                },
                {
                    dataIndex: "unitSalesPrice",
                    title: t('terms:unitSalesPricePrMwh'),
                    textRender: (_, record: InvoiceModel) => record.agreement.currentTerms && `${formatNumber(record.agreement.currentTerms.agreementTerms.unitSalesPrice)} DKK`
                },
                {
                    dataIndex: "usageInPeriod",
                    title: t('usageInPeriod'),
                    textRender: (_, record: InvoiceModel) => `${formatNumber(record.invoiceLines.map(x => x.quantity).reduce((a, b) => a + b, 0))}MWh (${formatNumber(record.invoiceLines.filter(x => x.invoiceLineType === 'FixedUsage').map(x => x.quantity).reduce((a, b) => a + b, 0))})MWh`,
                    allowOrderBy: true,
                    orderByValueFn: (record: InvoiceModel) => record.invoiceLines.map(x => x.quantity).reduce((a, b) => a + b, 0)
                },
                {
                    dataIndex: "priceForPeriod",
                    title: t('priceForPeriod'),
                    textRender: (_, record: InvoiceModel) => `${formatNumber(record.invoiceTotal)} DKK`
                },
                {
                    dataIndex: "dataPoints",
                    title: t('dataPoints'),
                    customRender: (_, record: InvoiceModel) => (
                        <FlexRow direction={"column"} align={'center'} justify={"center"}>
                            {record.expectedDataPoints !== 0 && (
                                <>
                                    {record.expectedDataPoints !== record.foundDataPoints && <div style={{color: '#9C0F2E', fontWeight: 'bolder'}}>{record.foundDataPoints - record.expectedDataPoints}</div>}
                                    <div style={{color: record.expectedDataPoints !== record.foundDataPoints ? '#9C0F2E' : '#11763D'}}>{record.expectedDataPoints} / {record.foundDataPoints}</div>
                                </>
                            )}
                        </FlexRow>
                    )
                },
                {
                    dataIndex: "latestDataPoint",
                    title: t('latestDataPoint'),
                    customRender: (value, record: InvoiceModel) => <FlexRow align={'center'} justify={"center"}><span style={{color: record.periodCoveredTo !== moment(value).format('YYYY-MM-DD') ? '#9C0F2E' : '#11763D'}}>{!!value ? moment(value).format('DD-MM-YYYY') : ''}</span></FlexRow>
                },
                {
                    dataIndex: "invoiceLines",
                    title: t('dataPointsCustomUsage'),
                    customRender: (_, record: InvoiceModel) => (
                        <FlexRow direction={"column"} align={'center'} justify={"center"}>
                            {record.expectedCustomUsageDataPoints !== 0 && (
                                <>
                                    {record.expectedCustomUsageDataPoints !== record.foundCustomUsageDataPoints && <div style={{color: '#9C0F2E', fontWeight: 'bolder'}}>{record.foundCustomUsageDataPoints - record.expectedCustomUsageDataPoints}</div>}
                                    <div style={{color: record.expectedCustomUsageDataPoints !== record.foundCustomUsageDataPoints ? '#9C0F2E' : '#11763D'}}>{record.expectedCustomUsageDataPoints} / {record.foundCustomUsageDataPoints}</div>
                                </>
                            )}
                        </FlexRow>
                    )
                },
                {
                    dataIndex: "latestCustomUsageDataPoint",
                    title: t('latestCustomUsageDataPoint'),
                    customRender: (value, record: InvoiceModel) => <FlexRow align={'center'} justify={"center"}><span style={{color: record.periodCoveredTo !== moment(value).format('YYYY-MM-DD') ? '#9C0F2E' : '#11763D'}}>{value}</span></FlexRow>
                },
                {
                    dataIndex: 'functions',
                    title: t('functions'),
                    customRender: (value, record: InvoiceModel) => {
                        return (
                            <div style={{display: 'grid', gridTemplateRows: 'min-content min-content', gridTemplateColumns: 'min-content',gap: 5}}>
                                <RemoveLinkToEconomicInvoiceButton {...record} />
                                <LinkToEconomicInvoiceButton {...record} />
                                <SeeMeterPointsButton {...record} />
                            </div>
                        )
                    }
                },
            ]} />
        </>
    )
}

const RemoveLinkToEconomicInvoiceButton = (invoice: InvoiceModel) => {
    const { t } = useTranslation();

    const { showSuccessToast } = useToast();

    const [open, setOpen] = useState<boolean>(false);

    const { mutateAsync, isLoading } = useRemoveLinkInvoiceToEconomic(invoice.agreementId, invoice.id);

    const onClick = () => {
        setOpen(true)
    }

    const onSubmit = async () => {
        await mutateAsync();

        showSuccessToast(t('removeInvoiceLinkedToEconomic'));

        setOpen(false);
    }

    if (!invoice.economicInfo.bookedAt) {
        return <></>
    }

    return (
        <>
            <div style={{backgroundColor: '#df1e2e', borderRadius: 100, display: 'flex', minHeight: 30, justifyContent: 'center', padding: 5}}>
                <Button color={"link"} onClick={onClick}>{t('removeLinkToInvoice')}</Button>
            </div>
            <Modal title={t('removeLinkToInvoice')} visible={open} onCancel={() => setOpen(false)}>
                    <Space size={"large"} direction={"column"}>
                        <div className={"w-100"}>
                            {t('areYouSureYouWantToRemoveLinkToInvoice')}
                        </div>

                        <FlexRow justify={"center"}>
                            <Button onClick={onSubmit} loading={isLoading} disabled={isLoading}>{t('removeLinkToInvoice')}</Button>
                        </FlexRow>
                    </Space>
            </Modal>
        </>
    )
}

const LinkToEconomicInvoiceButton = (invoice: InvoiceModel) => {
    const { t } = useTranslation();

    const { showSuccessToast } = useToast();

    const [open, setOpen] = useState<boolean>(false);

    const { mutateAsync, isLoading } = useLinkInvoiceToEconomic(invoice.agreementId, invoice.id);

    const { handleSubmit, control } = useForm();

    const onClick = () => {
        setOpen(true)
    }

    const onSubmit = async (data) => {
        await mutateAsync(data);

        showSuccessToast(t('invoiceLinkedToEconomic'));

        setOpen(false);
    }

    if (invoice.economicInfo.bookedAt) {
        return <></>
    }

    return (
        <>
            <div style={{backgroundColor: '#4463f8', borderRadius: 100, display: 'flex', minHeight: 30, justifyContent: 'center', padding: 5}}>
                <Button color={"link"} onClick={onClick}>{t('linkToBookedEconomicInvoice')}</Button>
            </div>
            <Modal title={t('linkToBookedEconomicInvoice')} visible={open} onCancel={() => setOpen(false)}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Space size={"large"} direction={"column"}>
                            <div className={"w-100"}>
                                <Input name={"bookedInvoiceNumber"} control={control} label={t('bookedInvoiceNumber')} placeholder={t('bookedInvoiceNumber')}/>
                            </div>

                            <FlexRow justify={"center"}>
                                <Button loading={isLoading} disabled={isLoading} type={"submit"}>{t('linkToBookedEconomicInvoice')}</Button>
                            </FlexRow>
                        </Space>
                    </form>
                </Modal>
        </>
    )
}

const SeeMeterPointsButton = (invoice: InvoiceModel) => {
    const { t } = useTranslation();

    const onClick = () => {
        const url = Config.meterPointsUrl.replace('{AgreementId}', invoice.agreementId).replace("{FromDate}", moment(invoice.periodCoveredFrom).format('YYYY-MM-DD')).replace("{ToDate}", moment(invoice.periodCoveredTo).format('YYYY-MM-DD')).replace("{ToDate}", moment(invoice.periodCoveredTo).format('YYYY-MM-DD'));

        window.open(url, '_blank')
    }

    return (
        <div style={{backgroundColor: '#A1AFA9', borderRadius: 100, display: 'flex', minHeight: 30, justifyContent: 'center', padding: 5}}>
            <Button color={"link"} onClick={onClick}>{t('seeMeteringPoints')}</Button>
        </div>
    )
}

export default InvoicesPage