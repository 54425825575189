const ThumbsUp = () => {

    return (
        <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.3332 4C19.4634 4 21.2047 5.66523 21.3264 7.76497L21.3332 8V13.3333H23.9998C26.1301 13.3333 27.8714 14.9986 27.993 17.0983L27.9998 17.3333L27.9739 17.5948L26.6313 24.3043C26.1232 26.4717 24.6292 28.0321 22.8857 28.0109L22.6665 28H13.3332C12.1728 28 11.099 27.6294 10.2236 27.0002L10.1332 26.932C9.68044 27.5335 8.98732 27.9345 8.19885 27.9927L7.99984 28H5.33317C3.92735 28 2.77562 26.9122 2.67382 25.5323L2.6665 25.3333V16C2.6665 14.5942 3.75434 13.4424 5.13415 13.3406L5.33317 13.3333H9.33317C11.4634 13.3333 13.2047 11.6681 13.3264 9.56836L13.3332 9.33333V8C13.3332 5.79086 15.124 4 17.3332 4ZM17.3332 6.66667C16.6494 6.66667 16.0858 7.18139 16.0088 7.84451L15.9998 8V9.33333C15.9998 12.5308 13.7489 15.2025 10.7451 15.8502L10.6665 15.864V22.6667C10.6665 24.0725 11.7543 25.2242 13.1342 25.326L13.3332 25.3333L22.7795 25.3381C23.171 25.3714 23.6951 24.8777 23.9675 23.9582L24.0257 23.7385L25.3265 17.228L25.3242 17.1778C25.2531 16.5657 24.7674 16.0801 24.1553 16.009L23.9998 16H19.9998C19.3161 16 18.7525 15.4853 18.6755 14.8222L18.6665 14.6667V8C18.6665 7.26362 18.0695 6.66667 17.3332 6.66667ZM7.99984 25.3333V16H5.33317V25.3333H7.99984Z" fill="#ECEEFE"/>
        </svg>

    )

}

export default ThumbsUp