import * as React from "react"
import { SVGProps } from "react"

const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="31"
        height="31"
        viewBox={"0 0 31 31"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.208 15.5a1 1 0 0 1 1-1h28.584a1 1 0 1 1 0 2H1.208a1 1 0 0 1-1-1Z"
            fill="#153629"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.207.501a1 1 0 0 1 0 1.414L2.623 15.5l13.584 13.584a1 1 0 0 1-1.414 1.415L.5 16.207a1 1 0 0 1 0-1.414L14.793.5a1 1 0 0 1 1.414 0Z"
            fill="#153629"
        />
    </svg>
)

export default ArrowIcon
