import styles from "../../../Pages/Admin/Terms/TermsPage.module.scss";
import FlexRow from "../../../Components/FlexRow";
import Button from "../../../Components/Button";
import Modal from "../../../Components/Modal";
import { useTranslation } from "react-i18next";
import {AgreementTermsModel} from "../../../Apis/Models/AgreementTermsModel";
import React, {useState} from "react";

type Props = {
    terms: AgreementTermsModel;
    button: React.ReactElement;
}
const ShowTermsModal = (props: Props) => {

    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false)

    return (
        <>
            {React.cloneElement(props.button, {onClick: () => setVisible(true)})}
            <Modal title={t('terms:showTerms')} visible={visible}
                   onCancel={() => setVisible(false)}>
                <div className={styles.termsField}>
                    {props.terms?.text}
                </div>

                <FlexRow justify={"center"}>
                    <Button onClick={() => setVisible(false)} >{t('close')}</Button>
                </FlexRow>
            </Modal>
        </>
    )

}

export default ShowTermsModal