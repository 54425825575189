import { Bar, BarChart, XAxis, YAxis } from "recharts";
import { AgreementModel } from "Apis/Models/AgreementModel";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import FlexRow from "Components/FlexRow";
import { useAgreementUsageData } from "Apis/UsageApi";
import Button from "Components/Button";

interface IProps {
    agreement: AgreementModel;
    width?: number;
}
const AgreementUsageChart = (props: IProps) => {
    
    const { t } = useTranslation();
    const { agreement, width } = props;
    
    const [aggregation, setAggregation] = useState< 'Day' | 'Week' | 'Month' | 'Year'>('Year');
    
    const [selectedItem, setSelectedItem] = useState<{key: string, data: string, usage: number}>();
    
    const {data: chartData} = useAgreementUsageData(agreement.id, aggregation)
    
    const getUsageLabel = () => {
        const usage = Math.floor(selectedItem?.usage ?? 0).toLocaleString("da-dk")

        switch (aggregation) {
            case "Day":
                return <Trans i18nKey="agreement:agreementUsageDay" values={{day: moment(selectedItem?.data).format('D. MMM'), usage: usage, company: agreement.nickname}} />
            case "Week":
                return <Trans i18nKey="agreement:agreementUsageWeek" values={{week: selectedItem?.data, usage: usage, company: agreement.nickname}} />
            case "Month":
                return <Trans i18nKey="agreement:agreementUsageMonth" values={{month: moment(selectedItem?.data).format("MMMM"), usage: usage, company: agreement.nickname}} />
            case "Year":
                return <Trans i18nKey="agreement:agreementUsageYear" values={{year: moment(selectedItem?.data).format("YYYY"), usage: usage, company: agreement.nickname}} />
        }
    }
    
    const aggregationButton = ( aggregation: 'Day' | 'Week' | 'Month' | 'Year' ) => (
        <Button onClick={() => setAggregation(aggregation)}>{t(aggregation)}</Button>
    )
    
    const getData = useMemo(() => {
        let data: {key: string, data: string, usage: number}[] = [];
        
        if (aggregation === "Week") {
    
            chartData?.forEach((item) => {
                if (data.filter(x => x.key === moment(item.key).format("W")).length === 0) {
                    data.push({key: moment(item.key).format("W"), data: moment(item.key).format("W"), usage: 0})
                }
            });
            
            chartData?.forEach((item) => {
                data.find(x => x.key === moment(item.key).format("W"))!.usage += item.usage;
            });
    
            setSelectedItem(data[data.length - 1]);
        } else {
            chartData?.forEach((item) => {
                if (data.filter(x => x.key === moment(item.key).format("W")).length === 0) {
                    const key = aggregation === "Day" ? moment(item.key).format("DD") : aggregation === "Month" ? moment(item.key).format("MMM") : moment(item.key).format("YYYY");
                    data.push({key: key, data: item.key, usage: item.usage})
                }
            });
        }

        if (data.length < 12) {
            const pointsToAdd = 12 - data.length;
            const toAppend = pointsToAdd / 2;

            for (let i = 0; i < toAppend; i++) {
                data.unshift({key: "", data: "", usage: 0})
            }
        } else if (data.length > 12) {
            data.splice(0, data.length - 12);
        }

        while (data.length < 12) {
            data.push({key: "", data: "", usage: 0})
        }

        return data;

    }, [chartData, aggregation])

    const getChartMax = () => {
        const max = Math.max(...getData.map(x => x.usage));

        return Math.floor(max * 1.2)
    }
    
    if (chartData?.length === 0) {
        return <FlexRow className={"h-100"} direction={"column"} justify={"space-between"} align={"center"}>{t("agreement:noUsageData")}</FlexRow>
    }
    
    return (
        <FlexRow className={"h-100"} direction={"column"} justify={"space-between"} align={"start"}>
            <div style={{fontSize: 40}}>
                {getUsageLabel()}
            </div>
            <div>
                {chartData?.length === 0 ? (
                    <FlexRow className={"h-100"} direction={"column"} justify={"space-between"} align={"center"}>{t("agreement:noUsageData")}</FlexRow>
                ) : (
                    <>
                        <BarChart maxBarSize={getChartMax()} width={width ?? 400} height={400} data={getData}>
                            <YAxis domain={[0, getChartMax()]} padding={{bottom: 5}} orientation={"right"} axisLine={false} tickLine={false} />
                            <XAxis axisLine={false} tickLine={false} dataKey="key" />
                            <Bar onMouseEnter={setSelectedItem} dataKey="usage" fill="#DEC3EF" style={{stroke: "#153629", strokeWidth: 1}}  />
                        </BarChart>
                    </>
                )}
                <FlexRow justify={"space-around"} align={"start"} style={{height: 50}}>
                    {aggregationButton("Day")}
                    {aggregationButton("Week")}
                    {aggregationButton("Month")}
                    {aggregationButton("Year")}
                </FlexRow>
            </div>
        </FlexRow>
    )
}

export default AgreementUsageChart
