import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import DashboardPage from "Pages/Agreements/Agreement/DashboardPage";
import LogoPage from "Pages/Agreements/Agreement/LogoPage";
import ParkImagesPage from "Pages/Agreements/Agreement/ParkImagesPage";
import CommunicationPage from "Pages/Agreements/Agreement/CommunicationPage";
import { useAgreement } from "Apis/AgreementApi";
import PageLoader from "Components/PageLoader";
import { useTopMenuLayoutProvider } from "Layouts/TopMenuLayout";
import { useEffect } from "react";
import SocialMediaPage from "Pages/Agreements/Agreement/SocialMediaPage";
import SettingsPage from "Pages/Agreements/Agreement/SettingsPage";
import InvoicesPage from "./InvoicesPage";
import AnnualReportsPage from "./AnnualReportsPage";

const AgreementRoutes = () => {
    
    const { agreementId } = useParams<{ agreementId: string }>();
    
    const { data: agreement, error } = useAgreement(agreementId);
    
    const { setTitle } = useTopMenuLayoutProvider();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (agreement) {
            setTitle(agreement.nickname)
        }
    }, [setTitle, agreement])
    
    useEffect(() => {
        if (error?.status === 404) {
            navigate('/agreements');
        }
    }, [error, navigate])
    
    if (!agreementId) {
        return (
            <Routes>
                <Route path={"*"} element={<Navigate to={"/"}/>}/>
            </Routes>
        )
    }
    
    if (!agreement) {
        return <PageLoader shown={true}><></></PageLoader>
    }
    
    return (
        <Routes>
            <Route path={`dashboard`} element={
                <DashboardPage agreement={agreement} />
            }/>
    
            <Route path={`settings`} element={
                <SettingsPage agreement={agreement} />
            }/>
    
            <Route path={`invoices`} element={
                <InvoicesPage agreement={agreement} />
            }/>

            <Route path={`communication`} element={
                <CommunicationPage agreement={agreement} />
            }/>
    
            <Route path={`communication/logo`} element={
                <LogoPage agreement={agreement} />
            }/>
    
            <Route path={`communication/parkImages`} element={
                <ParkImagesPage agreement={agreement} />
            }/>

            <Route path={`communication/annualReports`} element={
                <AnnualReportsPage />
            }/>
    
            <Route path={`communication/socialMedia`} element={
                <SocialMediaPage agreement={agreement} />
            }/>
            
            <Route path={"*"} element={<Navigate to={"/"}/>}/>
        </Routes>
    )
}

export default AgreementRoutes
