import { useTranslation } from "react-i18next";
import { useState } from "react";
import styles from "Pages/Agreements/Agreement/LogoPage.module.scss";
import Button from "Components/Button";
import { saveAs } from 'file-saver';
import Back from "Components/Back";
import { AgreementModel } from "Apis/Models/AgreementModel";
import { useSolarParks } from "Apis/SolarParkApi";
import { useSelectEntries } from "Hooks/UseSelectEntries";
import { UncontrolledCheckbox } from "Components/Checkbox/Checkbox";

interface IProps {
    agreement: AgreementModel;
}
const ParkImagesPage = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { data: solarParks } = useSolarParks();
    
    const [isAllImagesSelected, selectedImages, isImageSelected, toggleImageSelected, toggleAllImagesSelected] = useSelectEntries(solarParks?.flatMap(x => x.images).map(x => x.id) ?? [])
    
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    
    const downloadImages = async () => {
        setIsDownloading(true);
        
        for (let i = 0; i < selectedImages.length; i++) {
            
            try {
                const image = solarParks?.flatMap(park => park.images).find(x => x.id === selectedImages[i]);
            
                const response = await fetch(image!.url);
                
                const blob = await response.blob();
                
                saveAs(blob, image!.fileName);
            }
            catch (e) {
                console.error(e);
            }
        }
        
        setIsDownloading(false);
    }
    
    return (
        <>
            <Back text={t('agreement:parkImages.back')} />
            
            <div className={styles.contentGrid}>
                <div className={styles.imageGrid}>
                    {solarParks?.map(park => park.images.map((image, index) => (
                        <div key={index} className={`${styles.imageContainer} ${isImageSelected(image.id) && styles.selected}`} onClick={_ => toggleImageSelected(image.id)}>
                            <div className={styles.image}>
                                <img src={image.url} alt={""} height={"auto"} width={198} />
                            </div>
                        </div>
                    )))}
                </div>
                <div className={styles.downloadContainer}>
                    <UncontrolledCheckbox name={"allSelected"} value={isAllImagesSelected} onChange={() => toggleAllImagesSelected()} label={t('selectAll')} />
                    
                    <Button loading={isDownloading} onClick={downloadImages} color={"link"}>{t('downloadSelected')}</Button>
                </div>
            </div>
        </>
    )
}

export default ParkImagesPage
