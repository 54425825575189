import { Navigate, Route, Routes } from "react-router-dom";
import TermsPage from "Pages/Admin/Terms/TermsPage";

const TermsRoute = () => {
    
    return (
        <Routes>
            <Route path={`/`} element={
                <TermsPage />
            }/>
            
            <Route path={"*"} element={<Navigate to={"/"}/>}/>
        </Routes>
    )
}

export default TermsRoute
