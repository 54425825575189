import * as Radix from '@radix-ui/react-tooltip';
import QuestionmarkCircleIcon from 'Assets/Icons/V2/QuestionmarkCircleIcon';
import styles from './Tooltip.module.scss'

interface Props {
    text: string;
}
const Tooltip = (props: Props) => {

    return (
        <Radix.Provider>
            <Radix.Root>
                <Radix.Trigger asChild>
                    <div>
                        <QuestionmarkCircleIcon />
                    </div>
                </Radix.Trigger>
                <Radix.Portal>
                    <Radix.Content className={styles.tooltip}>
                        {props.text}
                        <Radix.Arrow width={20} height={10} />
                    </Radix.Content>
                </Radix.Portal>
            </Radix.Root>
        </Radix.Provider>
    )

}

export default Tooltip