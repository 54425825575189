import {SVGProps} from "react";

const CircledLeaf = (props: SVGProps<SVGSVGElement>) => {

    return (
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="0.666992" width="48" height="48" rx="24" fill="#445E54"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M32.6667 15H28.6527C20.0765 15 15.8028 18.5929 15.6565 24.7087L15.6533 25.2238C15.668 26.9707 16.0665 28.4662 17.2536 29.9401C16.9817 30.8599 16.789 31.8432 16.6728 32.8896C16.6118 33.4385 17.0073 33.9329 17.5562 33.9939C18.1051 34.0549 18.5996 33.6593 18.6605 33.1104C18.7432 32.3664 18.8675 31.6633 19.0355 31H21.6667C28.9254 31 33.1657 26.7581 33.665 18.0573L33.6667 16C33.6667 15.4477 33.2189 15 32.6667 15ZM19.7302 29H21.6667C27.738 29 31.1164 25.7198 31.6427 18.3708L31.6667 18V17H28.6527C20.9879 17 17.667 19.8808 17.6527 24.997L17.6533 25.2124C17.6609 26.1186 17.7938 26.9051 18.1575 27.6601C19.4118 25.2114 21.4187 23.3492 24.2605 22.0862C24.7652 21.8619 25.3562 22.0892 25.5805 22.5939C25.8048 23.0985 25.5775 23.6895 25.0728 23.9138C22.5069 25.0542 20.7661 26.7289 19.7302 29Z" fill="#FFFFC9"/>
        </svg>

    )

}

export default CircledLeaf