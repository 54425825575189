import { AgreementModel } from "Apis/Models/AgreementModel";
import styles from "Pages/Agreements/Agreement/DashboardPage.module.scss";
import ResourceLink from "Components/ResourceLink";
import Back from "Components/Back";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ArrowIcon from "Assets/Icons/ArrowIcon";
import DownloadIcon from "../../../Assets/Icons/DownloadIcon";

interface IProps {
    agreement: AgreementModel;
}
const CommunicationPage = (props: IProps) => {
    
    const { t } = useTranslation();
    const { agreement } = props;
    const { id: agreementId } = agreement;
    
    const [resources] = useState([
        {
            url: `/agreements/${agreementId}/communication/socialMedia/`,
            icon: <ArrowIcon style={{transform: 'rotate(180deg)'}} />,
            title: t('resources:socialMediaPostsTitle'),
            text: t('resources:socialMediaPostsText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/post.png"} />
        },
        {
            url: `/agreements/${agreementId}/communication/annualReports`,
            icon: <DownloadIcon />,
            title: t('resources:yearlyReportTitle'),
            text: t('resources:yearlyReportText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/yearlyReport.png"} />
        },
        {
            url: `/agreements/${agreementId}/communication/parkImages/`,
            icon: <DownloadIcon />,
            title: t('resources:parkImagesTitle'),
            text: t('resources:parkImagesText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/ParkImage.png"} />
        },
        {
            url: `/agreements/${agreementId}/communication/logo/`,
            icon: <DownloadIcon />,
            title: t('resources:logoTitle'),
            text: t('resources:logoText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/icon.png"} />
        },
        /**{
            url: `/agreements/${agreementId}/communication/logo/`,
            icon: <DownloadIcon />,
            title: t('resources:infoGraphicTitle'),
            text: t('resources:infoGraphicText'),
            content: <img alt={""} height={"auto"} width={'100%'} src={"/Images/Resources/infoGraphic.png"} />,
            openInNewTab: true,
        },
         */
    ]);
    
    return (
        <>
            <Back text={t('agreement:communicationPage.back')} />
            
            <div className={styles.resourcesGrid}>
                {resources.map((resource, index) => (
                    <ResourceLink key={index} {...resource} />
                ))}
            </div>
        </>
    )
}

export default CommunicationPage
