import {useMutation} from "react-query";
import {request} from "./BaseApi";
import {ContactFormModel} from "./Models/ContactFormModel";

export const useInternationalContact = () => {
    return useMutation(async (contactFormData: ContactFormModel) => {
        return await request({
            url: `/contactForm/international`,
            method: 'POST',
            data: contactFormData
        })
    });
}
