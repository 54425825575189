const PieChartIconV2 = () => {

    return (
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9724 3.67809C17.8132 3.57941 18.6586 3.82154 19.3197 4.35042C19.9073 4.82054 20.3059 5.48243 20.4492 6.21508L20.4909 6.49291L20.5 6.66673V18.3334C20.5 19.1881 21.1434 19.8926 21.9723 19.9889L22.1666 20.0001H33.5C35.3409 20.0001 36.8333 21.4924 36.8333 23.3334C36.8333 23.4601 36.8189 23.5864 36.7902 23.7098C34.9314 31.7283 27.4972 37.1889 19.2898 36.5645C11.0824 35.9401 4.55992 29.4176 3.93551 21.2102C3.32287 13.1577 8.56786 5.84947 16.3391 3.82093L16.7902 3.70979L16.9724 3.67809ZM17.1666 18.3334L17.165 7.05006L17.1276 7.06028C10.9406 8.70298 6.77056 14.5342 7.25923 20.9574C7.75877 27.5233 12.9767 32.7413 19.5427 33.2408C25.8231 33.7186 31.5376 29.7425 33.3241 23.7819L33.4483 23.3317L22.1666 23.3334C19.5038 23.3334 17.3272 21.2519 17.1751 18.6272L17.1666 18.3334ZM25.1246 4.20934C25.2438 4.18166 25.3667 4.16697 25.491 4.1663L25.6943 4.17794L25.8821 4.21075L26.0535 4.26134C30.6519 5.88048 34.3022 9.42806 36.0579 13.9575L36.2387 14.4465C36.598 15.4669 35.9066 16.5333 34.8657 16.6552L34.6666 16.6667H27.1666C25.4094 16.6667 23.9697 15.3069 23.8424 13.5822L23.8333 13.3334L23.8329 5.84232L23.8433 5.64506C23.8519 5.57527 23.8629 5.5126 23.8773 5.45125L23.9279 5.27986C23.9934 5.09381 24.0886 4.92669 24.2062 4.78194L24.325 4.6484C24.3751 4.60161 24.4213 4.56107 24.4694 4.52327L24.6195 4.41822L24.767 4.33613L24.9379 4.2639L25.1246 4.20934ZM27.5375 8.64573L27.1666 8.42507V13.3334H32.0733L31.8543 12.9626C30.8675 11.369 29.556 10.0023 28.0087 8.95142L27.5375 8.64573Z" fill="#B386CF"/>
        </svg>

    )

}

export default PieChartIconV2