import {useMutation} from "react-query";
import axios from "axios";
import config from "../Config";

export const useCalendylyItem = () => {
    return useMutation(async (url: string) => {
        return await axios({
            method: 'get',
            url: url,
            headers: {
                'Authorization': `Bearer ${config.calendly.accessToken}`
            }
        })
    })
}
