import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import {DashboardStat} from "./Models/DashboardStat";

export const AGREEMENT_USAGE_DATA_CACHE_KEY = 'AGREEMENT_USAGE_DATA_CACHE_KEY';
export const useAgreementUsageData = (agreementId: string, aggregation: string) => {
    return useQuery<{key: string, usage: number}[]>([AGREEMENT_USAGE_DATA_CACHE_KEY, agreementId, aggregation], async () => {
        return await request<{key: string, usage: number}[]>({
            url: `/usage/agreement/${agreementId}?aggregation=${aggregation}`,
            method: 'GET',
        })
    });
}

export const SOLARPARK_USAGE_DATA_CACHE_KEY = 'SOLARPARK_USAGE_DATA_CACHE_KEY';
export const useSolarParkUsageData = (solarParkId: string, agreementId: string) => {
    return useQuery<{key: string, usage: number}[]>([SOLARPARK_USAGE_DATA_CACHE_KEY, solarParkId, agreementId], async () => {
        return await request<{key: string, usage: number}[]>({
            url: `/usage/solarpark/${solarParkId}/agreement/${agreementId}`,
            method: 'GET',
        })
    });
}

export const DASHBOARD_USAGE_DATA_CACHE_KEY = 'DASHBOARD_USAGE_DATA_CACHE_KEY';
export const useDashboardStats = (agreementId: string | undefined) => {
    return useQuery<DashboardStat[]>([DASHBOARD_USAGE_DATA_CACHE_KEY, agreementId], async () => {
        return await request<DashboardStat[]>({
            url: `/usage/dashboard?agreementId=${agreementId ?? ''}`,
            method: 'GET',
        })
    });
}

