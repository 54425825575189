import { useMutation } from "react-query";
import { request } from "Apis/BaseApi";
import { FileDto, UploadFileDto } from "Apis/Models/FileDto";

export const useBase64File = () => {
    
    return async (file: File, includeData: boolean = false): Promise<string> => {
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                // @ts-ignore
                let payload = reader.result.split(',')[1];
        
                if (includeData) {
                    payload = reader.result;
                }
        
                resolve(payload);
            }
            reader.onerror = error => reject(error);
        });
    }
}

export const useUploadFile = () => {
    return useMutation(async (file: UploadFileDto): Promise<FileDto> => {
        return await request<FileDto>({
            url: `/files`,
            method: 'POST',
            data: file
        })
    })
}
