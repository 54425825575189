import Space from "Components/Space";
import Input from "Components/Input";
import Checkbox from "Components/Checkbox";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from './SignupPage.module.scss';
import { usePasswordChecks } from "Hooks/UsePasswordChecks";
import { Link } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";
import CheckmarkIcon from "Assets/Icons/CheckmarkIcon";

const SignupPage = () => {
    
    const { t } = useTranslation();
    
    const [error, setError] = useState<string>('');
    
    const { signUp, isCreatingUser } = useAuthProvider();
    
    const { control, handleSubmit, formState: { errors } } = useForm<{email: string, password: string, passwordCheck: string, newsletterAllowed: boolean, acceptTerms: boolean}>();
    
    const [passwordChecks, checkPassword] = usePasswordChecks();
    
    const onSubmit = async (data) => {
        setError("");
    
        if (passwordChecks.filter(x => !x.fulfilled).length > 0) {
            setError(t('auth:signup.passwordRequirementsNotMet'));
            return;
        }
        
        if (data.password !== data.passwordCheck) {
            setError(t('auth:signup.passwordMissMatch'));
            return;
        }
    
        if (!data.acceptTerms) {
            setError(t('auth:signup.acceptTermsMessage'));
            return;
        }
        
        try {
            await signUp(data);
        } catch (e: any) {
            switch (e.data) {
                case "USER/EMAIL_ALREADY_EXISTS":
                    setError(t('auth:emailAlreadyInUse'));
                    break;
                case "USER/INVALID_EMAIL":
                    setError(t('auth:invalidEmail'));
                    break;
                case "USER/UNEXPECTED_ERROR":
                    setError(t('somethingWentWrong'));
                    break;
                case "USER/WEEK_PASSWORD":
                    setError(t('auth:weekPassword'));
                    break;
            }
        }
    }
    
   return (
       <div className={styles.container}>
           <div className={styles.innerContainer}>
               
               <div className={styles.steps}>
                   {t('step')} 1 {t('stepof')} 5
               </div>
               
               <hr/>
               
               <h1>{t('auth:signup.h1')}</h1>
               <h2 style={error ? {color: 'red'} : {}}>{error ? error : t('auth:signup.h2')}</h2>
               <div className={"flex column w-100"}>
                   <form onSubmit={handleSubmit(onSubmit)}>
                       <Space>
                           <div className={"w-100"}>
                               <Input label={t('email')} placeholder={t('auth:emailPlaceholder')} control={control} name={"email"} errors={errors.email} rules={{required: true}} />
                           </div>
                        
                           <div className={"w-100"}>
                               <Input label={t('password')} type={"password"} placeholder={t('auth:passwordPlaceholder')} control={control} errors={errors.password} name={"password"} rules={{required: true}} onChange={newValue => checkPassword(newValue.target.value)} />
                           </div>
    
                           <div className={"w-100"}>
                               <Input label={t('retypePassword')} type={"password"} placeholder={t('auth:retypePasswordPlaceholder')} control={control} errors={errors.passwordCheck} name={"passwordCheck"} rules={{required: true}} />
                           </div>
    
                           <div className={styles.checkList}>
                                {passwordChecks.map((x, index) => (
                                    <div key={index}>
                                        <CheckmarkIcon fill={!x.fulfilled ? 'none' : '#153629'} />
                                        {x.message}
                                    </div>
                                ))}
                           </div>
                        
                           <div className={"w-100"}>
                               <Checkbox label={t('auth:signup.acceptNewsletter')} control={control} name={"newsletterAllowed"}/>
                           </div>
                        
                           <div className={"w-100"}>
                               <Checkbox label={t('auth:signup.acceptTermsAndConditions')} control={control} name={"acceptTerms"} />
                           </div>
                        
                           <FlexRow justify={"center"} style={{paddingTop: 20}}>
                               <Button disabled={isCreatingUser} type={"submit"} loading={isCreatingUser}>{t('auth:signup.signup')}</Button>
                           </FlexRow>
    
                           <FlexRow direction={"column"} justify={"center"} style={{paddingTop: 40}}>
                               <div className={"linkExplain"}>
                                   {t('auth:signup.alreadyHaveAccount')}
                               </div>
                               <Link to={"/auth/login"}>{t('auth:signup.gotoLogin')}</Link>
                           </FlexRow>
                       </Space>
                   </form>
               </div>
           </div>
       </div>
   )
}

export default SignupPage
