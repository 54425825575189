import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { ILogoDto } from "Apis/Models/LogoDto";

export const ADMIN_LOGO_CACHE_KEY = 'ADMIN_LOGO_CACHE_KEY';
export const useLogos = () => {
    return useQuery<ILogoDto[]>(ADMIN_LOGO_CACHE_KEY, async () => {
        return await request<ILogoDto[]>({
            url: `/admin/logo`,
            method: 'GET',
        })
    });
}

export const useSaveLogo = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (post: ILogoDto): Promise<ILogoDto> => {
        if (post.id) {
            return await request<ILogoDto>({
                url: `/admin/logo/${post.id}`,
                method: 'PUT',
                data: post
            })
        } else {
            return await request<ILogoDto>({
                url: `/admin/logo`,
                method: 'POST',
                data: post
            })
        }
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_LOGO_CACHE_KEY);
        }
    })
}

export const useDeleteLogo = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (post: ILogoDto) => {
        return await request({
            url: `/admin/logo/${post.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_LOGO_CACHE_KEY);
        }
    })
}
