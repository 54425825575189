import {SVGProps} from "react";

const WarningTriangle = (props: SVGProps<any>) => {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0407 10.8667L9.92739 2.38666V2.38666C9.2472 1.3226 7.8332 1.0114 6.76913 1.69158C6.48979 1.87015 6.25261 2.10732 6.07405 2.38666L0.960718 10.8667H0.960718C0.593082 11.4795 0.580375 12.2419 0.927385 12.8667C1.3287 13.5701 2.07754 14.0031 2.88738 14H13.1141C13.9184 14.0086 14.6659 13.5865 15.0741 12.8933V12.8933C15.4314 12.2618 15.4187 11.4861 15.0407 10.8667ZM8.00072 11.3333C7.63253 11.3333 7.33405 11.0349 7.33405 10.6667C7.33405 10.2985 7.63253 10 8.00072 10C8.36891 10 8.66738 10.2985 8.66738 10.6667C8.66738 11.0349 8.36891 11.3333 8.00072 11.3333ZM8.66738 8.66666C8.66738 9.03485 8.36891 9.33333 8.00072 9.33333C7.63253 9.33333 7.33405 9.03485 7.33405 8.66666V6C7.33405 5.63181 7.63253 5.33333 8.00072 5.33333C8.36891 5.33333 8.66738 5.63181 8.66738 6V8.66666Z" fill={props.fill ?? "#E95C7B"}/>
        </svg>

    )

}

export default WarningTriangle