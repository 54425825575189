import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import {InvoiceModel} from "../Models/InvoiceModel";

export const ADMIN_INVOICES_CACHE_KEY = 'ADMIN_INVOICES_CACHE_KEY';
export const useInvoices = () => {
    return useQuery<InvoiceModel[]>(ADMIN_INVOICES_CACHE_KEY, async () => {
        return await request<InvoiceModel[]>({
            url: `/admin/invoices`,
            method: 'GET',
        })
    });
}

export const ADMIN_UNINVOICED_USAGE_CACHE_KEY = 'ADMIN_INVOICES_CACHE_KEY';
export const useInvoiceableUsage = (until: string | undefined) => {
    return useQuery<InvoiceModel[]>([ADMIN_UNINVOICED_USAGE_CACHE_KEY, until], async () => {
        return await request<InvoiceModel[]>({
            url: `/admin/invoices/invoiceable?&until=${until}`,
            method: 'GET',
        })
    }, {
        enabled: !!until
    });
}

export const useGenerateInvoices = (year: number, quarter: number, agreementId: string = '') => {
    const queryClient = useQueryClient();

    let url = `/admin/invoices/generate?year=${year}&quarter=${quarter}`;

    if (!!agreementId) {
        url = `${url}&agreementId=${agreementId}`;
    }

    return useMutation(async () => {
        return await request({
            url,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_INVOICES_CACHE_KEY);
        }
    })
}

export const useCreateInvoiceDraft = (agreementId: string, invoiceId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async () => {
        return await request({
            url: `/admin/invoices/${agreementId}/${invoiceId}/draft`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_INVOICES_CACHE_KEY);
        }
    })
}

export const useCreateInvoiceDraftForInvoiceable = (agreementId: string, until: string) => {
    const queryClient = useQueryClient();

    return useMutation(async () => {
        return await request({
            url: `/admin/invoices/invoiceable/generate?agreementId=${agreementId}&until=${until}`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_INVOICES_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_UNINVOICED_USAGE_CACHE_KEY);
        }
    })
}

export const useMarkInvoicedForInvoiceable = (agreementId: string, until: string, invoiceId?: string) => {
    const queryClient = useQueryClient();

    return useMutation(async () => {
        return await request({
            url: `/admin/invoices/invoiceable/generate?agreementId=${agreementId}&until=${until}&invoiceId=${invoiceId}`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_INVOICES_CACHE_KEY);
            queryClient.invalidateQueries(ADMIN_UNINVOICED_USAGE_CACHE_KEY);
        }
    })
}

export const useMarkInvoiceAsInvoiced = (agreementId: string, invoiceId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async () => {
        return await request({
            url: `/admin/invoices/${agreementId}/${invoiceId}/markInvoiced`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_INVOICES_CACHE_KEY);
        }
    })
}

export const useLinkInvoiceToEconomic = (agreementId: string, invoiceId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async (model: {bookedInvoiceNumber: number}) => {
        return await request({
            url: `/admin/invoices/${agreementId}/${invoiceId}/link`,
            method: 'POST',
            data: model
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_INVOICES_CACHE_KEY);
        }
    })
}

export const useRemoveLinkInvoiceToEconomic = (agreementId: string, invoiceId: string) => {
    const queryClient = useQueryClient();

    return useMutation(async () => {
        return await request({
            url: `/admin/invoices/${agreementId}/${invoiceId}/unlink`,
            method: 'POST',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ADMIN_INVOICES_CACHE_KEY);
        }
    })
}