import { Navigate, useLocation } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";

const RequireAuthentication = ({ children }) => {
    
    let location = useLocation();
    
    const user = useAuthProvider();
    
    if (!user?.firebaseUser && !location.pathname.includes("/auth/login")) {
        return <Navigate to={"/auth/login"}/>
    }
    
    if (!user.firebaseUser?.emailVerified && !location.pathname.includes("/auth/verifyEmail")) {
        return (
            <Navigate to={"/auth/verifyEmail"} />
        )
    }
    
    return children;
}

export default RequireAuthentication;
