import * as React from "react"
import { SVGProps } from "react"
const ContactInfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 24}
    height={props.height ?? 24}
    fill="none"
    viewBox={"0 0 24 25"}
    {...props}
  >
    <path
      fill="#B386CF"
      fillRule="evenodd"
      d="M6 3.5h12a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-10a4 4 0 0 1 4-4Zm12 2H6a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-10a2 2 0 0 0-2-2Zm-12 5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm8-2a1 1 0 0 0-1-1h-2l-.117.007A1 1 0 0 0 15 9.5h2l.117-.007A1 1 0 0 0 18 8.5Zm-1 3a1 1 0 0 1 .117 1.993L17 13.5h-2a1 1 0 0 1-.117-1.993L15 11.5h2Zm1 5a1 1 0 0 0-1-1H7l-.117.007A1 1 0 0 0 7 17.5h10l.117-.007A1 1 0 0 0 18 16.5Z"
      clipRule="evenodd"
    />
  </svg>
)
export default ContactInfoIcon
