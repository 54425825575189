import * as React from "react"
import { SVGProps } from "react"
const SignOut = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 24 24"} fill="none" height={props.height ?? 24} width={props.width ?? 24} {...props}>
    <path
      fill="#B386CF"
      fillRule="evenodd"
      d="M8 5.5a1 1 0 0 1-1 1H6v12h1a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1v-14a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1Zm10 2.42 2.82 4a1 1 0 0 1-.02 1.18l-3 4a1 1 0 0 1-1.6-1.2l1.8-2.4h-8a1 1 0 1 1 0-2h8.09l-1.72-2.42-.005-.007A1 1 0 0 1 18 7.92Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SignOut
