import FlexRow from "../../../Components/FlexRow";
import PageHeader from "../../../Components/PageHeader";
import {Trans, useTranslation} from "react-i18next";
import {
    ADMIN_INVOICES_CACHE_KEY,
    useCreateInvoiceDraftForInvoiceable,
    useGenerateInvoices, useInvoiceableUsage, useMarkInvoicedForInvoiceable
} from "../../../Apis/Admin/InvoicesApi";
import List from "Components/List";
import {InvoiceModel} from "../../../Apis/Models/InvoiceModel";
import Button from "../../../Components/Button";
import {formatNumber} from "../../../Utils/NumberUtils";
import moment from "moment";
import {useLoadUsageFromEloverblik} from "../../../Apis/Admin/AgreementAdminApi";
import Config from "Config";
import {useQueryClient} from "react-query";
import Space from "../../../Components/Space";
import Modal from "../../../Components/Modal";
import React, {useState} from "react";
import inputStyles from "../../../Components/Input/Input.module.scss";
import useQueryParam from "../../../Hooks/UseQueryParam";
import _ from "lodash";
import {AgreementModel} from "../../../Apis/Models/AgreementModel";


const InvoiceablePage = () => {

    const { t } = useTranslation();
    const [ until, setUntil ] = useQueryParam("until");

    const { data: invoices, isLoading: isLoadingInvoices } = useInvoiceableUsage(until);

    const debouncedInvoiceSearch = _.debounce(async (date: string) => {
        setUntil(date);
    }, 1000);

    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('invoiceableUsage')} />

                <div>
                    <input type={"date"} className={inputStyles.input} onChange={e => debouncedInvoiceSearch(e.target.value)} defaultValue={until} name={"until"} />
                </div>
            </FlexRow>

            <List loading={isLoadingInvoices} data={invoices} keyDataIndex={"id"} emptyText={t('noInvoicesFound')} columns={[
                {
                    dataIndex: "agreement",
                    title: t('customer'),
                    textRender: (_, record: InvoiceModel) => record.agreement.nickname
                },
                {
                    dataIndex: "agreement",
                    title: t('vatNumber'),
                    textRender: (_, record: InvoiceModel) => record.agreement.vatNumber
                },
                {
                    dataIndex: "periodCoveredFrom",
                    title: t('period'),
                    customRender: (_, record: InvoiceModel) => (
                        <FlexRow>
                            {moment(record.periodCoveredFrom).format('DD-MM-YYYY') + ' - ' + moment(record.periodCoveredTo).format('DD-MM-YYYY')}
                        </FlexRow>
                    )
                },
                {
                    dataIndex: "agreement",
                    title: t('agreementType'),
                    textRender: (_, record: InvoiceModel) => t('agreement:' + record.agreement.agreementType)
                },
                {
                    dataIndex: "agreement",
                    title: t('terms:unitSalesPricePrMwh'),
                    textRender: (_, record: InvoiceModel) => record.agreement.currentTerms && `${formatNumber(record.agreement.currentTerms.agreementTerms.unitSalesPrice)} DKK`
                },
                {
                    dataIndex: "invoiceLines",
                    title: t('usageInPeriod'),
                    textRender: (_, record: InvoiceModel) => `${formatNumber(record.invoiceLines.map(x => x.quantity).reduce((a, b) => a + b, 0))}MWh (${formatNumber(record.invoiceLines.filter(x => x.invoiceLineType === 'FixedUsage').map(x => x.quantity).reduce((a, b) => a + b, 0))})MWh`
                },
                {
                    dataIndex: "invoiceLines",
                    title: t('priceForPeriod'),
                    textRender: (_, record: InvoiceModel) => `${formatNumber(record.invoiceTotal)} DKK`
                },
                {
                    dataIndex: "invoiceLines",
                    title: t('dataPoints'),
                    customRender: (_, record: InvoiceModel) => (
                        <FlexRow direction={"column"} align={'center'} justify={"center"}>
                            {record.expectedDataPoints !== 0 && (
                                <>
                                    {record.expectedDataPoints !== record.foundDataPoints && <div style={{color: '#9C0F2E', fontWeight: 'bolder'}}>{record.foundDataPoints - record.expectedDataPoints}</div>}
                                    <div style={{color: record.expectedDataPoints !== record.foundDataPoints ? '#9C0F2E' : '#11763D'}}>{record.expectedDataPoints} / {record.foundDataPoints}</div>
                                </>
                            )}
                        </FlexRow>
                    )
                },
                {
                    dataIndex: "latestDataPoint",
                    title: t('latestDataPoint'),
                    customRender: (value, record: InvoiceModel) => <FlexRow align={'center'} justify={"center"}><span style={{color: record.periodCoveredTo !== moment(value).format('YYYY-MM-DD') ? '#9C0F2E' : '#11763D'}}>{!!value ? moment(value).format('DD-MM-YYYY') : ''}</span></FlexRow>
                },
                {
                    dataIndex: "invoiceLines",
                    title: t('dataPointsCustomUsage'),
                    customRender: (_, record: InvoiceModel) => (
                        <FlexRow direction={"column"} align={'center'} justify={"center"}>
                            {record.expectedCustomUsageDataPoints !== 0 && (
                                <>
                                    {record.expectedCustomUsageDataPoints !== record.foundCustomUsageDataPoints && <div style={{color: '#9C0F2E', fontWeight: 'bolder'}}>{record.foundCustomUsageDataPoints - record.expectedCustomUsageDataPoints}</div>}
                                    <div style={{color: record.expectedCustomUsageDataPoints !== record.foundCustomUsageDataPoints ? '#9C0F2E' : '#11763D'}}>{record.expectedCustomUsageDataPoints} / {record.foundCustomUsageDataPoints}</div>
                                </>
                            )}
                        </FlexRow>
                    )
                },
                {
                    dataIndex: "latestCustomUsageDataPoint",
                    title: t('latestCustomUsageDataPoint'),
                    customRender: (value, record: InvoiceModel) => <FlexRow align={'center'} justify={"center"}><span style={{color: record.periodCoveredTo !== moment(value).format('YYYY-MM-DD') ? '#9C0F2E' : '#11763D'}}>{value}</span></FlexRow>
                },
                {
                    dataIndex: 'customerName',
                    title: t('functions'),
                    customRender: (value, record: InvoiceModel) => {
                        return (
                            <div style={{display: 'grid', gridTemplateRows: 'min-content min-content', gridTemplateColumns: 'min-content min-content',gap: 5}}>
                                <SeeMeterPointsButton {...record} />
                                {record.economicInfo.createdAt === null && (
                                    <>
                                        <CreateDraftButton agreement={record.agreement} until={until!} />
                                    </>
                                )}
                                {(record.economicInfo.bookedAt === null || (record.economicInfo.bookedAt != null && !record.economicInfo.draftInvoiceNumber)) && (
                                    <MarkInvoicedButton invoice={record} agreement={record.agreement} until={until!} />
                                )}
                                <LoadUsageButton agreementId={record.agreementId} />
                            </div>
                        )
                    }
                },
            ]} />
        </>
    )
}

const LoadUsageButton = ({agreementId}: any) => {
    const { t } = useTranslation();
    const { mutateAsync: loadUsageMutation, isLoading } = useLoadUsageFromEloverblik(agreementId);
    const queryClient = useQueryClient();
    const { mutateAsync: generateInvoices } = useGenerateInvoices(2024, 1, agreementId);

    const onClick = async () => {
        await loadUsageMutation();
        await generateInvoices();
        await queryClient.invalidateQueries(ADMIN_INVOICES_CACHE_KEY);
    }

    return (
        <div style={{backgroundColor: '#5DC389', borderRadius: 100, display: 'flex', minHeight: 30, justifyContent: 'center', padding: 5}}>
            <Button disabled={isLoading} loading={isLoading} color={"link"} onClick={onClick}>{t('reloadData')}</Button>
        </div>
    )
}

const CreateDraftButton = ({until, agreement}: { until: string, agreement: AgreementModel}) => {
    const { t } = useTranslation();
    const { mutateAsync: createDraftInvoice, isLoading } = useCreateInvoiceDraftForInvoiceable(agreement.id, until);

    return (
        <div style={{backgroundColor: '#F9D0D9', borderRadius: 100, display: 'flex', minHeight: 30, justifyContent: 'center', padding: 5}}>
            <Button disabled={isLoading} loading={isLoading} color={"link"} onClick={createDraftInvoice}>{t('createDraftInEconomic')}</Button>
        </div>
    )
}

const MarkInvoicedButton = ({until, agreement, invoice}: { until: string, agreement: AgreementModel, invoice: InvoiceModel}) => {
    const { t } = useTranslation();

    const isInvoiced = !!invoice?.economicInfo.createdAt;

    const { mutateAsync: markAsInvoiced, isLoading } = useMarkInvoicedForInvoiceable(agreement.id, until, isInvoiced ? invoice.id : undefined);

    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const mutate = async () => {
        await markAsInvoiced();
        setModalVisible(false);
    }

    return (
        <>
            <div style={{backgroundColor: '#FFF1D7', borderRadius: 100, display: 'flex', minHeight: 30, justifyContent: 'center', padding: 5}}>
                <Button disabled={isLoading} loading={isLoading} color={"link"} onClick={() => setModalVisible(true)}>{isInvoiced ? t('markNotInvoiced') : t('markAsInvoiced')}</Button>
            </div>

            <Modal title={isInvoiced ? t('markNotInvoiced') : t('markAsInvoiced')} visible={modalVisible} onCancel={() => setModalVisible(false)}>
                <Space>
                    <div className={"w-100"}>
                        {isInvoiced ? (
                            <Trans i18nKey="markNotInvoicedConfirmation" values={{companyName: agreement.nickname}} />
                        ) : (
                            <Trans i18nKey="markAsInvoicedConfirmation" values={{companyName: agreement.nickname}} />
                        )}
                    </div>

                    <Space direction={"column"}>
                        <div>
                            {t('start')}: {moment(invoice.periodCoveredFrom).format('DD-MM-YYYY')}
                        </div>
                        <div>
                            {t('end')}: {moment(invoice.periodCoveredTo).format('DD-MM-YYYY')}
                        </div>
                    </Space>

                    <FlexRow justify={"end"}>
                        <Button color={"danger"} disabled={isLoading} onClick={mutate} loading={isLoading}>{isInvoiced ? t('markNotInvoiced') : t('markAsInvoiced')}</Button>
                    </FlexRow>
                </Space>
            </Modal>
        </>
    )
}

const SeeMeterPointsButton = (invoice: InvoiceModel) => {
    const { t } = useTranslation();

    const onClick = () => {
        const url = Config.meterPointsUrl.replace('{AgreementId}', invoice.agreementId).replace("{FromDate}", moment(invoice.periodCoveredFrom).format('YYYY-MM-DD')).replace("{ToDate}", moment(invoice.periodCoveredTo).format('YYYY-MM-DD')).replace("{ToDate}", moment(invoice.periodCoveredTo).format('YYYY-MM-DD'));

        window.open(url, '_blank')
    }

    return (
        <div style={{backgroundColor: '#A1AFA9', borderRadius: 100, display: 'flex', minHeight: 30, justifyContent: 'center', padding: 5}}>
            <Button color={"link"} onClick={onClick}>{t('seeMeteringPoints')}</Button>
        </div>
    )
}

export default InvoiceablePage