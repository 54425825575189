import * as React from "react"
import { SVGProps } from "react"

const HeartIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="24" height="24" viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.93 4.18c-.965 0-1.917.273-2.76.79-2.61 1.599-3.53 5.17-2.013 7.958.283.522.791 1.15 1.444 1.829a32.619 32.619 0 0 0 2.23 2.079c1.612 1.384 3.327 2.675 4.34 3.417a1.41 1.41 0 0 0 1.66 0c1.014-.742 2.729-2.034 4.34-3.417a32.611 32.611 0 0 0 2.231-2.08c.652-.679 1.16-1.306 1.444-1.828 1.512-2.787.593-6.36-2.017-7.959a5.285 5.285 0 0 0-2.76-.788 5.336 5.336 0 0 0-3.832 1.629l-.238.242-.237-.242A5.336 5.336 0 0 0 7.93 4.18Zm-3.109.236a5.952 5.952 0 0 1 3.11-.887c1.52 0 2.954.574 4.068 1.598a5.986 5.986 0 0 1 4.07-1.598c1.092 0 2.164.308 3.108.887 2.923 1.79 3.918 5.746 2.251 8.82-.326.6-.882 1.276-1.547 1.969a33.25 33.25 0 0 1-2.276 2.122c-1.63 1.4-3.36 2.703-4.38 3.45h-.002a2.075 2.075 0 0 1-2.445 0c-1.02-.747-2.75-2.05-4.38-3.45a33.23 33.23 0 0 1-2.277-2.122c-.664-.693-1.22-1.37-1.546-1.969m2.246-8.82C1.9 6.206.903 10.163 2.575 13.236Zm.67 1.052a4.67 4.67 0 0 1 2.44-.695c1.501 0 2.891.717 3.826 1.946h.001l.241.32.241-.32h.001c.936-1.23 2.33-1.946 3.828-1.946.856 0 1.696.242 2.434.696 2.372 1.452 3.166 4.675 1.803 7.179-.305.561-.862 1.216-1.526 1.889a35.2 35.2 0 0 1-2.273 2.073 79.085 79.085 0 0 1-4.04 3.167l-.002.001c-.277.2-.659.2-.935 0h-.001a77.442 77.442 0 0 1-4.042-3.165 35.058 35.058 0 0 1-2.273-2.074c-.664-.673-1.22-1.329-1.526-1.891M5.84 6.022c-2.056 1.263-2.78 4.096-1.57 6.318.26.478.762 1.08 1.417 1.744.65.659 1.432 1.36 2.23 2.034a76.844 76.844 0 0 0 4.004 3.136.135.135 0 0 0 .152 0c.763-.56 2.41-1.791 4.005-3.14a34.53 34.53 0 0 0 2.229-2.032c.655-.664 1.158-1.265 1.418-1.742 1.209-2.222.485-5.06-1.57-6.318h-.001a3.978 3.978 0 0 0-2.085-.598c-1.277 0-2.48.61-3.3 1.685L12 8.133l-.77-1.022c-.82-1.076-2.018-1.686-3.299-1.686-.729 0-1.449.206-2.09.598Zm-.348-.554Zm0 0c-2.372 1.457-3.167 4.676-1.804 7.18Z"
            fill="#333"
        />
        <path
            d="M12 20.823c-.37 0-.724-.115-1.023-.334-2.028-1.484-6.874-5.173-8.09-7.409-1.59-2.921-.635-6.673 2.125-8.363a5.609 5.609 0 0 1 2.928-.835c1.535 0 2.978.62 4.06 1.723a5.658 5.658 0 0 1 4.06-1.723c1.026 0 2.035.29 2.927.835 2.76 1.69 3.715 5.442 2.129 8.363-1.216 2.236-6.062 5.925-8.09 7.409a1.74 1.74 0 0 1-1.027.334ZM7.94 5.122c-.791 0-1.57.223-2.26.645-2.208 1.355-2.965 4.371-1.682 6.726 1.127 2.075 6.2 5.884 7.728 7 .16.116.383.116.543 0 1.527-1.12 6.6-4.93 7.728-7 1.283-2.355.526-5.375-1.683-6.726a4.304 4.304 0 0 0-2.255-.645c-1.384 0-2.68.661-3.555 1.81l-.505.67-.504-.67c-.875-1.149-2.167-1.81-3.555-1.81Z"
            fill="#333"
        />
    </svg>
)

export default HeartIcon
