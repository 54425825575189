import styles from './Step.module.scss';
import { useTranslation } from "react-i18next";

interface Props {
    currentStep: number;
    numberOfSteps: number
}
const Step = (props: Props) => {
    
    const { t } = useTranslation();
    
    return (
        <div className={styles.steps}>
            {t('step')} {props.currentStep} {t('stepof')} {props.numberOfSteps}
        </div>
    )
}

export default Step
