import { AgreementModel } from "Apis/Models/AgreementModel";
import Back from "Components/Back";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "Components/Input/Input";
import styles from './SettingsPage.module.scss';
import React, { useEffect } from "react";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import { useAddAgreementLogo, useAgreementLogo, useUpdateAgreement } from "Apis/AgreementApi";
import FileUploadButton from "Components/FileUploadButton";
import { FileDto } from "Apis/Models/FileDto";
import {updatePassword} from "../../../FirebaseSetup/auth";
import useToast from "../../../Hooks/useToast";

interface FormData {
    companyName: string;
    vatNumber: string;
    invoiceEmail: string;
    name: string;
    phoneNumber: string;
    newPassword: string;
    newPasswordRepeat: string;
}

interface IProps {
    agreement: AgreementModel;
}
const SettingsPage = (props: IProps) => {
    
    const { t } = useTranslation();
    const { showErrorToast } = useToast();

    const { data: logoFile } = useAgreementLogo(props.agreement.id);
    const { mutateAsync: saveAgreement, isLoading: isSavingAgreement } = useUpdateAgreement();
    const { mutateAsync: saveLogoMutation } = useAddAgreementLogo(props.agreement);
    
    const { reset, control, handleSubmit, formState: { errors } } = useForm<FormData>();
    
    useEffect(() => {
        reset(props.agreement);
    }, [reset, props.agreement])
    
    const onSubmit = async (data) => {
        if (data.newPassword !== '' || data.newPasswordRepeat !== '') {

            const errorMessage = t('agreement:settings.newPasswordDoesntMatch');

            if (data.newPassword !== data.newPasswordRepeat) {
                showErrorToast(errorMessage)

                return;
            }

            try {
                await updatePassword(data.newPassword);
            }
            catch (e: any) {
                let message = t("unexpectedErrorOccurred");

                if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
                    message = t('auth:invalidCredentials');
                }
                if (e.code === 'auth/invalid-email') {
                    message = t('auth:invalidEmail');
                }
                if (e.code === 'auth/network-request-failed') {
                    message = t('somethingWentWrong');
                }

                showErrorToast(message);
            }
        }

        await saveAgreement({ id: props.agreement.id ,...data });
    }
    
    const saveLogo = async (file: FileDto) => {
        await saveLogoMutation({ fileId: file.id });
    }
    
    return (
        <>
            <Back text={t('agreement:settings.back')} />
            
            <div className={`${styles.settingsGrid} ${styles.bottomSeparator}`}>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                        <div className={styles.innerSettingsGrid}>
                            <div>
                                <Input name={"companyName"} control={control} label={t('companyName')} errors={errors.companyName} rules={{required: true}} />
                            </div>
                            <div>
                                <Input name={"vatNumber"} control={control} label={t('vatNumber')} errors={errors.vatNumber} rules={{required: true}} />
                            </div>
                        </div>

                        <div className={styles.innerSettingsGrid}>
                            <div>
                                <Input name={"invoiceEmail"} control={control} label={t('invoiceEmail')} errors={errors.invoiceEmail} rules={{required: true}} />
                            </div>
                        </div>

                        <div className={styles.contactPerson}>
                            {t('agreement:settings.contactPerson')}
                        </div>

                        <div className={styles.innerSettingsGrid}>
                            <div>
                                <Input name={"name"} control={control} label={t('name')} errors={errors.name} rules={{required: true}} />
                            </div>
                            <div>
                                <Input name={"phoneNumber"} control={control} label={t('phoneNumber')} errors={errors.phoneNumber} rules={{required: true}} />
                            </div>
                        </div>

                        <div className={styles.innerSettingsGrid}>
                            <div>
                                <Input type={"password"} name={"newPassword"} control={control} label={t('newPassword')} errors={errors.newPassword} />
                            </div>
                            <div>
                                <Input type={"password"} name={"newPasswordRepeat"} control={control} label={t('newPasswordRepeat')} errors={errors.newPasswordRepeat} />
                            </div>
                        </div>
                        
                        <FlexRow justify={"start"}>
                            <Button type={"submit"} loading={isSavingAgreement}>{t('save')}</Button>
                        </FlexRow>
                    </form>
                </div>
                
                <div>
                    <div className={styles.uploadLogoContainer}>
                        <label>&nbsp;</label>
                        <div className={styles.uploadButton} style={{backgroundSize: 'contain', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage: logoFile ? `url(${logoFile?.url})` : ''}}>
                            <FileUploadButton accept={[".png"]} callback={file => saveLogo(file)} />
                        </div>
                        <FlexRow justify={"center"}>
                            {logoFile ? t('agreement:settings.changeLogo') : t('agreement:settings.uploadLogo')}
                        </FlexRow>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsPage
