const GlobeIcon = () => {

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M36.6667 20C36.6667 10.7952 29.2047 3.33333 20 3.33333C10.7952 3.33333 3.33333 10.7952 3.33333 20C3.33333 29.2048 10.7952 36.6667 20 36.6667C29.2047 36.6667 36.6667 29.2048 36.6667 20ZM33.2198 18.3333H28.3365V18.3333C28.0616 14.4135 26.7186 10.6439 24.4531 7.43327C29.1996 9.11903 32.591 13.3357 33.2198 18.3333V18.3333ZM15.1302 21.6667H24.9969L24.9969 21.6667C24.5703 25.7843 22.8153 29.6513 19.9969 32.6833C17.1803 29.6639 15.4655 25.7822 15.1302 21.6667L15.1302 21.6667ZM15.1302 18.3332V18.3332C15.5183 14.2362 17.2262 10.376 19.9969 7.33317V7.33317C22.8381 10.3434 24.5971 14.2132 24.9969 18.3332L15.1302 18.3332ZM15.7333 7.38333C13.4559 10.6117 12.0913 14.3945 11.7833 18.3333H6.78333L6.78333 18.3333C7.42572 13.2715 10.9006 9.02016 15.7333 7.38333L15.7333 7.38333ZM6.78333 21.6667H11.7833V21.6667C12.0578 25.6021 13.4131 29.3854 15.7 32.6L15.7 32.6C10.8852 30.9554 7.42603 26.7139 6.78333 21.6667L6.78333 21.6667ZM24.3685 32.5833C26.6492 29.3678 28.0196 25.5962 28.3352 21.6667H33.2518C32.6089 26.6993 29.1654 30.9309 24.3685 32.5833Z" fill="#B386CF"/>
        </svg>

    )

}

export default GlobeIcon