import { Line, LineChart, XAxis, YAxis } from "recharts";
import { AgreementModel } from "Apis/Models/AgreementModel";
import The0MissionIcon from "Assets/Icons/The0MissionIcon";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAgreementUsageData, useSolarParkUsageData } from "Apis/UsageApi";
import { ISolarParkDto } from "Apis/Models/ISolarPark";
import moment from "moment";
import FlexRow from "Components/FlexRow";

interface IProps {
    agreement: AgreementModel;
    solarPark: ISolarParkDto;
    width?: number;
}

const aggregation = 'Month';

const AgreementComparisonChart = (props: IProps) => {
    
    const { agreement, width, solarPark } = props;
    
    const { t } = useTranslation();
    
    const {data: agreementUsageData, isLoading: isLoadingAgreementData } = useAgreementUsageData(agreement.id, aggregation)
    const {data: solarParkUsageData, isLoading: isLoadingSolarParkData } = useSolarParkUsageData(solarPark.id, agreement.id)
    
    const getUsage = (): any[] => {
        return solarParkUsageData?.map(x => {
            const agreementUsage = agreementUsageData?.find(y => y.key === x.key)!;

            if (!agreementUsage) {
                return null
            }

            return {
                name: moment(x.key).format("MMM"),
                parkOutput: x.usage / 1000,
                usage: agreementUsage?.usage / 1000
            }
        }).filter(x => !!x) ?? [];
    }
    
    if (isLoadingSolarParkData || isLoadingAgreementData) {
        return <The0MissionIcon width={14} height={14} spin />
    }

    const getChartMax = () => {
        if (getUsage().length === 0) {
            return 'auto'
        }

        const max = Math.max(...getUsage().map(x => x.parkOutput));

        return Math.floor(max * 1.2);
    }

    const getLegendDot = (color, value) => (
        <div style={{display: "flex", justifyContent: 'flex-start', alignItems: "center", textTransform: 'uppercase', fontFamily: 'Inter, sans-serif'}}>
            <svg width={30} height={30} viewBox="0 0 4 4">
                <circle fill={color} cx="2" cy="2" r="1" />
            </svg>
            {value}
        </div>
    )
    
    return (
        <FlexRow className={"h-100"} direction={"column"} justify={"space-between"} align={"start"}>
            <div style={{fontSize: 40, maxWidth: `max(360px, ${(width ?? 400) * 0.85}px)`}}>
                <Trans i18nKey="agreement:agreementComparison" values={{ company: agreement.nickname }}  />
            </div>
            <div>
                <div>
                    {getLegendDot("#DEC3EF", t('agreement:parkOutput'))}
                    {getLegendDot("#153629", `${agreement.nickname ?? ''} ${t('usage')}`)}
                </div>
                <LineChart width={width ?? 400} height={400} data={getUsage()}>
                    <YAxis domain={[0, getChartMax()]} padding={{bottom: 5}} orientation={"right"} axisLine={false} tickLine={false} interval={0} />
                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                    <Line type="monotone" dot={false} dataKey="parkOutput" stroke={"#DEC3EF"} strokeWidth={3} name={t('agreement:parkOutput')} />
                    <Line type="monotone" dot={false}  dataKey="usage" stroke={"#153629"} strokeWidth={2} name={`${agreement.nickname ?? ''} ${t('usage')}`} />
                </LineChart>
                <FlexRow justify={"space-around"} style={{height: 50}}>

                </FlexRow>
            </div>
        </FlexRow>
    )
}

export default AgreementComparisonChart
