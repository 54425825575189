import styles from './InvoicesPage.module.scss'
import {useTranslation} from "react-i18next";
import {StyledText} from "../../../Components/V2/Text";
import Gap from "../../../Components/V2/Gap";
import Box from 'Components/V2/Box';
import Table from 'Components/V2/Table/Table';
import moment from "moment/moment";
import ReportIcon from "../../../Assets/Icons/V2/ReportIcon";
import DownloadIcon from "../../../Assets/Icons/V2/DownloadIcon";
import {InvoiceModel} from "../../../Apis/Models/InvoiceModel";
import {toast} from "react-toastify";
import {getInvoicePdf, useInvoices} from "../../../Apis/InvoicesApi";
import AgreementSelector from "../../../Features/Dashboard/Components/AgreementSelector";
import useSelectedAgreement from "../../../Features/Dashboard/Hooks/useSelectedAgreement";
import Separator from "../../../Components/V2/Separator";
import React from "react";

const InvoicesPage = () => {

    const { t } = useTranslation();

    const selectedAgreement = useSelectedAgreement();

    const { data: invoices } = useInvoices(selectedAgreement?.id);

    const downloadInvoice = async (invoice: InvoiceModel) => {
        const message = t('downloadingInvoice');

        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });

        await getInvoicePdf(invoice);
    }

    return (
        <Box className={styles.container}>
            <Gap direction={"vertical"} size={"medium"}>
                <Gap direction={"vertical"} size={"small"}>
                    <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('invoices')}</StyledText>
                    <AgreementSelector requireSelect position={"Under Left"} />
                </Gap>

                <Separator/>

                <Table>
                    <thead>
                        <tr>
                            <th>
                                {t('period')}
                            </th>
                            <th>
                                {t('download')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {invoices?.map(invoice => (
                        <tr key={invoice.id}>
                            <td>
                                <div className={"flex align-center"} style={{gap: 8}}>
                                    <ReportIcon fill={"#B386CF"}/>
                                    {moment(invoice.periodCoveredFrom).format('MMMM') === moment(invoice.periodCoveredTo).format('MMMM') ? (
                                        moment(invoice.periodCoveredFrom).format('MMMM YYYY')
                                    ) : (
                                        moment(invoice.periodCoveredFrom).format('MMMM') + ' - ' + moment(invoice.periodCoveredTo).format('MMMM YYYY')
                                    )}
                                </div>
                            </td>
                            <td>
                                <div className={"flex align-center clickable"} style={{gap: 8}} onClick={() => downloadInvoice(invoice)}>
                                    <DownloadIcon />
                                    {t('downloadInvoice')}
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Gap>
        </Box>
    )

}

export default InvoicesPage