import {
    Routes,
    Route, useNavigate, useLocation, Navigate
} from "react-router-dom";
import RequireAuthentication from "Components/RequireAuthentication";
import SetupAgreementRoutes from "Pages/Agreements/Setup/SetupAgreementRoutes";
import { useAuthProvider } from "Providers/AuthProvider";
import { useEffect } from "react";
import AdminRoutes from "Pages/Admin/AdminRoutes";
import ListAgreementsPage from "Pages/Agreements/ListAgreementsPage";
import AgreementRoutes from "Pages/Agreements/Agreement/AgreementRoutes";
import AgreementMenu from "Pages/Agreements/Agreement/Components/AgreementMenu";
import TopMenuLayout from "Layouts/TopMenuLayout";

const AuthenticatedRoutes = () => {
    
    const { userContext } = useAuthProvider();
    
    const { pathname } = useLocation();
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if (userContext && pathname === '/') {
            if (userContext.permissions.includes("Admin")) {
                navigate(`/admin`);
                return;
            }

            if (userContext.agreements.length === 0) {
                navigate(`/agreements/setup/new`);
                return;
            }

            if (userContext.agreements.length === 1) {
                navigate(`/agreements/${userContext.agreements[0].id}/dashboard`);
                return;
            }
            
            if (userContext.agreements.length > 1) {
                navigate(`/agreements`);
                return;
            }
        }
    }, [pathname, userContext, navigate])
    
    return (
        <RequireAuthentication>
            <Routes>
                <Route path="/agreements" element={<TopMenuLayout menu={<></>}><ListAgreementsPage /></TopMenuLayout>} />
                
                <Route path="/agreements/setup/*" element={<SetupAgreementRoutes />} />
                
                <Route path="/agreements/:agreementId/*" element={<TopMenuLayout menu={<AgreementMenu />}><AgreementRoutes /></TopMenuLayout>} />
                
                <Route path="/admin/*" element={<AdminRoutes />} />

                <Route path="*" element={<Navigate to={"/agreements"} />} />
            </Routes>
        </RequireAuthentication>
    );
}

export default AuthenticatedRoutes;
