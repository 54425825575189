import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './Translations';
import { QueryClient, QueryClientProvider } from "react-query";
import TranslationProvider from "Providers/TranslationProvider";
import AuthProvider from "Providers/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import ApplicationRoutes from "Pages/Routes";
import i18n from "i18next";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import _ from "lodash";
import 'react-toastify/dist/ReactToastify.css';
import 'Styles/toasts.scss';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            onError: (error: any) => {
                let message;
    
                if (error.status === 404) {
                    message = i18n.t('errors:notFound');
                } else if (error.status === 400) {
                    if (!!error.data?.message) {
                        message = i18n.t(error.data.message)
                    } else if (error.data !== "") {
                        message = i18n.t(error.data)
                    }
        
                    const errors = _.get(error, 'data.errors');
        
                    const keys = Object.keys(errors ?? {});
        
                    if (errors && keys.length > 0) {
                        message = errors[keys[0]];
                    }
                } else {
                    message = error.data ?? i18n.t("unexpectedErrorOccurred");
                }
    
                toast.error(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
    
                console.error(error);
            }
        },
        mutations: {
            onError: (error: any) => {
                let message;
        
                if (error.status === 404) {
                    message = i18n.t('errors:notFound');
                } else if (error.status === 400) {
                    if (!!error.data?.message) {
                        message = i18n.t(error.data.message)
                    } else if (error.data !== "") {
                        message = i18n.t(error.data)
                    }
            
                    const errors = _.get(error, 'data.errors');
            
                    const keys = Object.keys(errors ?? {});
            
                    if (errors && keys.length > 0) {
                        message = errors[keys[0]];
                    }
                } else {
                    message = error.data ?? i18n.t("unexpectedErrorOccurred");
                }
        
                toast.error(message, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                });
        
                console.error(error);
            }
        }}
})

i18n.changeLanguage("da")


if (i18n.language === 'da') {
    moment.locale("da-dk", {
        week: {
            dow: 1,
            doy: 4
        },
        months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
        weekdaysShort: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
        weekdaysMin: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'D MMMM YYYY',
            LLL : 'D MMMM YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm'
        },
    });
} else {
    moment.locale('en-gb', {
        week: {
            dow: 1,
            doy: 4
        }
    })
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const render = Component => {
    return root.render(
        <StrictMode>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <TranslationProvider>
                        <AuthProvider>
                            <Component />
                            <ToastContainer />
                        </AuthProvider>
                    </TranslationProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </StrictMode>
    );
};

render(ApplicationRoutes);

if (module.hot) {
    module.hot.accept('./Pages/Routes', () => {
        const NextApp = require('Pages/Routes').default;
        render(NextApp);
    });
}
