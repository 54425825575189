import { useTranslation } from "react-i18next";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { confirmPasswordReset, verifyPasswordReset, applyActionCode } from "FirebaseSetup/auth";
import FlexRow from "Components/FlexRow";
import The0MissionIcon from "Assets/Icons/The0MissionIcon";
import Button from "Components/Button";
import styles from './AuthActionsPage.module.scss';
import { useForm } from "react-hook-form";
import { usePasswordChecks } from "Hooks/UsePasswordChecks";
import Input from "Components/Input";
import Space from "Components/Space";
import { useAuthProvider } from "Providers/AuthProvider";
import CheckmarkIcon from "Assets/Icons/CheckmarkIcon";

const AuthActionsPage = () => {
    
    const { t } = useTranslation()
    
    let query = new URLSearchParams(useLocation().search);
    
    const { forceReload } = useAuthProvider();
    
    let mode = query.get('mode');
    let oobCode = query.get('oobCode') ?? '';
    
    const { control, handleSubmit, formState: { errors } } = useForm<{password: string}>();
    
    const navigate = useNavigate();
    const [codeValidated, setCodeValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validatingCodeFailed, setValidatingCodeFailed] = useState(false);
    const [error, setError] = useState('');
    
    const [passwordChecks, checkPassword] = usePasswordChecks();
    
    useEffect(() => {
        checkPassword('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        if (!codeValidated) {
            if (mode === 'resetPassword') {
                setLoading(true);
        
                verifyPasswordReset(oobCode).then(() => {
                    forceReload().then(() => {
                        setCodeValidated(true);
                    })
                }).catch((e) => {
                    setValidatingCodeFailed(c => true);
                }).finally(() => {
                    setLoading(false);
                })
            } else if (mode === 'verifyEmail') {
                setLoading(true);
                
                applyActionCode(oobCode).then(() => {
                    forceReload().then(() => {
                        setCodeValidated(true);
                    })
                }).catch(e => {
                    if (e.code !== 'auth/invalid-action-code') {
                        setError(t('somethingWentWrong'));
                    }
                }).finally(() => {
                    setLoading(false)
                })
            }
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);
    
    const resetPassword = (data: { password: string }) => {
        setError('');
    
        if (passwordChecks.filter(x => !x.fulfilled).length > 0) {
            setError(t('auth:signup.passwordRequirementsNotMet'));
            return;
        }
        
        setLoading(true)
        
        confirmPasswordReset(oobCode, data.password).then(
            () => {setError(t('auth:actions.passwordUpdatedMessage'))},
            (e) => {
                if (e.code === 'auth/network-request-failed') {
                    setError(t('somethingWentWrong'));
                }
            }
        ).catch((e) => {
            if (e.code === 'auth/network-request-failed') {
                setError(t('somethingWentWrong'));
            }
        }).finally(() => {setLoading(false)})
    };
    
    let content;
    
    if (loading) {
        content = (
            <div className={"w-100 flex column justify-center h-100"}>
                <FlexRow justify={'center'}>
                    <The0MissionIcon width={40} height={40} spin />
                </FlexRow>
            </div>
        )
    }
    
    if (!validatingCodeFailed) {
        
        if (mode === 'resetPassword') {
            content = (
                <>
                    <h1>{t('auth:actions.resetPassword.h1')}</h1>
                    <h2>{t('auth:actions.resetPassword.h2')}</h2>
                    <form onSubmit={handleSubmit(resetPassword)}>
                        <Space>
                            <FlexRow justify={"center"}>
                                <h2 style={error ? {color: 'red'} : {}}>{error}</h2>
                            </FlexRow>
            
                            <div className={"w-100"}>
                                <Input label={t('password')} type={"password"} placeholder={t('auth:actions.newPassword')} control={control} errors={errors.password} name={"password"} rules={{required: true}} onChange={event => checkPassword(event.target.value)} />
                            </div>
            
                            <div className={styles.checkList}>
                                {passwordChecks.map((x, index) => (
                                    <div key={index}>
                                        <CheckmarkIcon fill={!x.fulfilled ? 'none' : '#153629'} />
                                        {x.message}
                                    </div>
                                ))}
                            </div>
                            
                            <FlexRow justify={'center'}>
                                <Button type="submit" loading={loading}>{t('auth:actions.updatePassword')}</Button>
                            </FlexRow>
    
                            <FlexRow justify={'center'}>
                                <Link to="/auth/login">{t('auth:actions.gotoLogin')}</Link>
                            </FlexRow>
                        </Space>
                    </form>
                </>
            )
        }
        
        if (mode === 'verifyEmail') {
            content =  (
                <>
                    {error && (
                        <h1>{error}</h1>
                    )}
                    
                    {loading && (
                        <>
                            <h1>{t('auth:actions.emailVerifiedLoading.h1')}</h1>
                            <h2>{t('auth:actions.emailVerifiedLoading.h2')}</h2>
                            
                            <FlexRow justify={"center"}>
                                <The0MissionIcon spin height={40} width={40} />
                            </FlexRow>
                        </>
                    )}
    
                    {!loading && !error && (
                        <>
                            <h1>{t('auth:actions.emailVerified.h1')}</h1>
                            <h2>{t('auth:actions.emailVerified.h2')}</h2>
    
                            <FlexRow justify={"center"}>
                                <Button onClick={() => navigate('/agreements/setup/new')}>
                                    {t('auth:actions.emailVerifiedButton')}
                                </Button>
                            </FlexRow>
                        </>
                    )}
                </>
            )
        }
        
        if (content) {
            return (
                <div className={styles.container}>
                    <div className={styles.innerContainer}>
                        {content}
                    </div>
                </div>
            )
        }
    }
    
    return (
        <Navigate to={"/"} />
    )
}

export default AuthActionsPage
