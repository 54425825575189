import List from "Components/List";
import {Trans, useTranslation} from "react-i18next";
import { AgreementModel, AgreementType } from "Apis/Models/AgreementModel";
import Button from "Components/Button";
import { useNavigate } from "react-router-dom";
import { useAgreements, useDeleteAgreement } from "Apis/AgreementApi";
import PageHeader from "Components/PageHeader";
import React, {useEffect, useState} from "react";
import FlexRow from "Components/FlexRow";
import Modal from "Components/Modal";
import Space from "Components/Space";
import useToast from "../../Hooks/useToast";

const ListAgreementsPage = () => {
    
    const { t } = useTranslation();
    const { showSuccessToast } = useToast();

    const { data: agreements, isFetched, isLoading } = useAgreements();
    const { mutateAsync: deleteAgreementMutation, isLoading: isDeletingAgreement } = useDeleteAgreement();

    const [selectedAgreement, setSelectedAgreement] = useState<AgreementModel>();
    const [deleteModalShown, setDeleteModalShown] = useState<boolean>(false);

    const navigate = useNavigate();
    
    useEffect(() => {
        if (isFetched) {
            if (agreements?.length === 0) {
                navigate(`/agreements/setup/new`);
            }
        }
    }, [isFetched, agreements, navigate])
    
    const getAgreementTypeText = (agreement: AgreementType) => {
        switch (agreement) {
            case "Custom":
                return t('agreement:custom');
            case "Match":
                return t('agreement:match');
            case "MatchAndCustom":
                return t('agreement:matchAndCustom');
        }
    }
    
    const getAgreementStatus = (agreement: AgreementModel) => {
        if (!agreement.agreementSignedAt) {
            return t('agreement:notSignedUser');
        }
    
        return t('agreement:signed');
    }
    
    const getAgreementLink = (agreement: AgreementModel) => {
        const signPage = `/agreements/setup/${agreement.id}/signContact`;
        const dashboardPage = `/agreements/${agreement.id}/dashboard`;
        
        if (agreement.agreementSignedAt) {
            return dashboardPage;
        }
        
        return signPage
    }

    const getGotoLinkText = (record: AgreementModel) => {
        if (record.agreementSignedAt) {
            return t('agreement:gotoDashboard');
        } else {
            return t('agreement:gotoSignAgreement');
        }
    }

    const selectAgreement = (agreement: AgreementModel) => {
        setSelectedAgreement(agreement);
        setDeleteModalShown(true)
    }

    const deleteAgreement = async () => {
        await deleteAgreementMutation(selectedAgreement!);

        setSelectedAgreement(undefined);
        setDeleteModalShown(false);

        showSuccessToast(t('agreement:deleted'));
    }
    
    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('agreement:yourAgreements')} />

                <Button color={"link"} onClick={() => navigate('/agreements/setup/new')}>+ {t('addNewAgreement')}</Button>
            </FlexRow>
            
            <List emptyText={t('agreement:noAgreementsFound')} loading={isLoading} keyDataIndex={"id"} data={agreements} columns={[
                {
                    title: t('comapnyName'),
                    dataIndex: "companyName",
                    textRender: (value) => value ?? t('notFilled')
                },
                {
                    title: t('agreement:agreementType'),
                    dataIndex: "agreementType",
                    textRender: (text) => getAgreementTypeText(text),
                },
                {
                    title: t('agreement:status.title'),
                    dataIndex: "agreementSignedAt",
                    textRender: (_, record) => getAgreementStatus(record),
                },
                {
                    title: t('agreement:functions'),
                    customRender: (_, record) => (
                        <FlexRow justify={"space-between"}>
                            <div>
                                {!record.agreementSignedAt && (
                                    <Button color={"link"} onClick={() => selectAgreement(record)}>{t('agreement:deleteAgreement')}</Button>
                                )}
                            </div>
                            <Button color={"link"} onClick={() => navigate(getAgreementLink(record))}>{getGotoLinkText(record)}</Button>
                        </FlexRow>
                    )
                },
            ]} />

            <Modal title={t('agreement:deleteAgreement')} visible={deleteModalShown} onCancel={() => setDeleteModalShown(false)}>
                <Space>
                    <div className={"w-100"}>
                        <Trans i18nKey="agreement:deleteAgreementConfirmation" values={{company: selectedAgreement?.nickname, vatNumber: selectedAgreement?.vatNumber}} />
                    </div>

                    <FlexRow justify={"end"}>
                        <Button color={"danger"}  disabled={isDeletingAgreement} onClick={deleteAgreement} loading={isDeletingAgreement}>{t('delete')}</Button>
                    </FlexRow>
                </Space>
            </Modal>
        </>
    )
}

export default ListAgreementsPage
