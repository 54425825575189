interface Props {
    width?: number;
    height?: number;
}
const The0MissionText = (props: Props) => {

    return (
        <svg width={props.width ?? "246"} height={props.height ?? "28"} viewBox="0 0 246 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_240_18866)">
                <path
                    d="M31.0953 8.04675H29.793C28.8163 4.73771 27.9438 3.88119 25.6778 3.88119H22.6226V22.3283C22.6226 23.9642 23.4039 24.6053 25.6908 24.8553V26.1028H15.3349V24.8553C17.6009 24.6053 18.4031 23.9642 18.4031 22.3283V3.88119H15.3349C13.035 3.88119 12.1625 4.73771 11.1988 8.04675H9.89648L10.2794 2.31445H30.7124L31.0953 8.04675Z"
                    fill="#153629"/>
                <path
                    d="M51.6664 24.8552V26.1028H43.1936V24.8552C44.5532 24.5706 45.3216 24.1796 45.3216 22.682V14.0663C45.3216 11.6723 44.2068 10.47 42.2872 10.47C40.9849 10.47 39.7425 11.0738 38.2423 12.7123V22.682C38.2423 24.1796 39.0809 24.544 40.5864 24.8552V26.1028H31.8975V24.8552C33.4316 24.5334 34.2416 24.1796 34.2416 22.682V5.5197C34.2416 4.3094 33.7884 3.70292 32.4991 3.49012L31.9053 3.38372V2.31972L37.3749 0.859375H38.2449V11.0738C40.0941 8.79682 41.8731 7.61578 43.9984 7.61578C47.2073 7.61578 49.3353 9.71718 49.3353 13.6327V22.6767C49.3327 24.1796 50.1687 24.5334 51.6664 24.8552Z"
                    fill="#153629"/>
                <path
                    d="M67.7942 16.4151H55.9745C56.1829 20.7961 58.3786 23.5013 61.6213 23.5013C62.6545 23.4912 63.668 23.2111 64.5653 22.6877C65.4625 22.1644 66.2137 21.4152 66.7472 20.5115L67.69 20.9371C66.7836 24.0014 64.1321 26.6002 60.194 26.6002C54.9639 26.6002 52.0676 22.291 52.0676 17.5562C52.0676 11.3956 55.589 7.62109 60.681 7.62109C65.5282 7.62109 67.7577 11.4675 67.7942 16.4151ZM55.998 14.7792H63.6685C63.494 11.4675 62.4834 9.22241 60.1132 9.22241C57.6467 9.22241 56.2871 11.3956 55.998 14.7792Z"
                    fill="#153629"/>
                <path
                    d="M93.9417 14.2067C93.9417 20.9737 90.7355 26.5996 84.4245 26.5996C78.0433 26.5996 74.8708 20.9737 74.8708 14.2067C74.8708 7.43965 78.0433 1.81641 84.4245 1.81641C90.7355 1.81641 93.9417 7.44763 93.9417 14.2067ZM79.2987 12.3447C79.2987 18.1488 80.937 24.8786 84.9454 24.8786C87.7011 24.8786 89.5139 21.4605 89.5139 16.0474C89.5139 10.2087 87.8053 3.51349 83.8645 3.51349C81.1115 3.52413 79.2987 6.94223 79.2987 12.3553V12.3447Z"
                    fill="#153629"/>
                <path d="M114.5 16.6654H96.6609V15.0986H114.5V16.6654Z" fill="#153629"/>
                <path
                    d="M146.534 3.56199C144.19 3.66839 143.395 4.41585 143.395 6.22199V22.3283C143.395 23.7514 144.197 24.6053 146.534 24.8553V26.1028H136.037V24.8553C138.41 24.5707 139.176 23.7168 139.176 22.3283V7.05191L130.489 26.0683H128.71L120.376 7.05457V22.3283C120.376 23.7514 121.175 24.6053 123.548 24.8553V26.1028H115.734V24.8553C118.037 24.5707 118.839 23.7514 118.839 22.3283V6.23263C118.839 4.41585 118.037 3.66839 115.7 3.57263V2.31445H122.962L131.086 20.9744L139.605 2.31445H146.534V3.56199Z"
                    fill="#153629"/>
                <path
                    d="M156.653 24.8552V26.1027H147.971V24.8552C149.506 24.5333 150.316 24.1795 150.316 22.6819V12.3931C150.316 11.1455 149.896 10.4326 148.396 10.2198L147.979 10.148V9.08402L153.449 7.62368H154.321V22.6819C154.316 24.1795 155.152 24.5333 156.653 24.8552ZM149.68 2.63618C149.68 1.1386 150.805 0.00012299 152.368 0.00012299C152.714 -0.0139931 153.059 0.0439912 153.382 0.170541C153.706 0.297091 154 0.489556 154.248 0.736203C154.497 0.982849 154.693 1.27851 154.825 1.60516C154.958 1.93182 155.024 2.28262 155.019 2.63618C155.024 2.98952 154.957 3.34004 154.825 3.66638C154.692 3.99272 154.496 4.28806 154.247 4.53442C153.999 4.78078 153.705 4.973 153.382 5.09939C153.059 5.22578 152.714 5.28368 152.368 5.26958C150.805 5.26958 149.68 4.1311 149.68 2.63618Z"
                    fill="#153629"/>
                <path
                    d="M171.507 10.1852L168.717 13.0687C167.715 10.4335 166.286 9.11591 164.43 9.11591C162.651 9.11591 161.257 10.0762 161.257 11.6083C161.257 15.9175 171.647 13.8161 171.647 21.0088C171.647 24.0013 169.066 26.5948 164.813 26.5948C161.466 26.5948 158.468 24.9216 157.351 23.2831L160.419 20.647C161.64 23.573 163.284 25.0999 165.162 25.0999C166.985 25.0999 168.475 23.9587 168.438 22.1739C168.405 17.6173 158.153 19.6096 158.119 13.0235C158.082 9.71175 160.698 7.61035 164.813 7.61035C168.777 7.62099 170.811 9.25955 171.507 10.1852Z"
                    fill="#153629"/>
                <path
                    d="M186.824 10.1853L184.035 13.0688C183.023 10.4318 181.593 9.11424 179.745 9.11601C177.966 9.11601 176.573 10.0763 176.573 11.6084C176.573 15.9176 186.962 13.8162 186.962 21.0089C186.962 24.0014 184.381 26.5949 180.128 26.5949C176.781 26.5949 173.783 24.9217 172.666 23.2832L175.737 20.6471C176.956 23.5731 178.602 25.1 180.477 25.1C182.3 25.1 183.79 23.9588 183.754 22.174C183.72 17.628 173.465 19.6204 173.434 13.0342C173.4 9.72249 176.015 7.62109 180.128 7.62109C184.103 7.62109 186.126 9.25965 186.824 10.1853Z"
                    fill="#153629"/>
                <path
                    d="M197.274 24.8552V26.1027H188.593V24.8552C190.127 24.5333 190.937 24.1795 190.937 22.6819V12.3931C190.937 11.1455 190.518 10.4326 189.017 10.2198L188.601 10.148V9.08402L194.07 7.62368H194.943V22.6819C194.938 24.1795 195.774 24.5333 197.274 24.8552ZM190.301 2.63618C190.301 1.1386 191.416 0.00012299 192.984 0.00012299C193.33 -0.0139931 193.675 0.0439912 193.999 0.170541C194.322 0.297091 194.616 0.489556 194.865 0.736203C195.113 0.982849 195.309 1.27851 195.442 1.60516C195.574 1.93182 195.64 2.28262 195.636 2.63618C195.64 2.98952 195.573 3.34004 195.441 3.66638C195.308 3.99272 195.112 4.28806 194.864 4.53442C194.616 4.78078 194.321 4.973 193.998 5.09939C193.675 5.22578 193.33 5.28368 192.984 5.26958C191.416 5.26958 190.301 4.1311 190.301 2.63618Z"
                    fill="#153629"/>
                <path
                    d="M198.027 17.4844C198.027 11.6084 201.895 7.62109 206.778 7.62109C212.008 7.62109 215.319 11.5366 215.319 16.7715C215.319 22.6235 211.412 26.6135 206.567 26.6135C201.303 26.6002 198.027 22.7193 198.027 17.4844ZM211.206 18.2318C211.206 13.9599 209.602 9.29423 206.257 9.29423C203.713 9.29423 202.179 12.0367 202.179 15.9176C202.179 20.2268 203.817 24.9271 207.127 24.9271C209.669 24.9271 211.206 22.15 211.206 18.2318Z"
                    fill="#153629"/>
                <path
                    d="M236.416 24.8555V26.103H227.943V24.8555C229.303 24.5709 230.069 24.1799 230.069 22.6823V14.0665C230.069 11.6725 228.954 10.4702 227.037 10.4702C225.735 10.4702 224.49 11.074 222.992 12.7126V22.6823C222.992 24.1799 223.828 24.5443 225.336 24.8555V26.103H216.645V24.8555C218.179 24.5336 218.989 24.1799 218.989 22.6823V12.3934C218.989 11.1459 218.572 10.433 217.072 10.2202L216.652 10.1484V9.08436L222.122 7.62402H222.995V11.082C224.841 8.80506 226.62 7.62402 228.748 7.62402C231.954 7.62402 234.082 9.72542 234.082 13.6409V22.6849C234.08 24.1799 234.916 24.5336 236.416 24.8555Z"
                    fill="#153629"/>
            </g>
            <defs>
                <clipPath id="clip0_240_18866">
                    <rect width="226.207" height="26.6" fill="white" transform="translate(9.89648)"/>
                </clipPath>
            </defs>
        </svg>
    )

}

export default The0MissionText