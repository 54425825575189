
const CircledMatchIcon = () => {

    return (
        <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="60" height="60" rx="30" fill="#D1B6E2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.9862 33.9641C24.4462 34.4748 25.5 35.864 25.5 37.5028C25.5 39.5641 23.8125 41.2507 21.75 41.2507C19.675 41.2507 18 39.5641 18 37.5028C18 35.8673 19.0418 34.4804 20.5 33.9672V28.7574C20.5 24.6098 23.85 21.2616 28 21.2616H28.7375L27.1125 19.65V19.6488C26.6125 19.1491 26.6125 18.362 27.1125 17.8748C27.6 17.3751 28.3875 17.3751 28.8875 17.8748L32.6375 21.6227C32.8375 21.8351 32.9625 22.1099 32.9875 22.4097V22.6596C32.95 22.897 32.85 23.1343 32.6875 23.3217H32.625L28.875 27.0696L28.8738 27.0684C28.2988 27.4557 27.5237 27.3057 27.1237 26.7436C26.8362 26.3313 26.8238 25.7816 27.0988 25.3568L28.7238 23.7452H27.9862C25.2238 23.7452 22.9862 25.9815 22.9862 28.7424V33.9641ZM35.5 22.5119C35.5 24.1375 36.5416 25.5299 37.9996 26.0461V31.2563C37.9996 34.0048 35.7496 36.2535 32.9996 36.2535H32.2621L33.8746 34.6544C34.3621 34.1547 34.3621 33.3676 33.8746 32.8804C33.3746 32.3807 32.5871 32.3807 32.0996 32.8804L28.3496 36.6283C28.1371 36.8407 28.0121 37.1155 27.9996 37.4154C27.9871 37.4903 27.9871 37.5778 27.9996 37.6652C28.0246 37.9026 28.1246 38.1399 28.2996 38.3273L28.3621 38.3898L32.1121 42.1377C32.3371 42.3626 32.6621 42.5 32.9996 42.5L32.9871 42.4875C33.3121 42.4875 33.6371 42.3501 33.8746 42.1252H33.8871C34.3746 41.638 34.3746 40.8384 33.8871 40.3637L32.2621 38.7521H32.9996C37.1371 38.7521 40.4996 35.3915 40.4996 31.2563V26.0433C41.9524 25.5261 42.9997 24.135 43 22.5113C42.9997 20.4378 41.3123 18.764 39.25 18.764C37.175 18.764 35.5 20.4381 35.5 22.5119ZM39.2496 23.7605C39.2597 23.7605 39.2698 23.7606 39.2799 23.7609C39.9537 23.7449 40.5 23.1891 40.5 22.5119C40.5 21.8123 39.9375 21.2626 39.25 21.2626C38.55 21.2626 38 21.8123 38 22.5119C38 23.189 38.534 23.7447 39.2193 23.7609C39.2294 23.7606 39.2395 23.7605 39.2496 23.7605ZM20.5 37.5028C20.5 36.8032 21.05 36.2535 21.75 36.2535C22.4375 36.2535 23 36.8032 23 37.5028C23 38.1899 22.4375 38.7521 21.75 38.7521C21.05 38.7521 20.5 38.1899 20.5 37.5028Z" fill="#6B507C"/>
        </svg>


)

}

export default CircledMatchIcon