const Checkmark = () => {

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.8618 4.19526C13.1221 3.93491 13.5442 3.93491 13.8046 4.19526C14.0449 4.43558 14.0634 4.81374 13.86 5.07527L13.8046 5.13807L7.13791 11.8047C6.89759 12.0451 6.51943 12.0635 6.2579 11.8602L6.1951 11.8047L2.86177 8.4714C2.60142 8.21105 2.60142 7.78895 2.86177 7.5286C3.10209 7.28827 3.48024 7.26979 3.74177 7.47314L3.80458 7.5286L6.6665 10.39L12.8618 4.19526Z" fill="#ECEEFE"/>
        </svg>
    )

}

export default Checkmark