import {useMutation} from "react-query";
import {request} from "../../../Apis/BaseApi";
import {OnboardingValues} from "./OnboardingValues";
import {OnboardingResult} from "./OnboardingResult";

export const useCreateAgreement = () => {
    return useMutation(async (data: OnboardingValues) => {
        return await request<OnboardingResult>({
            url: `/agreement/onboarding`,
            method: 'POST',
            data
        })
    });
}