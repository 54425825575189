import Box from "../../../Components/V2/Box";
import {useTranslation} from "react-i18next";
import Input from "../../../Components/V2/Input";
import Button from "Components/V2/Button";
import Gap from "../../../Components/V2/Gap";
import {OnboardingValues} from "../Services/OnboardingValues";
import {useVatInformationSearch} from "../../../Hooks/UseVatinformationSearch";
import {useEffect, useState} from "react";
import InputLabel from "../../../Components/V2/InputLabel";
import OnboardingBoxContent from "./OnboardingBoxContent";

interface Props {
    values: Partial<OnboardingValues>;
    onChange: (values: Partial<OnboardingValues>) => void;
    goBack: () => void;
    next: () => void;
}
const CompanyDetails = (props: Props) => {

    const { t } = useTranslation();
    
    const { values, onChange } = props;
    const [vatNumberValue, setVatNumberValue] = useState(props.values.companyVatNumber?.toString())

    const { search, results, query } = useVatInformationSearch();
    
    useEffect(() => {
        if (!!results && results.length > 0) {
            let result;

            if (results?.length === 1) {
                result = results[0];
            } else {
                result = results?.find(x => x.name.toLowerCase() === query.toLowerCase() || x.vatNumber === query);
            }

            if (!!result) {
                onChange({...values, companyVatNumber: result.vatNumber, companyName: result.name})
            } else {
                onChange({...values, companyVatNumber: '', companyName: ''})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results]);
    
    return (
        <Box style={{width: '100%'}}>
            <OnboardingBoxContent
                content={(
                    <>
                        <div>
                            <InputLabel>
                                {t('companyVatNumber')}
                            </InputLabel>
                            <Input maxLength={8} type={"number"} value={vatNumberValue} onChange={e => {
                                search(e.currentTarget.value);
                                setVatNumberValue(e.currentTarget.value)
                            }} placeholder={t('companyVatNumber')} />
                        </div>

                        <div>
                            <InputLabel>
                                {t('companyName')}
                            </InputLabel>
                            <Input disabled value={props.values.companyName} placeholder={t('companyName')}></Input>
                        </div>
                    </>
                )}
                buttons={(
                    <Gap size={"small"} className={"flex justify-end"}>
                        <Button outline text={t('back')} onClick={props.goBack} />
                        <Button text={t('continue')} disabled={!values.companyName} onClick={() => {
                            if (!!values.companyName) {
                                props.next();
                            }
                        }} />
                    </Gap>
                )}
            />
        </Box>
    )

}

export default CompanyDetails