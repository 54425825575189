import Back from "Components/Back";
import { useTranslation } from "react-i18next";
import List from "../../../Components/List";
import Button from "../../../Components/Button";
import {useAnnualReports} from "../../../Apis/SolarParkAnnualReportApi";
import {ISolarParkAnnualReportDto} from "../../../Apis/Models/ISolarParkAnnualReportDto";
import Space from "Components/Space";
import { saveAs } from 'file-saver';

const AnnualReportsPage = () => {
    
    const { t } = useTranslation();

    const { data: annualReports, isLoading: isLoadingAnnualReports } = useAnnualReports();

    const downloadReport = async (report: ISolarParkAnnualReportDto) => {
        const response = await fetch(report.file.url);

        const blob = await response.blob();

        saveAs(blob, report.file.fileName);
    }

    return (
        <>
            <Back text={t('agreement:annnualReports.back')} />

            <List data={annualReports} loading={isLoadingAnnualReports} columns={[
                {
                    dataIndex: 'name',
                    title: t('annualReport:name'),
                },
                {
                    dataIndex: 'solarPark',
                    title: t('solarPark'),
                    textRender: (value, record) => record.solarPark?.name
                },
                {
                    title: t('solarPark:functions'),
                    customRender: (_, report: ISolarParkAnnualReportDto) => (
                        <Space direction={"row"} size={"large"}>
                            <Button color={"link"} onClick={() => downloadReport(report)}>{t('annualReport:downloadReport')}</Button>
                        </Space>
                    )
                },
            ]} emptyText={t('annualReport:noReportsFound')} keyDataIndex={"id"}
            />
        </>
    )
}

export default AnnualReportsPage
