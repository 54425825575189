import styles from './CenteredPageLayout.module.scss';

interface Props {
    children: any;
    maxWidth?: number
}
const CenteredPageLayout = (props: Props) => {
    
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer} style={{maxWidth: `clamp(450px, 80%, ${props.maxWidth ?? 1100}px)`}}>
                {props.children}
            </div>
        </div>
    )
}

export default CenteredPageLayout
