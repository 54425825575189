import * as React from "react"
import { SVGProps } from "react"
const InternationalUsageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={40} height={40} viewBox={"0 0 40 40"} {...props}>
        <path
            fill="#B386CF"
            fillRule="evenodd"
            d="M20 6.858a26.628 26.628 0 0 0-2.617 6.475h5.234A26.628 26.628 0 0 0 20 6.858Zm3.254 9.809h-6.509a26.712 26.712 0 0 0 0 6.666h6.509a26.702 26.702 0 0 0 0-6.666Zm3.356 6.666a30.046 30.046 0 0 0 0-6.666h6.303c.274 1.065.42 2.182.42 3.333 0 1.151-.146 2.268-.42 3.333H26.61Zm-3.993 3.334h-5.234A26.627 26.627 0 0 0 20 33.142a26.627 26.627 0 0 0 2.617-6.475Zm1.342 6.069a29.943 29.943 0 0 0 2.088-6.07h5.502a13.369 13.369 0 0 1-7.59 6.07Zm-3.328 3.919c.195.023.391.012.58-.032 8.64-.62 15.455-7.826 15.455-16.623 0-8.798-6.816-16.004-15.456-16.624a1.67 1.67 0 0 0-.577-.031 16.952 16.952 0 0 0-1.266 0 1.67 1.67 0 0 0-.577.031C10.15 3.996 3.333 11.202 3.333 20c0 8.797 6.816 16.003 15.455 16.623.189.044.385.055.58.032a16.981 16.981 0 0 0 1.263 0Zm3.328-29.39a13.369 13.369 0 0 1 7.59 6.068h-5.502a29.943 29.943 0 0 0-2.088-6.069ZM8.45 13.332a13.369 13.369 0 0 1 7.59-6.069 29.95 29.95 0 0 0-2.087 6.07H8.45Zm-1.364 3.334h6.303a30.046 30.046 0 0 0 0 6.666H7.086A13.357 13.357 0 0 1 6.666 20c0-1.151.146-2.268.42-3.333Zm1.364 10h5.503a29.95 29.95 0 0 0 2.087 6.069 13.368 13.368 0 0 1-7.59-6.07Z"
            clipRule="evenodd"
        />
    </svg>
)
export default InternationalUsageIcon
