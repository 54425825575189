import { Navigate, useLocation } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";

const RedirectIfLoggedIn = ({ children }) => {
    
    let location = useLocation();
    
    const user = useAuthProvider();
    
    if (!user.firebaseUser?.emailVerified && !location.pathname.includes("/auth")) {
        return (
            <Navigate to={"/auth/verifyEmail"} />
        )
    }
    
    return user.firebaseUser === null ? children : <Navigate to={"/"} />;
}

export default RedirectIfLoggedIn;
