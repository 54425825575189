import {request} from "./BaseApi";
import { useMutation } from "react-query";
import { EloverblikAuthorizationUrl } from "Apis/Models/EloverblikAuthorizationUrl";

export const useAuthorizationUrl = () => {
    return useMutation(async (data: {agreementId: string}) => {
        return await request<EloverblikAuthorizationUrl>({
            url: `/eloverblik/authorization/`,
            method: 'POST',
            data: data
        })
    });
}


export const useRemoveAuthorization = () => {
    return useMutation(async (id: string) => {
        return await request({
            url: `/eloverblik/authorization/${id}`,
            method: 'DELETE',
        })
    });
}
