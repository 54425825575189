import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAgreement } from "Apis/AgreementApi";
import Step from "Components/Step";
import FlexRow from "Components/FlexRow";
import CenteredPageLayout from "Layouts/CenteredPageLayout";
import { useState } from "react";
import styles from './EloverBlikPage.module.scss';
import Button from "Components/Button";
import { useAuthorizationUrl } from "Apis/EloverblikAuthorizationApi";
import { EloverblikAuthorizationModel, EloverblikAuthorizationStatus } from "Apis/Models/EloverblikAuthorizationModel";
import moment from "moment";

const EloverBlikPage = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const { agreementId } = useParams();
    
    const { data: agreement } = useAgreement(agreementId!);
    const { mutateAsync: getAuthorizationUrlMutation, isLoading: isGettingAuthorizationUrl } = useAuthorizationUrl();
    
    const [openItem, setOpenItem] = useState<number | null>(null);
    
    const getTranslatedStatus = (status: EloverblikAuthorizationStatus) => {
        switch (status) {
            case "Active":
                return t('active');
            case "Pending":
                return t('pending');
            case "Expired":
                return t('expired');
            case "Invalid":
                return t('invalid');
        }
    }
    
    const giveAccess = async () => {
        const authorization = await getAuthorizationUrlMutation({ agreementId: agreement!.id });
    
        window.open(authorization.url, "_blank");
    }
    
    const openEloverblik = (authorization: EloverblikAuthorizationModel) => {
        window.open(authorization.authorizationUrl, "_blank");
    }
    
    const gotoNext = () => {
        if (agreement!.agreementType === 'Match') {
            navigate(`/agreements/setup/${agreement!.id}/result`);
        } else {
            navigate(`/agreements/setup/${agreement!.id}/custom`)
        }
        
    }
    
    const faq = [
        {
            question: t('agreement:eloverblik.faq.1.question'),
            answer: t('agreement:eloverblik.faq.1.answer')
        },
        {
            question: t('agreement:eloverblik.faq.2.question'),
            answer: t('agreement:eloverblik.faq.2.answer')
        },
        {
            question: t('agreement:eloverblik.faq.3.question'),
            answer: t('agreement:eloverblik.faq.3.answer')
        },
        {
            question: t('agreement:eloverblik.faq.4.question'),
            answer: t('agreement:eloverblik.faq.4.answer')
        },
        {
            question: t('agreement:eloverblik.faq.5.question'),
            answer: t('agreement:eloverblik.faq.5.answer')
        },
        {
            question: t('agreement:eloverblik.faq.6.question'),
            answer: t('agreement:eloverblik.faq.6.answer')
        },
    ]
    
    return (
        <CenteredPageLayout maxWidth={700}>
        
            <Step currentStep={2} numberOfSteps={5} />
        
            <hr/>
        
            <FlexRow justify={"center"} direction={"column"}>
                <h1 className={"text-align-center"}>
                    {t('agreement:eloverblik.h1')}
                </h1>
            
                <p className={"text-align-center"} style={{paddingBottom: 40, fontSize: 26}}>
                    {t('agreement:eloverblik.explainer')}
                </p>
                
                <Button loading={isGettingAuthorizationUrl} disabled={isGettingAuthorizationUrl} onClick={giveAccess}>
                    {t('agreement:eloverblik.giveAccess')}
                </Button>
            </FlexRow>
            
    
            {agreement && (
                <>
                    <div className={styles.hr}>
                        <hr/>
                    </div>
                    
                    <h2>
                        {t('agreement:eloverblik.authorizations')}
                    </h2>
                    
                    {agreement?.eloverblikAuthorizations.map((authorization, index) => (
                        <div className={styles.authorizationItem}>
                            <div>
                                {t('agreement:eloverblik.access')} {index + 1}: {getTranslatedStatus(authorization.status)}
                            </div>
                            <div className={styles.details}>
                                {authorization.status === "Active" && (
                                    <>
                                        {t('validBetween')} {moment(authorization.validFrom).format("DD-MM-YYYY")} & {moment(authorization.validTo).format("DD-MM-YYYY")}
                                    </>
                                )}
                                {authorization.status === "Expired" && (
                                    <>
                                        {t('agreement:eloverblik.invalidExplainer')}
                                    </>
                                )}
                                {authorization.status === "Pending" && (
                                    <>
                                        <Button color={"link"} onClick={() => openEloverblik(authorization)}>{t('agreement:eloverblik.authorize')}</Button>
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
    
                    <FlexRow justify={"center"} className={styles.hr}>
                        <Button disabled={agreement?.eloverblikAuthorizations.filter(x => x.status === "Active").length === 0} onClick={gotoNext}>
                            {agreement?.eloverblikAuthorizations.filter(x => x.status === "Active").length === 0 ? t('agreement:eloverblik.nextDisabled') :  t('agreement:eloverblik.next')}
                        </Button>
                    </FlexRow>
                </>
            )}
    
            <div className={styles.hr}>
                <hr/>
            </div>
            
            <h2>
                {t('agreement:eloverblik.faq.title')}
            </h2>
            
            <div className={styles.accordion}>
                {faq.map((item, index) => (
                    <div key={index} className={`${styles.item} ${openItem === index && styles.open}`} onClick={() => setOpenItem(index)}>
                        <div className={styles.title}>
                            {item.question}
                        </div>
                        <div className={styles.content}>
                            {item.answer}
                        </div>
                    </div>
                ))}
            </div>
            
        </CenteredPageLayout>
    )
}

export default EloverBlikPage
