import styles from './PageHeader.module.scss';

interface IProps {
    text: string
}
const PageHeader = (props: IProps) => {
    
    const { text } = props;
    
    return (
        <div className={styles.container}>
            {text}
        </div>
    )
}

export default PageHeader
