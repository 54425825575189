import styles from './InvoicesPage.module.scss'
import {Trans, useTranslation} from "react-i18next";
import FlexRow from "../../../Components/FlexRow";
import {StyledText} from "../../../Components/V2/Text";
import Box from 'Components/V2/Box';
import AgreementSelector from "../../../Features/Dashboard/Components/AgreementSelector";
import useSelectedAgreement from "../../../Features/Dashboard/Hooks/useSelectedAgreement";
import Separator from "../../../Components/V2/Separator";
import React from "react";
import CircledSolarPanel from "../../../Assets/Icons/V2/CircledSolarPanel";
import {formatNumber} from "../../../Utils/NumberUtils";
import CircledLeaf from "../../../Assets/Icons/V2/CircledLeaf";
import Grid from "../../../Components/V2/Grid";
import {useAgreementEstimate} from "../../../Apis/AgreementApi";
import Gap from 'Components/V2/Gap';
import MatchUsageIcon from "../../../Assets/Icons/MatchUsageIcon";
import InternationalUsageIcon from "../../../Assets/Icons/InternationalIUsageIcon";
import CustomUsageIcon from "../../../Assets/Icons/CustomUsageIcon";
import CircledGraphIcon from "../../../Assets/Icons/V2/CircledGraphIcon";
import The0MissionIcon from "../../../Assets/Icons/The0MissionIcon";
import CircledPlane from "../../../Assets/Icons/V2/CircledPlane";

const AgreementDetailsPage = () => {

    const { t } = useTranslation();

    const agreement = useSelectedAgreement();

    const { data: agreementEstimate, isLoading } = useAgreementEstimate(agreement?.id);

    return (
        <Box className={styles.container}>
            <Gap direction={"vertical"} size={"medium"}>
                <Gap direction={"vertical"} size={"small"}>
                    <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('agreement')}</StyledText>
                    <AgreementSelector requireSelect position={"Under Left"} />
                </Gap>

                <Separator/>

                {!!agreement && (
                    <>
                        {!agreement.agreementType && (
                            <Gap direction={"vertical"} size={"medium"}>
                                <FlexRow direction={"column"} align={"start"} style={{gap: 12}}>
                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('noPackageSelected')}</StyledText>
                                </FlexRow>
                            </Gap>
                        )}

                        {agreement.agreementType && (
                            <>
                                <Gap direction={"vertical"} size={"medium"}>
                                    <FlexRow direction={"column"} align={"start"} style={{gap: 12}}>
                                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('selectedPackage')}</StyledText>
                                        {agreement.agreementType === 'Match' && (
                                            <>
                                                <FlexRow align={"start"} style={{gap: 8}}>
                                                    <MatchUsageIcon />
                                                    <FlexRow direction={"column"} align={"start"}>
                                                        <StyledText type={"Caption"} weight={"Bold"}>{t('agreementDetails.packageDescription.match.title')}</StyledText>
                                                        <StyledText type={"Caption"} style={{maxWidth: 250}}>{t('agreementDetails.packageDescription.match.description')}</StyledText>
                                                    </FlexRow>
                                                </FlexRow>
                                            </>
                                        )}
                                        {agreement.agreementType === 'Custom' && (
                                            <>
                                                <FlexRow align={"start"} style={{gap: 8}}>
                                                    <CustomUsageIcon />
                                                    <FlexRow direction={"column"} align={"start"}>
                                                        <StyledText type={"Caption"} weight={"Bold"}>{t('agreementDetails.packageDescription.custom.title')}</StyledText>
                                                        <StyledText type={"Caption"} style={{maxWidth: 250}}>{t('agreementDetails.packageDescription.custom.description')}</StyledText>
                                                    </FlexRow>
                                                </FlexRow>
                                            </>
                                        )}
                                        {agreement.agreementType === 'MatchAndCustom' && (
                                            <>
                                                <FlexRow align={"start"} style={{gap: 8}}>
                                                    <InternationalUsageIcon />
                                                    <FlexRow direction={"column"} align={"start"}>
                                                        <StyledText type={"Caption"} weight={"Bold"}>{t('agreementDetails.packageDescription.matchAndCustom.title')}</StyledText>
                                                        <StyledText type={"Caption"} style={{maxWidth: 400}}>{t('agreementDetails.packageDescription.matchAndCustom.description')}</StyledText>
                                                    </FlexRow>
                                                </FlexRow>
                                            </>
                                        )}
                                    </FlexRow>
                                </Gap>

                                <Separator />

                                <Gap direction={"vertical"} size={"medium"}>
                                    <FlexRow direction={"column"} align={"start"} style={{gap: 12}}>
                                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                                            {(agreement.agreementType === 'Match' || agreement.agreementType === 'MatchAndCustom') && (
                                                t('agreementDetails.estimatedUsage.match.title')
                                            )}
                                            {agreement.agreementType === 'Custom' && (
                                                t('agreementDetails.estimatedUsage.custom.title')
                                            )}
                                        </StyledText>

                                        <FlexRow align={"start"} style={{gap: 8}}>
                                            <CircledGraphIcon width={40} height={40} />
                                            <FlexRow direction={"column"} align={"start"}>
                                                <StyledText type={"Caption"} weight={"Bold"}>{isLoading ? <The0MissionIcon spin /> : <>{formatNumber(agreementEstimate?.estimatedKwh! / 1000, 0)} MWh</>}</StyledText>
                                                <StyledText type={"Caption"} style={{maxWidth: 400}}>
                                                    {(agreement.agreementType === 'Match' || agreement.agreementType === 'MatchAndCustom') && (
                                                        <Trans i18nKey="agreementDetails.estimatedUsage.match.description" values={{amount: agreementEstimate?.estimatedKwh! / 1000}} />
                                                    )}
                                                    {agreement.agreementType === 'Custom' && (
                                                        <Trans i18nKey="agreementDetails.estimatedUsage.custom.description" values={{amount: agreementEstimate?.estimatedKwh! / 1000}} />
                                                    )}
                                                </StyledText>
                                            </FlexRow>
                                        </FlexRow>
                                    </FlexRow>
                                </Gap>
                            </>
                        )}

                        <Separator />

                        <Gap size={"medium"} direction={"vertical"}>
                            <FlexRow justify={"space-between"}>
                                <FlexRow direction={"column"} align={"start"}>
                                    <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('overviewEstimate')}</StyledText>
                                </FlexRow>
                            </FlexRow>

                            <Grid gridTemplateColumns={"1fr 1fr 1fr"} gap={12} style={{width: '100%'}}>
                                <FlexRow justify={"center"} align={"start"} style={{gap: 12}}>
                                    <div>
                                        <CircledSolarPanel />
                                    </div>
                                    <div className={"flex column"}>
                                        <StyledText type={"Caption"} weight={"Bold"}>{isLoading ? <The0MissionIcon spin /> : <>{agreementEstimate?.solarPanelMeters! > 1000 ? formatNumber(agreementEstimate?.solarPanelMeters! / 1000, 1) : formatNumber(agreementEstimate?.solarPanelMeters, 1)} {agreementEstimate?.solarPanelMeters! > 1000 ? 'km' : 'm'} {t('solarpanels')}</>}</StyledText>
                                        <StyledText type={"Caption"} style={{maxWidth: 250}}>{t('onboarding.details.solarPanelDescription')}</StyledText>
                                    </div>
                                </FlexRow>

                                <FlexRow justify={"center"} align={"start"} style={{gap: 12}}>
                                    <div>
                                        <CircledLeaf />
                                    </div>
                                    <div className={"flex column"}>
                                        <StyledText type={"Caption"} weight={"Bold"}>{isLoading ? <The0MissionIcon spin /> : <>{t('reduces')} {formatNumber(agreementEstimate?.amountOfCO2InGrams! / 1000 / 1000, 2)} ton {t('yearly')}</>}</StyledText>
                                        <StyledText type={"Caption"} style={{maxWidth: 250}}>{t('onboarding.details.yearlyReductionDescription')}</StyledText>
                                    </div>
                                </FlexRow>

                                <FlexRow justify={"center"} align={"start"} style={{gap: 12}}>
                                    <div>
                                        <CircledPlane />
                                    </div>
                                    <div className={"flex column"}>
                                        <StyledText type={"Caption"} weight={"Bold"}>{isLoading ? <The0MissionIcon spin /> : <>{t('comparesTo')} {formatNumber(agreementEstimate?.numberOfFlights)} {t('flightsToParis')}</>}</StyledText>
                                        <StyledText type={"Caption"} style={{maxWidth: 250}}><Trans i18nKey="onboarding.details.yearlyCostDescription" values={{noOfFlights: formatNumber(agreementEstimate?.numberOfFlights)}} /></StyledText>
                                    </div>
                                </FlexRow>
                            </Grid>
                        </Gap>
                    </>
                )}
            </Gap>
        </Box>
    )

}

export default AgreementDetailsPage