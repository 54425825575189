import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import { ISocialMediaPostDto } from "Apis/Models/SocialMedia";

export const SOCIAL_MEDIA_POSTS_CACHE_KEY = 'SOCIAL_MEDIA_POSTS_CACHE_KEY';
export const useSocialMediaPosts = (agreementId: string) => {
    return useQuery<ISocialMediaPostDto[]>(SOCIAL_MEDIA_POSTS_CACHE_KEY, async () => {
        return await request<ISocialMediaPostDto[]>({
            url: `/socialmedia/${agreementId}`,
            method: 'GET',
        })
    });
}
