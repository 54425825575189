import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {FileDto} from "../../../Apis/Models/FileDto";
import FlexRow from "../../../Components/FlexRow";
import PageHeader from "../../../Components/PageHeader";
import Button from "../../../Components/Button";
import styles from "./AnnualReportsPage.module.scss";
import CloseIcon from "../../../Assets/Icons/CloseIcon";
import Modal from "../../../Components/Modal";
import Space from "../../../Components/Space";
import Input from "../../../Components/Input";
import FileUploadButton from "../../../Components/FileUploadButton";
import {
    useAnnualReports,
    useDeleteAnnualReport,
    useSaveAnnualReport
} from "../../../Apis/Admin/SolarParkAnnualReportApi";
import {ISolarParkAnnualReportDto} from "../../../Apis/Models/ISolarParkAnnualReportDto";
import { saveAs } from 'file-saver';
import List from "../../../Components/List";
import {useSolarParks} from "../../../Apis/Admin/SolarParkAdminApi";

const AnnualReportsPage = () => {

    const { t } = useTranslation();

    const { data: annualReports, isLoading: isLoadingReports } = useAnnualReports();
    const { data: solarParks } = useSolarParks();
    const { mutateAsync: saveAnnualReport, isLoading: isSavingReport } = useSaveAnnualReport();
    const { mutateAsync: deleteAnnualReportMutation, isLoading: isDeletingReport } = useDeleteAnnualReport();

    const [createReportModalVisible, setCreateReportModalVisible] = useState<boolean>(false);
    const [deleteReportModalVisible, setDeleteReportModalVisible] = useState<boolean>(false);

    const { handleSubmit, register, control, reset } = useForm<ISolarParkAnnualReportDto>();
    const [file, setFile] = useState<FileDto | null>(null);
    const [reportToDelete, setReportToDelete] = useState<ISolarParkAnnualReportDto>();

    const saveReport = async (data: ISolarParkAnnualReportDto) => {
        if (!file) {
            return;
        }

        await saveAnnualReport({ ...data, file: file });

        closeModal();
    }

    const closeModal = () => {
        setCreateReportModalVisible(false);
        setDeleteReportModalVisible(false);
        reset({
            id: '',
            name: '',
        });
        setFile(null)
        setReportToDelete(undefined);
    }

    const removeFile = () => {
        setFile(null);
    }

    const showDeleteReportModal = (report: ISolarParkAnnualReportDto) => {
        setReportToDelete(report);
        setDeleteReportModalVisible(true);
    }

    const deleteReport = async () => {
        await deleteAnnualReportMutation(reportToDelete!);

        closeModal();
    }

    const downloadReport = async (report: ISolarParkAnnualReportDto) => {
        const response = await fetch(report.file.url);

        const blob = await response.blob();

        saveAs(blob, report.file.fileName);
    }

    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('annualReport:title')} />

                <Button color={"link"} onClick={() => setCreateReportModalVisible(true)}>+ {t('annualReport:createAnnualReport')}</Button>
            </FlexRow>

            <List data={annualReports} loading={isLoadingReports} columns={[
                {
                    dataIndex: 'name',
                    title: t('annualReport:name'),
                },
                {
                    dataIndex: 'solarPark',
                    title: t('solarPark'),
                    textRender: (value, record) => record.solarPark?.name
                },
                {
                    title: t('solarPark:functions'),
                    customRender: (_, report: ISolarParkAnnualReportDto) => (
                        <Space direction={"row"} size={"large"}>
                            <Button color={"link"} onClick={() => downloadReport(report)}>{t('annualReport:downloadReport')}</Button>
                            <Button color={"link"} onClick={() => showDeleteReportModal(report)}>{t('annualReport:deleteAnnualReport')}</Button>
                        </Space>
                    )
                },
            ]} emptyText={t('annualReport:noReportsFound')} keyDataIndex={"id"}
            />

            <Modal closeOnOutsideClick title={t('annualReport:createAnnualReport')} visible={createReportModalVisible} onCancel={closeModal}>
                <form onSubmit={handleSubmit(saveReport)}>
                    <input type={"hidden"} {...register('id')} />

                    <Space direction={"column"} size={"large"} >
                        <div className={"w-100"}>
                            <Input name={"name"} control={control} placeholder={t('logo:name')} label={t('logo:name')} />
                        </div>

                        <div className={"w-100"}>
                            <select className={styles.select} {...register('solarParkId')}>
                                {solarParks?.map(x => (
                                    <option value={x.id}>{x.name}</option>
                                ))}
                            </select>
                        </div>

                        <FlexRow>
                            {!file && (<FlexRow style={{padding: 40}} justify={"center"}><FileUploadButton callback={setFile} /></FlexRow>)}
                            {!!file && (
                                <FlexRow justify={"space-between"}>
                                    <div onClick={removeFile} className={styles.remove}>
                                        <CloseIcon fill={"red"} />
                                    </div>
                                </FlexRow>
                            )}
                        </FlexRow>

                        <FlexRow justify={"center"}>
                            <Button disabled={!file} loading={isSavingReport} type={"submit"}>{t('save')}</Button>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>

            <Modal closeOnOutsideClick title={t('annualReport:deleteAnnualReportHeader')} visible={deleteReportModalVisible} onCancel={closeModal}>
                <Space direction={"column"} size={"large"}>
                    <div className={"w-100"}>
                        {t('annualReport:deleteAnnualReportText')}
                    </div>

                    <FlexRow justify={"center"}>
                        <p>
                            {reportToDelete?.name}
                        </p>
                    </FlexRow>

                    <FlexRow justify={"center"}>
                        <Button disabled={isDeletingReport} loading={isDeletingReport} onClick={deleteReport}>{t('delete')}</Button>
                    </FlexRow>
                </Space>
            </Modal>
        </>
    )

}

export default AnnualReportsPage