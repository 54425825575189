import AgreementTypeBox from "./AgreementTypeBox";
import CircledDashedIcon from "../../../Assets/Icons/CircledDashedIcon";
import PieChartIconV2 from "../../../Assets/Icons/PieChartIconV2";
import {useTranslation} from "react-i18next";
import styles from "./AgreementTypeSelector.module.scss";
import { StyledText } from "Components/V2/Text";
import { Link } from "react-router-dom";

interface Props {
    onSelect: (agreementType: 'Match' | 'Custom') => void;
    next: () => void;
}
const AgreementTypeSelector = (props: Props) => {

    const { t } = useTranslation();
    
    return (
        <div className={styles.container}>
            <div className={styles.grid}>
                <AgreementTypeBox
                    showTopPick
                    icon={<CircledDashedIcon />}
                    headline={t('onboarding.matchUsage')}
                    subheadline={t('onboarding.matchUsageSubheadline')}
                    explainerHeadline={t('onboarding.matchUsageExplainerHeadline')}
                    explainerItems={[
                        t('onboarding.matchUsageExplainerItem1'),
                        t('onboarding.matchUsageExplainerItem2'),
                    ]}
                    onClick={() => {
                        props.onSelect('Match');
                        props.next();
                    }}
                />

                <AgreementTypeBox
                    icon={<PieChartIconV2 />}
                    headline={t('onboarding.customUsage')}
                    subheadline={t('onboarding.customUsageSubheadline')}
                    explainerHeadline={t('onboarding.customUsageExplainerHeadline')}
                    explainerItems={[
                        t('onboarding.customUsageExplainerItem1'),
                    ]}
                    onClick={() => {
                        props.onSelect('Custom');
                        props.next();
                    }}
                />
            </div>

            <Link to={"international"}>
                <StyledText type={"Body"} color={"secondary"}>
                    {t('onboarding.matchInternationalQuestion')}
                </StyledText>
            </Link>
        </div>
    )

}

export default AgreementTypeSelector