import {SVGProps} from "react";


const AgreementIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 5C8.05228 5 9 5.94772 9 9C9 10.3909 8.37031 11.9634 7.12466 13.7456L6.98 13.947L7.13076 14.1674L7.3 14.4L7.66262 14.8759L7.9628 15.249L8.13297 15.4438L8.2825 15.5975C8.60951 15.91 8.79112 15.9028 8.98974 15.6832L9.07638 15.5763L9.16795 15.4453L11.1679 12.4453C11.682 11.6743 12.8775 11.9566 12.9923 12.8761C13.2489 14.9309 13.8424 15.854 14.763 15.9839L14.865 15.994L14.9746 15.9291L15.1361 15.8218L15.4483 15.5962L16.6602 14.6568L16.8637 14.5049C17.3803 14.1277 17.5981 14 18 14C18.7485 14 19.6666 14.5409 20.9786 15.6527L21.4309 16.0445C21.5088 16.1133 21.588 16.1839 21.6685 16.2563C22.0792 16.6255 22.1129 17.2577 21.7437 17.6685C21.3745 18.0792 20.7423 18.1129 20.3315 17.7437L19.8773 17.3435C19.6593 17.1556 19.4553 16.9863 19.2656 16.8356L18.905 16.5589C18.7913 16.475 18.6839 16.3994 18.583 16.3321L18.37 16.1967L18.127 16.06L17.7624 16.3318L16.7105 17.151L16.3936 17.3885C16.3448 17.4242 16.2977 17.4581 16.2524 17.4901L15.9983 17.6614C15.6029 17.913 15.334 18 15 18C13.4124 18 12.287 17.141 11.6193 15.5682L11.57 15.447L10.8321 16.5547C9.40282 18.6985 7.71802 18.2024 5.84441 15.7893L5.687 15.582L5.49775 15.8021C5.051 16.3128 4.56194 16.8386 4.03082 17.3802L3.70711 17.7071C3.31658 18.0976 2.68342 18.0976 2.29289 17.7071C1.90237 17.3166 1.90237 16.6834 2.29289 16.2929C3.10825 15.4775 3.81626 14.7077 4.41802 13.9857L4.559 13.814L4.45793 13.6286C3.69264 12.1921 3.10541 10.5894 3.01239 9.29028L3.00014 9.01671C2.96216 6.74412 4.2567 5 6 5ZM6 7C5.51662 7 4.97883 7.72457 4.99986 8.9834C5.01204 9.71678 5.30981 10.705 5.7467 11.6975L5.884 11.999L5.90569 11.9655C6.58886 10.8735 6.95005 9.93517 6.99517 9.16293L7 9L6.99716 8.43696L6.9902 8.12741L6.97702 7.86581C6.92916 7.18872 6.78432 7.04048 6.3538 7.00857L6.18968 7.00147L6 7Z" fill="#616161"/>
        </svg>

    )
}

export default AgreementIcon