import * as React from "react"
import { SVGProps } from "react"

const CaretDownIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="14"
        height="10"
        fill="none"
        viewBox="0 0 14 10"
        xmlns="http://www.w3.org/2000/svg"
        style={{transform: props.direction === 'ASCENDING' ? 'rotate(180deg)' : ''}}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.303 1.271a1 1 0 0 1 1.414.02L7 6.73l5.283-5.438a1 1 0 0 1 1.434 1.393l-6 6.177a1 1 0 0 1-1.434 0l-6-6.177a1 1 0 0 1 .02-1.414Z"
            fill="#153629"
        />
    </svg>
)

export default CaretDownIcon
