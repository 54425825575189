import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "Pages/Auth/LoginPage";
import SignupPage from "Pages/Auth/SignupPage";
import AuthActionsPage from "Pages/Auth/AuthActionsPage";
import ResetPasswordPage from "Pages/Auth/ResetPasswordPage";
import VerifyEmailPage from "Pages/Auth/VerifyEmailPage";
import RedirectIfLoggedIn from "Components/RedirectIfLoggedIn";

const AuthRoutes = () => {
    
    return (
        <Routes>
            <Route path={`login`} element={
                <RedirectIfLoggedIn>
                    <LoginPage />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={`signup`} element={
                <RedirectIfLoggedIn>
                    <SignupPage />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={`verifyEmail`} element={
                <VerifyEmailPage />
            } />
            
            <Route path={`action`} element={
                <AuthActionsPage />
            } />
    
            <Route path={`resetPassword`} element={
                <ResetPasswordPage />
            } />

            <Route path={"*"} element={<Navigate to={'/auth/login'} />} />
        </Routes>
    )
}

export default AuthRoutes
