import * as React from "react"
import { SVGProps } from "react"
const MatchUsageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={40} height={40} viewBox={"0 0 40 40"}{...props}>
        <path
            fill="#B386CF"
            fillRule="evenodd"
            d="M26.373 4.611a16.667 16.667 0 0 0-6.37-1.278 1.667 1.667 0 0 0-.006 3.334c1.75.003 3.48.35 5.096 1.022a1.667 1.667 0 0 0 1.28-3.078Zm-10.567.902a1.667 1.667 0 0 0-2.177-.903A16.666 16.666 0 0 0 8.22 8.221a1.667 1.667 0 1 0 2.357 2.358 13.333 13.333 0 0 1 4.326-2.89 1.667 1.667 0 0 0 .902-2.176ZM6.79 12.728a1.667 1.667 0 0 0-2.18.899 16.667 16.667 0 0 0-1.277 6.37 1.667 1.667 0 1 0 3.333.006c.003-1.75.35-3.481 1.023-5.096a1.667 1.667 0 0 0-.9-2.18ZM5.512 24.193a1.667 1.667 0 0 1 2.178.903 13.334 13.334 0 0 0 2.888 4.326 1.667 1.667 0 0 1-2.357 2.356 16.667 16.667 0 0 1-3.611-5.407 1.667 1.667 0 0 1 .902-2.178Zm9.394 8.118a1.667 1.667 0 1 0-1.28 3.078c2.02.84 4.184 1.274 6.37 1.278a1.667 1.667 0 1 0 .007-3.334 13.333 13.333 0 0 1-5.097-1.022Zm14.516-2.89a1.667 1.667 0 1 1 2.356 2.358 16.666 16.666 0 0 1-5.407 3.61 1.667 1.667 0 1 1-1.275-3.079 13.332 13.332 0 0 0 4.326-2.889Zm7.244-9.418a1.667 1.667 0 1 0-3.333-.006 13.332 13.332 0 0 1-1.022 5.096 1.667 1.667 0 1 0 3.078 1.28 16.667 16.667 0 0 0 1.277-6.37ZM29.422 8.221a1.667 1.667 0 0 1 2.357 0 16.665 16.665 0 0 1 3.61 5.408 1.667 1.667 0 1 1-3.08 1.275 13.331 13.331 0 0 0-2.888-4.326 1.667 1.667 0 0 1 0-2.357Z"
            clipRule="evenodd"
        />
    </svg>
)
export default MatchUsageIcon
