import { useTranslation } from "react-i18next";
import styles from './SocialMediaPage.module.scss';
import Back from "Components/Back";
import { AgreementModel } from "Apis/Models/AgreementModel";
import FlexRow from "Components/FlexRow";
import SocialMediaPost from "Components/SocialMediaPost";
import { useSocialMediaPosts } from "Apis/SocialMediaPostApi";

interface IProps {
    agreement: AgreementModel;
}
const SocialMediaPage = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { data } = useSocialMediaPosts(props.agreement.id);
    
    return (
        <div>
            <Back text={t('agreement:socialMedia.back')} />
    
            {data?.length === 0 && (<FlexRow className={"h-100"} justify={"center"}>{t('socialMedia:noPostsFound')}</FlexRow>)}
    
            <div className={styles.grid}>
                {data?.map((post) => <SocialMediaPost agreement={props.agreement} post={post} />)}
            </div>
        </div>
    )
}

export default SocialMediaPage
