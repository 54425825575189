import {useAgreements, useDeleteAgreement, useSaveAgreement} from "Apis/Admin/AgreementAdminApi";
import List from "Components/List";
import {Trans, useTranslation} from "react-i18next";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import Modal from "Components/Modal/Modal";
import React, { useState } from "react";
import PageHeader from "Components/PageHeader";
import Space from "Components/Space";
import Input from "Components/Input";
import { useForm } from "react-hook-form";
import { AgreementModel, AgreementType } from "Apis/Models/AgreementModel";
import { useNavigate } from "react-router-dom";

const ListAgreementsPage = () => {

    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const { data, isLoading } = useAgreements();
    
    const [createAgreementModalVisible, setCreateAgreementModalVisible] = useState<boolean>(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [selectedAgreement, setSelectedAgreement] = useState<AgreementModel>();

    const { mutateAsync: createAgreementMutation, isLoading: isCreatingNewAgreement } = useSaveAgreement();
    const { mutateAsync: deleteAgreementMutation, isLoading: isDeletingAgreement } = useDeleteAgreement();

    const { control, handleSubmit, formState: { errors } } = useForm<AgreementModel>();
    
    const createNewAgreement = async (data: AgreementModel) => {
        const response = await createAgreementMutation(data);
        
        navigate(`/admin/agreements/${response.id}`)
    }
    
    const getAgreementTypeText = (agreement: AgreementType) => {
        switch (agreement) {
            case "Custom":
                return t('agreement:custom');
            case "Match":
                return t('agreement:match');
            case "MatchAndCustom":
                return t('agreement:matchAndCustom');
        }
    }
    
    const getAgreementStatus = (agreement: AgreementModel) => {
        if (!agreement.agreementSignedAt) {
            return t('agreement:notSigned');
        }
        
        return t('agreement:signed');
    }

    const selectAgreementForDeletion = (agreement: AgreementModel) => {
        setSelectedAgreement(agreement);
        setDeleteModalVisible(true)
    }

    const cancelDelete = () => {
        setDeleteModalVisible(false);
        setSelectedAgreement(undefined);
    }

    const deleteAgreement = async () => {
        if (!selectedAgreement) {
            return
        }

        await deleteAgreementMutation(selectedAgreement);

        setSelectedAgreement(undefined);

        setDeleteModalVisible(false);
    }
    
    
    return (
        <>
            <FlexRow justify={"space-between"} align={"center"} style={{paddingBottom: 30}}>
                <PageHeader text={t('agreement:title')} />
                
                <Button color={"link"} onClick={() => setCreateAgreementModalVisible(true)}>+ {t('agreement:createNewAgreement')}</Button>
            </FlexRow>
            
            <List keyDataIndex={"id"} loading={isLoading} data={data} emptyText={t('terms:noAgreementsFound')}  columns={[
                {
                    dataIndex: "companyName",
                    title: t('agreement:company'),
                },
                {
                    dataIndex: "nickname",
                    title: t('nickname'),
                },
                {
                    dataIndex: "vatNumber",
                    title: t('agreement:vatNumber'),
                },
                {
                    dataIndex: "agreementType",
                    title: t('agreement:type'),
                    textRender: (text) => getAgreementTypeText(text),
                },
                {
                    dataIndex: "id",
                    title: t('agreement:status.title'),
                    textRender: (_, record) => getAgreementStatus(record),
                },
                {
                    dataIndex: "id",
                    title: t('agreement:functions'),
                    customRender: (_, record) => (
                        <Space direction={"row"} size={"large"}>
                            <Button color={"link"} onClick={() => navigate(`/admin/agreements/${record.id}`)}>{t('agreement:goto')}</Button>
                            <Button color={"link"} onClick={() => selectAgreementForDeletion(record)}>{t('agreement:delete')}</Button>
                        </Space>
                    )
                },
            ]} />
            
            <Modal title={t('agreement:createNewAgreement')} visible={createAgreementModalVisible} onCancel={() => setCreateAgreementModalVisible(false)}>
                <form onSubmit={handleSubmit(createNewAgreement)}>
                    <Space>
                        <div className={"w-100"}>
                            <Input name={"companyName"} control={control} label={t('companyName')} rules={{required: true}} errors={errors.companyName}/>
                        </div>

                        <div className={"w-100"}>
                            <Input name={"nickname"} control={control} label={t('nickname')} errors={errors.nickname}/>
                        </div>

                        <div className={"w-100"}>
                            <Input name={"vatNumber"} control={control} label={t('vatNumber')} rules={{required: true}}
                                   errors={errors.vatNumber}/>
                        </div>

                        <FlexRow justify={"center"}>
                            <Button disabled={isCreatingNewAgreement} type={"submit"}
                                    loading={isCreatingNewAgreement}>{t('create')}</Button>
                        </FlexRow>
                    </Space>
                </form>
            </Modal>

            <Modal title={t('agreement:deleteAgreement')} visible={deleteModalVisible} onCancel={() => cancelDelete()}>
                <Space>
                    <div className={"w-100"}>
                        <Trans i18nKey="agreement:deleteAgreementConfirmation" values={{company: selectedAgreement?.companyName, vatNumber: selectedAgreement?.vatNumber}} />
                    </div>

                    <FlexRow justify={"end"}>
                        <Button color={"danger"}  disabled={isDeletingAgreement} onClick={deleteAgreement} loading={isDeletingAgreement}>{t('delete')}</Button>
                    </FlexRow>
                </Space>
            </Modal>
        </>
    )
}

export default ListAgreementsPage
