import {request} from "./BaseApi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {AcceptedTermsModel, AgreementInfoModel, AgreementModel, AgreementType} from "Apis/Models/AgreementModel";
import { AgreementTermsModel } from "Apis/Models/AgreementTermsModel";
import { SignContractModel } from "Apis/Models/SignContractModel";
import { AgreementEstimateModel } from "Apis/Models/AgreementEstimateModel";
import { FileDto } from "Apis/Models/FileDto";
import { saveAs } from 'file-saver';
import {InvoiceModel} from "./Models/InvoiceModel";
import {ISendAgreementDto} from "./Models/SendAgreementDto";
import { MutateAgreementInfoModel } from "./Models/MutateAgreementInfoModel";

export const useCreateAgreement = () => {
    return useMutation(async (data: {agreementType: AgreementType}) => {
        return await request<AgreementModel>({
            url: `/agreement`,
            method: 'POST',
            data
        })
    });
}

export const AGREEMENTS_CACHE_KEY = 'AGREEMENTS_CACHE_KEY';
export const useAgreements = (onlySigned: boolean = false) => {
    return useQuery<AgreementModel[]>([AGREEMENTS_CACHE_KEY, onlySigned], async () => {
        return await request<AgreementModel[]>({
            url: `/agreement?onlySigned=${onlySigned}`,
            method: 'GET',
        })
    });
}

export const AGREEMENT_CACHE_KEY = 'AGREEMENT_CACHE_KEY';
export const useAgreement = (agreementId: string | undefined) => {
    
    return useQuery<AgreementModel, any>([AGREEMENT_CACHE_KEY, agreementId], async () => {
        return await request<AgreementModel>({
            url: `/agreement/${agreementId}`,
            method: 'GET',
        })
    }, {
        refetchOnWindowFocus: false,
        enabled: !!agreementId
    });
}

export const AGREEMEN_LOGO_CACHE_KEY = 'AGREEMEN_LOGO_CACHE_KEY';
export const useAgreementLogo = (agreementId: string | undefined) => {
    
    return useQuery<FileDto, any>([AGREEMEN_LOGO_CACHE_KEY, agreementId], async () => {
        return await request<FileDto>({
            url: `/agreement/${agreementId}/logo`,
            method: 'GET',
        })
    }, {
        refetchOnWindowFocus: false,
        enabled: !!agreementId
    });
}

export const AGREEMENT_ESTIMATE_CACHE_KEY = 'AGREEMENT_ESTIMATE_CACHE_KEY';
export const useAgreementEstimate = (agreementId: string | undefined) => {
    
    return useQuery<AgreementEstimateModel>([AGREEMENT_ESTIMATE_CACHE_KEY, agreementId], async () => {
        return await request<AgreementEstimateModel>({
            url: `/agreement/${agreementId}/estimate`,
            method: 'GET',
        })
    }, {
        refetchOnWindowFocus: false,
        enabled: !!agreementId
    });
}

export const useAddAgreementLogo = (agreement: AgreementModel) => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (data: { fileId: string }) => {
        return await request<AgreementModel>({
            url: `/agreement/${agreement.id}/logo`,
            method: 'PUT',
            data
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(AGREEMEN_LOGO_CACHE_KEY);
        }
    })
}


export const useAddAgreementInfo = (agreement: AgreementModel) => {
    return useMutation(async (data: AgreementInfoModel) => {
        return await request<AgreementModel>({
            url: `/agreement/${agreement.id}/info`,
            method: 'PUT',
            data
        })
    });
}

const AGREEMENT_INVOICES_CACHE_KEY = 'AGREEMENT_INVOICES_CACHE_KEY';
export const useAgreementInvoices = (agreement: AgreementModel) => {
    return useQuery<InvoiceModel[]>([AGREEMENT_INVOICES_CACHE_KEY, agreement.id], async () => {
        return await request<InvoiceModel[]>({
            url: `/agreement/${agreement.id}/invoice`,
            method: 'GET'
        })
    });
}

export const getInvoicePdf = async (agreement: AgreementModel, invoice: InvoiceModel): Promise<any> => {

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/agreement/${agreement.id}/invoice/${invoice.id}/download`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, `The 0-Mission faktura ${invoice.invoiceNumber}.pdf`);
}


export const useSignContract = () => {
    return useMutation(async (model: SignContractModel) => {
        return await request<AgreementModel>({
            url: `/agreement/${model.agreementId}/sign`,
            method: 'PUT',
            data: model
        })
    });
}

export const useSendContract = () => {
    return useMutation(async (model: ISendAgreementDto) => {
        return await request({
            url: `/agreement/${model.agreementId}/sendAgreement`,
            method: 'POST',
            data: model
        })
    });
}


export const CURRENT_AGREEMENT_TERMS_CACHE_KEY = 'CURRENT_AGREEMENT_TERMS_CACHE_KEY';
export const useCurrentAgreementTerms = (agreementId) => {
    
    return useQuery<AgreementTermsModel>([CURRENT_AGREEMENT_TERMS_CACHE_KEY, agreementId], async () => {
        return await request<AgreementTermsModel>({
            url: `/agreementTerms/current/${agreementId}`,
            method: 'GET',
        })
    }, {
        refetchOnWindowFocus: false
    });
}

export const useUpdateAgreement = () => {
    const queryClient = useQueryClient();
    
    return useMutation(async (agreement: AgreementModel): Promise<AgreementModel> => {
        return await request<AgreementModel>({
            url: `/agreement/${agreement.id}`,
            method: 'PUT',
            data: agreement
        })
        
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(AGREEMENT_CACHE_KEY);
            queryClient.invalidateQueries(AGREEMENTS_CACHE_KEY);
        }
    })
}

export const useUpdateAgreementV2 = () => {
    const queryClient = useQueryClient();

    return useMutation(async (agreement: MutateAgreementInfoModel): Promise<AgreementModel> => {
            return await request<AgreementModel>({
                url: `/agreement/${agreement.id}/v2`,
                method: 'PUT',
                data: agreement
            })

        },
        {
            onError: (error) => { /** do nothing */
            },
            onSuccess: () => {
                queryClient.invalidateQueries(AGREEMENT_CACHE_KEY);
                queryClient.invalidateQueries(AGREEMENTS_CACHE_KEY);
            }
        })
}

export const getAcceptedTermsPdf = async (agreement: AgreementModel, acceptedTerms: AcceptedTermsModel): Promise<any> => {

    try {
        const responseBlob = await request<Blob>({
            responseType: 'blob',
            url: `/agreement/${agreement.id}/acceptedTerms/${acceptedTerms.id}/pdf`,
            method: 'GET',
        });

        const blob = new Blob([responseBlob], {type: 'application/pdf' });

        saveAs(blob, "The 0-Mission Kontrakt.pdf");
    }
    catch (e) {
        return e;
    }
}

export const getContractPdf = async (agreement: AgreementModel): Promise<any> => {

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/agreement/${agreement.id}/contract`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, "The 0-Mission Kontrakt.pdf");
}

export const useDeleteAgreement = () => {
    const queryClient = useQueryClient();

    return useMutation(async (agreement: AgreementModel) => {
        return await request({
            url: `/agreement/${agreement.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(AGREEMENT_CACHE_KEY);
            queryClient.invalidateQueries(AGREEMENTS_CACHE_KEY);
        }
    })
}
