import { Navigate, Route, Routes } from "react-router-dom";
import ListAgreementsPage from "Pages/Admin/Agreements/ListAgreementsPage";
import ShowAgreementPage from "Pages/Admin/Agreements/ShowAgreementPage";

const AgreementRoutes = () => {
    
    return (
        <Routes>
            <Route path={`/`} element={
                <ListAgreementsPage />
            }/>
    
            <Route path={`/:agreementId`} element={
                <ShowAgreementPage />
            }/>
            
            <Route path={"*"} element={<Navigate to={"/"}/>}/>
        </Routes>
    )
}

export default AgreementRoutes
