import DefaultPageLayout from "../../../Layouts/V2/DefaultPageLayout";
import Gap from "../../../Components/V2/Gap";
import Button from "../../../Components/V2/Button";
import {useTranslation} from "react-i18next";
import Box from "../../../Components/V2/Box";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import {
    useAgreement,
    useCurrentAgreementTerms,
    useSendContract,
    useSignContract
} from "../../../Apis/AgreementApi";
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import styles from "./AgreementReviewPage.module.scss";
import {ControlledInput} from "../../../Components/V2/Input";
import {StyledText} from "../../../Components/V2/Text";
import useToast from "../../../Hooks/useToast";

const AgreementReviewPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { showSuccessToast } = useToast();

    const { agreementId } = useParams();
    const { data: agreement } = useAgreement(agreementId!);
    const { data: agreementTerms } = useCurrentAgreementTerms(agreementId);
    const { mutateAsync: signContractMutation, isLoading } = useSignContract();
    const { mutateAsync: sendContractMutation, isLoading: isSendingContract } = useSendContract();

    const { control, handleSubmit, reset: resetSendEmailForm } = useForm<{email: string}>()
    const [showSendEmailField, setShowSendEmailField] = useState<boolean>(false);

    const sendEmail = async (data) => {
        await sendContractMutation({
            receiverEmail: data.email,
            agreementId: agreementId!
        })

        setShowSendEmailField(false);

        showSuccessToast(t('agreement:agreementSent'));

        resetSendEmailForm()
    }

    const signContract = async () => {
        await signContractMutation({agreementId: agreement!.id, agreementTermsId: agreementTerms!.id});

        navigate(`/v2/dashboard/${agreementId}`);
    }

    if (!!agreement && agreement.agreementStatus !== "Pending") {
        return <Navigate to={`/v2/dashboard/${agreement.id}`}  />
    }
    
    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.content}>
                
                <div className={styles.headline}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('agreement:agreementReview')}
                    </StyledText>
                </div>
                
                <Box>
                    {!!agreement && !!agreementTerms && (
                        <div className={styles.agreementArea}>
                            {agreementTerms?.text}
                        </div>
                    )}

                    <form onSubmit={handleSubmit(sendEmail)}>
                        <Gap size={"small"} className={"flex space-between"} style={{height: 48}}>
                            <div style={{flex: 1}}>
                                {showSendEmailField && (
                                    <ControlledInput placeholder={t('enterTheEmail')} name={"email"} control={control} />
                                )}
                            </div>

                            {showSendEmailField ? (
                                <Button outline text={t('send')} state={isSendingContract ? "Loading" : "None"} type={"submit"} />
                            ) : (
                                <Button outline text={t('receiveOnMail')} type={"button"} onClick={(e) => {
                                    e.preventDefault();
                                    setShowSendEmailField(true)
                                }} />
                            )}

                            <Button outline text={t('back')} />
                            <Button text={t('sign')} type={"button"} onClick={signContract} state={isLoading ? "Loading" : "None"} />
                        </Gap>
                    </form>
                </Box>
            </div>
        </DefaultPageLayout>
    )

}

export default AgreementReviewPage