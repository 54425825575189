import { Navigate, Route, Routes } from "react-router-dom";
import InvoicesPage from "./InvoicesPage";
import InvoiceablePage from "./InvoiceablePage";

const InvoicesRoutes = () => {
    
    return (
        <Routes>
            <Route path={`invoiceable`} element={
                <InvoiceablePage />
            }/>

            <Route path={`/`} element={
                <InvoicesPage />
            }/>


            <Route path={"*"} element={<Navigate to={"/"}/>}/>
        </Routes>
    )
}

export default InvoicesRoutes
