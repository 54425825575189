const CircledDollar = () => {

    return (
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.333008" width="48" height="48" rx="24" fill="#FFFFBC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.333 24C14.333 18.4772 18.8102 14 24.333 14C29.8559 14 34.333 18.4772 34.333 24C34.333 29.5228 29.8559 34 24.333 34C18.8102 34 14.333 29.5228 14.333 24ZM32.333 24C32.333 19.5817 28.7513 16 24.333 16C19.9147 16 16.333 19.5817 16.333 24C16.333 28.4183 19.9147 32 24.333 32C28.7513 32 32.333 28.4183 32.333 24ZM25.4941 19.0003C26.5279 19.0325 27.4782 19.5966 27.9994 20.5006C28.2752 20.979 28.1109 21.5905 27.6324 21.8664C27.1881 22.1225 26.6292 21.9991 26.3307 21.5971L26.2667 21.4994C26.1026 21.2148 25.8109 21.0313 25.4651 21.0032L25.333 21H24.333H23.333C22.7807 21 22.333 21.4477 22.333 22C22.333 22.5128 22.719 22.9355 23.2164 22.9933L23.333 23H25.333C26.9899 23 28.333 24.3431 28.333 26C28.333 27.5977 27.0841 28.9037 25.5093 28.9949L25.333 29V30C25.333 30.5523 24.8853 31 24.333 31C23.8202 31 23.3975 30.614 23.3397 30.1166L23.333 30V29.0004C22.2376 29.025 21.2147 28.4502 20.6667 27.4994C20.3908 27.021 20.5551 26.4095 21.0336 26.1336C21.4779 25.8775 22.0369 26.0009 22.3353 26.4029L22.3994 26.5006C22.5634 26.7852 22.8551 26.9687 23.2009 26.9968L23.333 27H24.333H25.333C25.8853 27 26.333 26.5523 26.333 26C26.333 25.4872 25.947 25.0645 25.4496 25.0067L25.333 25H23.333C21.6762 25 20.333 23.6569 20.333 22C20.333 20.4023 21.5819 19.0963 23.1567 19.0051L23.333 19V18C23.333 17.4477 23.7807 17 24.333 17C24.8458 17 25.2685 17.386 25.3263 17.8834L25.333 18V19.0003L25.4941 19.0003Z" fill="#445E54"/>
        </svg>

    )

}

export default CircledDollar