const CircledX = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20ZM13.2977 9.28414C13.6898 8.89522 14.323 8.89782 14.7119 9.28994L14.7177 9.29574C15.1066 9.68786 15.104 10.321 14.7119 10.7099L13.4119 11.9999L14.7119 13.2899L14.7177 13.2957C15.1066 13.6879 15.104 14.321 14.7119 14.7099L14.7061 14.7157C14.314 15.1047 13.6808 15.1021 13.2919 14.7099L12.0019 13.4099L10.7119 14.7099L10.7061 14.7157C10.314 15.1047 9.68081 15.1021 9.29189 14.7099L9.28609 14.7041C8.89717 14.312 8.89977 13.6789 9.29189 13.2899L10.5919 11.9999L9.29189 10.7099C8.89977 10.3178 8.89977 9.68206 9.29189 9.28994C9.68401 8.89782 10.3198 8.89782 10.7119 9.28994L12.0019 10.5899L13.2919 9.28994L13.2977 9.28414Z" fill="#FB5151"/>
        </svg>
    )

}

export default CircledX