import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import { ILogoDto } from "Apis/Models/LogoDto";

export const LOGO_CACHE_KEY = 'LOGO_CACHE_KEY';
export const useLogos = () => {
    return useQuery<ILogoDto[]>(LOGO_CACHE_KEY, async () => {
        return await request<ILogoDto[]>({
            url: `/logo`,
            method: 'GET',
        })
    });
}
