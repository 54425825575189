import {StyledText} from "./Text";

interface Props {
    children: any;
    bottomPadding?: boolean;
    required?: boolean;
}
const InputLabel = (props: Props) => {

    return (
        <StyledText tag={"div"} type={"Caption"} weight={"Bold"} color={"secondary"} style={props.bottomPadding ? {paddingBottom: 8} : {}}>
            {props.children} {props.required && "*"}
        </StyledText>
    )
}

InputLabel.defaultProps = {
    bottomPadding: true
}

export default InputLabel