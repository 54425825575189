import The0MissionText from "../../Assets/Icons/V2/The0MissionText";
import styles from "./Footer.module.scss";
import LinkedInIcon from "../../Assets/Icons/LinkedInIcon";
import The0MissionIcon from "../../Assets/Icons/The0MissionIcon";
import { StyledText } from "Components/V2/Text";

interface Props {
    contentMaxWidth?: number;
}
const Footer = (props: Props) => {

    return (
        <footer className={styles.container}>
            <div style={{maxWidth: props.contentMaxWidth ?? '100%'}}>
                <The0MissionText width={174} height={20} />
                <hr/>
                <div className={styles.content}>
                    <StyledText type={"Caption"} color={"muted"}>
                        Copyright ©2023. The 0 Mission
                    </StyledText>
                    <div>
                        <a href={"https://www.linkedin.com/company/the-0-mission/"} target={"_blank"} rel={"noreferrer"}>
                            <LinkedInIcon />
                        </a>
                    </div>
                    <div>
                        <The0MissionIcon width={45} height={45} variant={"light"} />
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer