import CenteredPageLayout from "Layouts/CenteredPageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAddAgreementInfo, useAgreement } from "Apis/AgreementApi";
import { useForm } from "react-hook-form";
import Input from "Components/Input";
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import Step from "Components/Step";
import Checkbox from "Components/Checkbox";
import Button from "Components/Button";
import { AgreementInfoModel } from "Apis/Models/AgreementModel";
import {useEffect} from "react";

const SignupPage = () => {
    
    const { t } = useTranslation();
    
    const { agreementId } = useParams();
    
    const navigate = useNavigate();
    
    const { data: agreement } = useAgreement(agreementId!);
    
    const { control, formState: { errors }, handleSubmit } = useForm<AgreementInfoModel>();
    
    const { mutateAsync: addAgreementInfoMutation, isLoading } = useAddAgreementInfo(agreement!);

    useEffect(() => {
        if (!!agreement && agreement.agreementStatus !== 'Pending') {
            navigate(`/agreements/${agreement.id}/dashboard`);
            return;
        }
    }, [agreement, navigate])

    const saveAgreement = async (data: AgreementInfoModel) => {
        await addAgreementInfoMutation(data);
        
        navigate(`/agreements/setup/${agreementId}/signContact`);
    }
    
    return (
        <CenteredPageLayout maxWidth={600}>
            <Step currentStep={4} numberOfSteps={5} />
            
            <hr/>
            
            <h1 className={"text-align-center"}>{t('agreement:signup.h1')}</h1>
            
            <form onSubmit={handleSubmit(saveAgreement)}>
                <Space size={"large"}>
                    <div className={"w-100"}>
                        <Input name={"name"} control={control} label={t('name')} errors={errors.name} rules={{required: true}} />
                    </div>
    
                    <div className={"w-100"}>
                        <Input name={"companyName"} control={control} label={t('company')} errors={errors.companyName} rules={{required: true}} />
                    </div>
    
                    <div className={"w-100"}>
                        <Input name={"phoneNumber"} control={control} label={t('phoneNumber')} errors={errors.phoneNumber} rules={{required: true}} />
                    </div>
    
                    <div className={"w-100"}>
                        <Input name={"vatNumber"} control={control} label={t('vatNumber')} errors={errors.vatNumber} rules={{required: true}} />
                    </div>
    
                    <div className={"w-100"}>
                        <Checkbox name={"mayBeContacted"} control={control} label={t('mayBeContacted')} />
                    </div>
                    
                    <FlexRow justify={"center"}>
                        <Button loading={isLoading} disabled={isLoading} type={"submit"}>{t('agreement:signup.next')}</Button>
                    </FlexRow>
                </Space>
            </form>
        </CenteredPageLayout>
    )
}

export default SignupPage
