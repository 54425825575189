import { AgreementModel } from "Apis/Models/AgreementModel";
import Back from "Components/Back";
import { useTranslation } from "react-i18next";
import {getInvoicePdf, useAgreementInvoices} from "../../../Apis/AgreementApi";
import moment from "moment/moment";
import {formatNumber} from "../../../Utils/NumberUtils";
import List from "../../../Components/List";
import {InvoiceModel} from "../../../Apis/Models/InvoiceModel";
import {toast} from "react-toastify";
import Button from "../../../Components/Button";

interface IProps {
    agreement: AgreementModel;
}
const InvoicesPage = (props: IProps) => {
    
    const { t } = useTranslation();

    const { agreement } = props;

    const { data: invoices, isLoading: isLoadingInvoices } = useAgreementInvoices(agreement);

    const downloadInvoice = async (invoice: InvoiceModel) => {
        const message = t('downloadingInvoice');

        toast.info(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
        });

        await getInvoicePdf(agreement, invoice);
    }

    return (
        <>
            <Back text={t('agreement:invoices.back')} />
            
            <List data={invoices} loading={isLoadingInvoices} columns={[
                {
                    dataIndex: 'periodCoveredFrom',
                    title: t('date'),
                    customRender: (invoiceDate, record) => <>{moment(record.periodCoveredFrom).format('DD-MM-YYYY')} - {moment(record.periodCoveredTo).format('DD-MM-YYYY')}</>
                },
                {
                    dataIndex: 'invoiceTotal',
                    title: t('invoiceTotal'),
                    textRender: (value) => formatNumber(value) + ' kr',
                },
                {
                    title: t('solarPark:functions'),
                    customRender: (_, invoice: InvoiceModel) => (
                        <Button color={"link"} onClick={() => downloadInvoice(invoice)}>{t('agreement:invoice.downloadInvoice')}</Button>
                    )
                },
            ]} emptyText={t('noInvoicesFound')} keyDataIndex={"id"}
            />
        </>
    )
}

export default InvoicesPage
