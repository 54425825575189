import { useTranslation } from "react-i18next";
import styles from './NewAgreementPage.module.scss';
import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import Space from "Components/Space";
import { useCreateAgreement } from "Apis/AgreementApi";
import { AgreementType } from "Apis/Models/AgreementModel";
import { useNavigate } from "react-router-dom";
import Step from "Components/Step";
import CenteredPageLayout from "Layouts/CenteredPageLayout";


const NewAgreementPage = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const { mutateAsync: createAgreementMutation, isLoading: isCreatingAgreement } = useCreateAgreement();
    
    const createAgreement = async (type: AgreementType) => {
        const agreement = await createAgreementMutation({ agreementType: type });
        
        if (agreement.agreementType === 'Match' || agreement.agreementType === 'MatchAndCustom') {
            navigate(`/agreements/setup/${agreement.id}/eloverblik`);
        }

        if (agreement.agreementType === 'Custom') {
            navigate(`/agreements/setup/${agreement.id}/custom`);
        }
    }
    
    return (
        <CenteredPageLayout>
            <Step currentStep={1} numberOfSteps={5} />

            <hr/>
            
            <div className={styles.header}>
                <h1 className={"text-align-center"}>{t('agreement:newAgreement.h1')}</h1>
                
                <p className={"text-align-center"}>
                    {t('agreement:newAgreement.explainer')}
                </p>
            </div>
            
            <div className={styles.grid}>
                <div className={styles.column}>
                    <FlexRow direction={"column"} justify={"space-between"} className={`h-100 ${styles.padding}`}>
                        <div>
                        
                        </div>
                        
                        <Space direction={"column"} size={"large"}>
    
                            <FlexRow justify={"center"}>
                                <span className={`h1 ${styles.alignCenter}`}>{t('agreement:newAgreement.custom.header')}</span>
                            </FlexRow>
    
                            <FlexRow justify={"center"} className={styles.alignCenter}>
                                {t('agreement:newAgreement.custom.description')}
                            </FlexRow>
    
                            <FlexRow justify={"center"}>
                                <Button disabled={isCreatingAgreement} loading={isCreatingAgreement} onClick={() => createAgreement('Custom')}>{t('agreement:newAgreement.nextButton')}</Button>
                            </FlexRow>
                            
                        </Space>
                        
                        <div className={styles.explainer}>{t('agreement:newAgreement.custom.explainer')}</div>
                    </FlexRow>
                </div>

                <div className={`${styles.column} ${styles.recommendedColumn}`}>
                    <FlexRow direction={"column"} justify={"space-between"} className={"h-100"}>
                        <div className={`${styles.recommended} ${styles.padding}`}>
                            {t('recommended')}
                        </div>
                        
                        <div className={`${styles.padding}`}>
                            <Space direction={"column"} size={"large"}>
                
                                <FlexRow justify={"center"}>
                                    <span className={`h1 ${styles.alignCenter}`}>{t('agreement:newAgreement.match.header')}</span>
                                </FlexRow>
        
                                <FlexRow justify={"center"} className={styles.alignCenter}>
                                    {t('agreement:newAgreement.match.description')}
                                </FlexRow>
                
                                <FlexRow justify={"center"}>
                                    <Button disabled={isCreatingAgreement} loading={isCreatingAgreement} onClick={() => createAgreement('Match')}>{t('agreement:newAgreement.nextButton')}</Button>
                                </FlexRow>
                
                            </Space>
                        </div>
                        
                        <div className={`${styles.explainer} ${styles.padding}`}>{t('agreement:newAgreement.match.explainer')}</div>
                    </FlexRow>
                </div>

                <div className={styles.column}>
                    <FlexRow direction={"column"} justify={"space-between"} className={`h-100 ${styles.padding}`}>
                        <div>
    
                        </div>
    
                        <Space direction={"column"} size={"large"}>
        
                            <FlexRow justify={"center"}>
                                <span className={`h1 ${styles.alignCenter}`}>{t('agreement:newAgreement.matchAndCustom.header')}</span>
                            </FlexRow>
    
                            <FlexRow justify={"center"} className={styles.alignCenter}>
                                {t('agreement:newAgreement.matchAndCustom.description')}
                            </FlexRow>
            
                            <FlexRow justify={"center"}>
                                <Button disabled={isCreatingAgreement} loading={isCreatingAgreement} onClick={() => createAgreement('MatchAndCustom')}>{t('agreement:newAgreement.nextButton')}</Button>
                            </FlexRow>
            
                        </Space>

                        <div className={`${styles.explainer}`}>{t('agreement:newAgreement.matchAndCustom.explainer')}</div>
                    </FlexRow>
                </div>
            </div>
            
        </CenteredPageLayout>
    )
}

export default NewAgreementPage
