import styles from './Dropdown.module.scss';
import React, {useRef, useState} from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";

const POINTER_SIZE = 10;

interface Props {
    open?: boolean;
    toggle: React.ReactElement;
    children: React.ReactElement | React.ReactElement[] | undefined;
    onOpenChanged?: Function;
    position?: 'Under Right' | 'Under Left' | 'Right';
    stayOpenAfterClick?: boolean;
    changeState?: boolean;
}
const Dropdown = (props: Props) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const getIsOpen = () => {
        return props.open ?? isOpen;
    }
    
    const containerRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = (newValue: boolean | undefined = undefined) => {
        if (props.onOpenChanged) {
            props.onOpenChanged(newValue ?? !getIsOpen());
        }

        setIsOpen(newValue ?? !getIsOpen());
    }

    const getPlacementStyle = () => {
        const dropDownBoundingRect = dropdownRef.current?.getBoundingClientRect();
        const containerBoundingRect = containerRef.current?.getBoundingClientRect();

        if (!containerBoundingRect || !dropDownBoundingRect) {
            return;
        }

        if (props.position === 'Right') {
            return {
                top: containerBoundingRect?.top!,
                left: containerBoundingRect?.x + containerBoundingRect?.width + POINTER_SIZE
            }
        }

        if (props.position === 'Under Left') {
            return {
                top: containerBoundingRect?.top! + containerBoundingRect?.height + POINTER_SIZE,
                left: containerBoundingRect?.x
            }
        }

        return {
            top: containerBoundingRect?.top! + containerBoundingRect?.height + POINTER_SIZE,
            left: containerBoundingRect?.x + containerBoundingRect?.width - (dropDownBoundingRect?.width!)
        }
    }


    return (
        <div className={styles.container} ref={containerRef}>
            {React.cloneElement(props.toggle, {
                onClick: () => toggleDropdown(true)
            })}

            {createPortal(
                <CSSTransition
                    in={getIsOpen()}
                    timeout={{ enter: 0, exit: 150 }}
                    classNames={{
                        enterDone: styles.open,
                        exit: styles.closed,
                    }}
                >
                    <div className={styles.backdrop} onClick={() => toggleDropdown()}>
                        <div className={styles.dropdown}
                             ref={dropdownRef}
                             style={getPlacementStyle()}
                        >
                            {props.children}
    
                        </div>
                    </div>
                </CSSTransition>
                ,document.getElementById('root')!)}
        </div>
    )
}

export default Dropdown
