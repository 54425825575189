const DownloadIcon = () => {

    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5878 12.0615C11.5866 12.0411 11.5859 12.0206 11.5859 12V4C11.5859 3.44771 12.0337 3 12.5859 3C13.1382 3 13.5859 3.44771 13.5859 4V12C13.5859 12.0028 13.5859 12.0055 13.5859 12.0083L15.9874 10.2001C16.4293 9.86869 17.0561 9.95823 17.3874 10.4001C17.7188 10.8419 17.6293 11.4687 17.1874 11.8001L13.1874 14.8001C13.0143 14.9299 12.8038 15.0001 12.5874 15.0001C12.3801 15.0016 12.1774 14.9387 12.0074 14.8201L8.00743 12.0001C7.55862 11.6817 7.45136 11.0605 7.76743 10.6101C8.08437 10.1578 8.70795 10.048 9.16025 10.365L9.16744 10.3701L11.5878 12.0615ZM4.58594 17V19C4.58594 19.5523 5.03366 20 5.58594 20H19.5859C20.1382 20 20.5859 19.5523 20.5859 19V17C20.5859 16.4477 20.1382 16 19.5859 16C19.0337 16 18.5859 16.4477 18.5859 17V18H6.58594V17C6.58594 16.4477 6.13822 16 5.58594 16C5.03366 16 4.58594 16.4477 4.58594 17Z" fill="#B386CF"/>
        </svg>
    )

}

export default DownloadIcon