import DefaultPageLayout from "../../Layouts/V2/DefaultPageLayout";
import React, {useState} from "react";
import styles from './LoginPage.module.scss';
import {StyledText} from "../../Components/V2/Text";
import Box from "Components/V2/Box";
import InputLabel from "../../Components/V2/InputLabel";
import {ControlledInput} from "../../Components/V2/Input";
import Gap from "../../Components/V2/Gap";
import Button from "../../Components/V2/Button";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {signIn} from "../../FirebaseSetup/auth";
import useToast from "Hooks/useToast";
import {useNavigate} from "react-router-dom";
import OnboardingBoxContent from "../../Features/Onboarding/Components/OnboardingBoxContent";

const LoginPage = () => {

    const navigate = useNavigate();
    const { showErrorToast } = useToast();

    const login = async (data: any) => {
        setLoading(true);

        try {
            await signIn(data.email, data.password);
        } catch (e: any) {
            let message = t('somethingWentWrong');

            if (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password') {
                message = t('auth:invalidCredentials');
            }
            if (e.code === 'auth/invalid-email') {
                message = t('auth:invalidEmail');
            }

            showErrorToast(message);
        } finally {
            setLoading(false);
        }
    }

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false)

    const {control, handleSubmit} = useForm();

    return (
        <DefaultPageLayout contentMaxWidth={1440}  menuContent={(
                <Gap size={"small"}>
                    <Button text={t('auth:login.noAccount')} onClick={() => navigate("/v2/onboarding")} />
                </Gap>
            )}>
            <div className={styles.container}>

                <div className={styles.headline}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('signIn')}
                    </StyledText>
                </div>

                <Box style={{width: 840}}>
                    <form onSubmit={handleSubmit(login)} className={styles.content}>
                        <OnboardingBoxContent content={(
                            <>
                                <div>
                                    <InputLabel required>
                                        {t('email')}
                                    </InputLabel>
                                    <ControlledInput name={'email'} placeholder={t('yourEmail')} control={control} rules={{required: true}}/>
                                </div>

                                <div>
                                    <InputLabel required>
                                        {t('password')}
                                    </InputLabel>
                                    <ControlledInput name={'password'} placeholder={t('yourPassword')} type={"password"} control={control} rules={{required: true}}/>
                                </div>
                            </>
                        )} buttons={(
                            <Gap size={"small"} className={"flex space-between"}>
                                <div />
                                <Button text={t('signIn')} type={"submit"} state={loading ? "Loading" : "None"}/>
                            </Gap>
                        )} />
                    </form>
                </Box>
            </div>
        </DefaultPageLayout>
    )
}

export default LoginPage
