import * as React from "react"
import { SVGProps } from "react"
const UserPlaceholderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 30}
    height={props.height ?? 30}
    fill="none"
    viewBox={"0 0 31 30"}
    {...props}
  >
    <circle cx={15} cy={15.5} r={15} fill="#445E54" />
    <path
      fill="#FFFFC9"
      fillRule="evenodd"
      d="M15 14.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-6a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm-7 15a7 7 0 1 1 14 0 1 1 0 1 1-2 0 5 5 0 0 0-10 0 1 1 0 1 1-2 0Z"
      clipRule="evenodd"
    />
  </svg>
)
export default UserPlaceholderIcon
