import {Route, Routes} from "react-router-dom";
import AuthRoutes from "./Auth/AuthRoutes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import 'index.scss';

const OldRoutes = () => {

    return (
        <Routes>
            <Route path="/auth/*" element={<AuthRoutes />} />
            
            <Route path={"*"} element={
                <AuthenticatedRoutes />
            } />
        </Routes>
    )

}

export default OldRoutes